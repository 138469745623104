export const COLUMNS = [
  {
    Header: "Card Number",
    Footer: "Card Number",
    accessor: "memberCardNumber",
  },

  {
    Header: "Created Date",
    Footer: "Created Date",
    accessor: "createdDate",
  },

  {
    Header: "Expiry Date",
    Footer: "Expiry Date",
    accessor: "ExpiryDate",
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "status",
  },
];
