import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  logo,
  inside,
  outside,
  dashboard,
  dashboardHover,
  programs,
  programsHover,
  customers,
  customersHover,
  transaction,
  transactionHover,
  /*financialAccount, financialAccountHover,*/ settings,
  settingsHover,
  /*helpFAQs, helpFAQsHover,*/ logoutIcon,
  logoutIconHover,
  apiDocument,
  apiDocumentHover,
  uploadIcon,
  monitor,
  monitor_hover,
} from "../../Image/Index";
import { logout } from "../../actions/authAction";
import { clearCampaigns } from "../../actions/campaigns";
import { clearCustomer } from "../../actions/customer";
import { clearDashboard } from "../../actions/dashboard";
import { clearSetting } from "../../actions/setting";
import { clearTransaction } from "../../actions/transaction";
import { clearBusinessGoal } from "../../actions/businessGoal";
import { decrypt } from "../../utils/encodingdecoding";
import "intro.js/introjs.css";
import { useDispatch } from "react-redux";
import { modalCard } from "../../actions/intro";
import SidebarIntro from "../../intro/SidebarIntro";

const Sidebar = (props) => {
  const {
    children,
    logout,
    clearCampaigns,
    clearCustomer,
    clearDashboard,
    clearSetting,
    clearTransaction,
    clearBusinessGoal,
  } = props;
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  const [dropdownDocBtn, setDropdownDocBtn] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [dropdownSetting, setDropdownSetting] = useState(false);
  const [dropdownSettingBtn, setDropdownSettingBtn] = useState(false);
  const [apiDocBtn, setApiDocBtn] = useState(false);
  const [menuItem, setMenuItem] = useState([]);
  const [menuTree, setMenuTree] = useState([]);
  const [isEmailDash, setIsEmailDash] = useState(false);
  let Dashboard = sessionStorage.getItem("dashboard");
  const dispatch = useDispatch();
  const [key, setKey] = useState(true);

  const moduleAccess =
    localStorage.getItem("moduleAccess") &&
    JSON.parse(decrypt(localStorage.getItem("moduleAccess")));

  useEffect(() => {
    moduleAccess?.map((ele) => {
      if (ele.moduleName === "Email Dashboard" && ele.isEnable === true) {
        setIsEmailDash(true);
      }
    });
  }, [moduleAccess]);

  useEffect(() => {
    if (localStorage.getItem("firstLogin")) setToggle(true);
    if (location.pathname.includes("customers-list")) {
      let tempCusPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
      localStorage.setItem("CusPageIndex", JSON.stringify(tempCusPageIndex));
    } else {
      localStorage.setItem("CusPageIndex", JSON.stringify(1));
    }
    if (location.pathname.includes("transactions-list")) {
      let tempTransPageIndex = JSON.parse(
        localStorage.getItem("TransPageIndex")
      );
      localStorage.setItem(
        "TransPageIndex",
        JSON.stringify(tempTransPageIndex)
      );
    } else {
      localStorage.setItem("TransPageIndex", JSON.stringify(1));
    }

    let commTabKey = decrypt(localStorage.getItem("key"));
    let commTabCode = decrypt(localStorage.getItem("code"));

    if (!commTabCode && !commTabKey) {
      return;
    } else {
      localStorage.removeItem("key");
      localStorage.removeItem("code");
    }
  }, [location]);
  useEffect(() => {
    setMenuTree(menuItem);
  }, [menuItem]);
  const membership = decrypt(localStorage.getItem("Id"));
  useEffect(() => {
    setMenuItem(
      window.location.pathname == "/register" ||
        window.location.pathname == "/contact-us-query" ||
        window.location.pathname == "/alertNotification"
        ? [
            {
              id: 1,
              path: "/register",
              name: "Registerd User",
              iconOffHover: (
                <img src={"/Images/monitor.png"} alt="" className="offHover" />
              ),
              iconOnHover: (
                <img
                  src={"/Images/monitor_hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              isActive: false,
              dropdown: null,
            },
            {
              id: 2,
              path: "/contact-us-query",
              name: "Contact-Us Query",
              iconOffHover: (
                <img
                  src={"/Images/uploadIcon.png"}
                  alt=""
                  className="offHover"
                />
              ),
              iconOnHover: (
                <img
                  src={"/Images/uploadIcon.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
            },
            {
              id: 3,
              path: "/alertNotification",
              name: "Alert Notification",
              iconOffHover: (
                <i
                  className="fa-solid fa-bell"
                  style={{ fontSize: "1.2rem" }}
                ></i>
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
            },
          ]
        : [
            {
              id: 1,
              path: "/dashboard",
              name: "Dashboard",
              iconOffHover: (
                <img src={"/Images/monitor.png"} alt="" className="offHover" />
              ),
              iconOnHover: (
                <img
                  src={"/Images/monitor_hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              isActive: false,
              dropdown: null,
              moduleAccessValue: "Dashboard",
              className: "pro_options",
              ids: "dashboardinfo",
            },
            {
              id: 14,
              path: "/email-dashboard",
              name: "Email Dashboard",
              iconOffHover: (
                <img src={"/Images/monitor.png"} alt="" className="offHover" />
              ),
              iconOnHover: (
                <img
                  src={"/Images/monitor_hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              isActive: false,
              dropdown: null,
              moduleAccessValue: "Email Dashboard",
              className: "pro_options",
            },
            {
              id: 2,
              path: "/analytical-report",
              name: "RFM Data Analysis",
              iconOffHover: (
                <img
                  src={"/Images/uploadIcon.png"}
                  alt=""
                  className="offHover"
                />
              ),
              iconOnHover: (
                <img
                  src={"/Images/uploadIcon.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
              moduleAccessValue: "RFM Data Analysis",
              className: "pro_options",
              ids: "analytical",
            },
            {
              id: 3,
              path: "/growth-prospect",
              name: "Growth Prospect",
              iconOffHover: (
                <img
                  src={"/Images/dashboard.png"}
                  alt=""
                  className="offHover"
                />
              ),
              iconOnHover: (
                <img
                  src={"/Images/dashboard-hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
              moduleAccessValue: "Growth Prospect",
              className: "pro_options",
              ids: "growth",
            },
            {
              id: 4,
              name: "Campaigns",
              iconOffHover: (
                <img src={"/Images/programs.png"} alt="" className="offHover" />
              ),
              iconOnHover: (
                <img
                  src={"/Images/programs-hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible:
                localStorage.getItem("isChildVisible") === "true"
                  ? true
                  : false,
              isActive: false,
              dropdown: [
                {
                  path: "/my-campaigns",
                  name: "My Campaigns",
                },
                {
                  path: "/business-goals-selection",
                  name: "Sample Campaigns",
                },
                //   {
                //       path: "/campaign-report",
                //       name: "Campaign Report",
                //   },
              ],
              moduleAccessValue: "My Campaigns",
              ids: "campaignsinfos",
              className: "pro_options",
            },
            {
              id: 5,
              name: "Customers",
              iconOffHover: (
                <img
                  src={"/Images/customers.png"}
                  alt=""
                  className="offHover"
                />
              ),
              iconOnHover: (
                <img
                  src={"/Images/customers-hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
              dropdown: [
                {
                  path: "/customers-list",
                  name: "Customers",
                },
                {
                  path: "/customer-group",
                  name: "Customer Group",
                },
              ],
              moduleAccessValue: "Customers",
              className: "pro_options",
            },
            {
              id: 6,
              path: "/transactions-list",
              name: "Transaction",
              iconOffHover: (
                <img
                  src={"/Images/transactions.png"}
                  alt=""
                  className="offHover"
                />
              ),
              iconOnHover: (
                <img
                  src={"/Images/transactions-hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
              moduleAccessValue: "Transactions",
              ids: "teansctions",
              className: "pro_options",
            },
            {
              id: 7,
              name: "Voucher",
              iconOffHover: (
                <img
                  src={"/Images/voucher_black.png"}
                  alt=""
                  className="offHover"
                />
              ),
              iconOnHover: (
                <img src={"/Images/voucher.png"} alt="" className="onHover" />
              ),
              isChildVisible: false,
              isActive: false,
              moduleAccessValue: "Vouchers",
              ids: "voucherp",

              className: "pro_options",
              dropdown: [
                {
                  path: "/voucher-template",
                  name: "Template",
                },
                {
                  path: "/vouchers",
                  name: "Voucher List",
                },
              ],
            },
            {
              id: 14,
              name: "QR Code",
              iconOffHover: (
                <i
                  className="fa-solid fa-qrcode"
                  style={{ fontSize: "1.2rem" }}
                ></i>
              ),

              isChildVisible: false,
              isActive: false,
              moduleAccessValue: "Vouchers",
              ids: "voucherp",

              className: "pro_options",
              dropdown: [
                {
                  path: "/qrcode-template",
                  name: "QR Code Template",
                },
              ],
            },
            {
              id: 8,
              path: "/gift-card",
              name: "Gift Card ",
              iconOffHover: (
                <i className="fas fa-gift" style={{ fontSize: "1.2rem" }}></i>
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
              moduleAccessValue: "Gift Card",
              ids: "gift",
              className: "pro_options",
            },

            ...(membership === "MER000289"
              ? [
                  {
                    id: 14,
                    path: "/membership",
                    name: "Membership",
                    iconOffHover: (
                      <i
                        className="fa fa-user-plus"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    ),
                    isChildVisible: false,
                    dropdown: null,
                    isActive: false,
                    moduleAccessValue: "Membership",
                    ids: "gift",
                    className: "pro_options",
                  },
                  {
                    id: 14,
                    path: "/membership-card",
                    name: "Membership Card",
                    iconOffHover: (
                      <i
                        className="fa-solid fa-id-card"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    ),
                    isChildVisible: false,
                    dropdown: null,
                    isActive: false,
                    moduleAccessValue: "Membership",
                    ids: "gift",
                    className: "pro_options",
                  },
                  {
                    id: 14,
                    path: "/invoice",
                    name: "Uploaded Invoice",
                    iconOffHover: (
                      <i
                        className="fa fa-file-invoice"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    ),
                    isChildVisible: false,
                    dropdown: null,
                    isActive: false,
                    moduleAccessValue: "Membership",
                    ids: "gift",
                    className: "pro_options",
                  },
                ]
              : []),

            // {
            //     id: 11,
            //     name: "WhatsappNotify",
            //     iconOffHover: <i className="fa-brands fa-whatsapp"style={{ fontSize: "1.2rem" }}></i>,
            //     isChildVisible: false,
            //     dropdown: [
            //         {
            //             path: "/profile-settings?p13",
            //             name: "Whatsapp Template",
            //         },
            //         {
            //             path: "/whatsappMsg",
            //             name: "Send Whatsapp",
            //         },
            //     ],
            //     isActive: false,
            //     moduleAccessValue: "Gift Card",
            //     ids: "emailSender",
            //     className: "pro_options",
            // },
            ...(membership === "MER000252"
              ? []
              : [
                  {
                    id: 13,
                    path: "/tiers",
                    name: "Tiers",
                    iconOffHover: (
                      <i
                        className="fa-solid fa-ranking-star"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    ),
                    isChildVisible: false,
                    dropdown: null,
                    isActive: false,
                    moduleAccessValue: "Gift Card",
                    ids: "setting",
                    className: "pro_options",
                  },
                  {
                    id: 12,
                    path: "/offer",
                    name: "Offer",
                    iconOffHover: (
                      <i
                        className="fas fa-gift"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    ),
                    isChildVisible: false,
                    dropdown: null,
                    isActive: false,
                    moduleAccessValue: "Gift Card",
                    ids: "offer",
                    className: "pro_options",
                  },
                  {
                    id: 9,
                    path: "/report",
                    name: "Report",
                    iconOffHover: (
                      <i
                        className="fa fa-file-text"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    ),
                    isChildVisible: false,
                    dropdown: null,
                    isActive: false,
                    moduleAccessValue: "Gift Card",
                    ids: "report",
                    className: "pro_options",
                  },
                  {
                    id: 10,

                    name: "Email Sender",
                    iconOffHover: (
                      <i
                        className="fa-solid fa-envelope"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    ),
                    isChildVisible: false,
                    dropdown: [
                      {
                        path: "/emailSender",
                        name: "Send Email",
                      },
                      {
                        path: "/emailReport",
                        name: "Email Report",
                      },
                      {
                        path: "/emailTemplate",
                        name: "Email Template",
                      },
                    ],
                    isActive: false,
                    moduleAccessValue: "Email Sender",
                    ids: "emailSender",
                    className: "pro_options",
                  },
                  {
                    id: 9,
                    path: "/integration",
                    name: "Integration",
                    iconOffHover: (
                      <i class="fa fa-link" style={{ fontSize: "1.2rem" }}></i>
                    ),
                    isChildVisible: false,
                    dropdown: null,
                    isActive: false,
                    moduleAccessValue: "Gift Card",
                    ids: "gift",
                    className: "pro_options",
                  },
                ]),

            {
              id: 11,
              path: "/customer-review",
              name: "Customer Review",
              iconOffHover: (
                <i
                  className="fa-solid fa-comments"
                  style={{ fontSize: "1.2rem" }}
                ></i>
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
              moduleAccessValue: "Gift Card",
              ids: "report",
              className: "pro_options",
            },
            {
              id: 13,
              path: "/profile-settings",
              name: "Setting",
              iconOffHover: (
                <img src={"/Images/settings.png"} alt="" className="offHover" />
              ),
              iconOnHover: (
                <img
                  src={"/Images/settings-hover.png"}
                  alt=""
                  className="onHover"
                />
              ),
              isChildVisible: false,
              dropdown: null,
              isActive: false,
              moduleAccessValue: "Gift Card",
              ids: "setting",
              className: "pro_options",
            },
          ]
    );
  }, [membership]);

  useEffect(() => {
    if (
      location.pathname.includes("my-campaigns") ||
      location.pathname.includes("business-goals-selection")
    ) {
      localStorage.setItem("isChildVisible", true);
    } else {
      localStorage.setItem("isChildVisible", false);
    }
  }, [location]);

  const menuMainTrigger = () => {
    let array = [...menuTree];
    array.forEach((elem) => {
      if (toggle) {
        elem.isActive = false;
        setDropdownDocBtn(false);
        setDropdownSettingBtn(false);
        setDropdownSetting(false);
      }
    });
    setMenuTree(array);
    setToggle(!toggle);
  };
  const childrenMenu = (id, Index, api_doc, path) => {
    location.pathname === path && window.location.reload();
    let array = [...menuTree];
    array.forEach((elem) => {
      if (elem.id === id && elem.dropdown && !toggle) {
        elem.isActive = false;
        elem.isChildVisible = true;
        setDropdownDocBtn(false);
        setDropdownSetting(false);
        setActiveBtn(false);
        setApiDocBtn(false);
      } else if ((id === "btn" && toggle && Index === 2) || api_doc) {
        setDropdownDocBtn(true);
        setActiveBtn(true);
        elem.isChildVisible = false;
        setDropdownSettingBtn(false);
        setDropdownSetting(false);
        api_doc ? setApiDocBtn(true) : setApiDocBtn(false);
      } else if (id === "btn" && toggle && Index === 1) {
        setDropdownSettingBtn(true);
        setDropdownSetting(false);
        elem.isChildVisible = false;
        setDropdownDocBtn(false);
        setActiveBtn(true);
        setApiDocBtn(false);
      } else if (id === "btn" && Index === 1) {
        setDropdownSettingBtn(!dropdownSettingBtn);
        setDropdownSetting(false);
        elem.isChildVisible = false;
        setDropdownDocBtn(false);
        setApiDocBtn(false);
      } else if (id === "btn" && toggle && Index === 3) {
        setDropdownSettingBtn(false);
        setDropdownSetting(true);
        elem.isChildVisible = false;
        setDropdownDocBtn(false);
        setActiveBtn(true);
        setApiDocBtn(false);
      } else if (id === "btn" && Index === 3) {
        setDropdownSetting(!dropdownSetting);
        setDropdownSettingBtn(false);
        elem.isChildVisible = false;
        setDropdownDocBtn(false);
        setApiDocBtn(false);
      } else if ((id === "btn" && Index == 2) || api_doc) {
        setDropdownSettingBtn(false);
        setDropdownSetting(false);
        elem.isChildVisible = false;
        setDropdownDocBtn(!dropdownDocBtn);
        api_doc ? setApiDocBtn(true) : setApiDocBtn(false);
      } else if (toggle === false) {
        setDropdownDocBtn(false);
        setActiveBtn(false);
        setApiDocBtn(false);
        elem.isChildVisible = false;
      }
    });
    setMenuTree(array);
  };
  const menuChildTrigger = async (id, Index, path) => {
    location.pathname === path && window.location.reload();

    let array = [...menuTree];
    await array.forEach((elem) => {
      if (elem.id === id && elem.dropdown) {
        elem.isActive = !elem.isActive;
        setDropdownDocBtn(false);
        setActiveBtn(false);
        setDropdownSettingBtn(false);
        setDropdownSetting(false);
        setApiDocBtn(false);
      } else if (id === "btn" && Index === 3) {
        setDropdownSetting(!dropdownSetting);
        setDropdownSettingBtn(false);
        setDropdownDocBtn(false);
        setActiveBtn(true);
        setApiDocBtn(false);
        elem.isActive = false;
      } else if (id === "btn" && Index === 2) {
        setDropdownDocBtn(!dropdownDocBtn);
        setActiveBtn(true);
        setDropdownSetting(false);
        setDropdownSettingBtn(false);
        elem.isActive = false;
      } else if (id === "btn" && Index === 1) {
        setDropdownSettingBtn(!dropdownSettingBtn);
        setDropdownDocBtn(false);
        setDropdownSetting(false);
        setActiveBtn(true);
        setApiDocBtn(false);
        elem.isActive = false;
      } else {
        setDropdownSettingBtn(false);
        setDropdownSetting(false);
        setDropdownDocBtn(false);
        setActiveBtn(false);
        setApiDocBtn(false);
        elem.isChildVisible = false;
        elem.isActive = false;
      }
    });

    setMenuTree(array);
  };

  useEffect(() => {
    // clearTransaction();
    sessionStorage.setItem(
      "dashboard",
      location.pathname.includes("register") ? "AD" : "UD"
    );
  }, [location]);

  const handleLogout = () => {
    if (Dashboard === "UD") {
      if (localStorage.getItem("superAdmin")) {
        localStorage.removeItem("saasemail");
        localStorage.removeItem("superAdmin");
      }
      logout();
      clearCampaigns();
      clearCustomer();
      clearDashboard();
      clearSetting();
      clearTransaction();
      clearBusinessGoal();
      localStorage.removeItem("token_gen");
      sessionStorage.clear();
      localStorage.removeItem("pageIndex");
      localStorage.removeItem("token_gen");
      localStorage.removeItem("token_rare");
      localStorage.removeItem("profile");
      localStorage.removeItem("Id");
      localStorage.removeItem("clientid");
      localStorage.removeItem("schemaId");
      localStorage.removeItem("merchantcode");
      localStorage.removeItem("clientsecret");
      localStorage.removeItem("schemaName");
      localStorage.removeItem("email");
      localStorage.removeItem("isCardAttach");
      localStorage.removeItem("TransPageIndex");
      localStorage.removeItem("CusPageIndex");
      localStorage.removeItem("isChildVisible");
      localStorage.removeItem("loginKey");
      localStorage.removeItem("name");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("moduleAccess");
      localStorage.removeItem("cardStatus");
      localStorage.removeItem("plan");
      localStorage.removeItem("packageNameList");
    } else {
      localStorage.removeItem("saasemail");
      localStorage.removeItem("password");
      sessionStorage.setItem("dashboard", "UD");
    }
  };
  return (
    <>
      <div className={`dashboard ${toggle ? "openSidebar" : ""}`}>
        <div className="dashboard__sidebar" id="sidebar">
          <div className="dashboard__topSection">
            <Link
              to={
                location.pathname.includes("register") ||
                location.pathname.includes("contact-us-query") ||
                location.pathname.includes("alertNotification")
                  ? "/register"
                  : isEmailDash
                  ? "/email-dashboard"
                  : "/dashboard"
              }
            >
              <img
                src={"/Images/logo.png"}
                alt=""
                className="dashboard__logoBox"
              />
            </Link>
            <div className="dashboard__menuBox">
              <span onClick={menuMainTrigger}>
                <img src={"/Images/close-icon.png"} alt="" className="inside" />
                <img
                  src={"/Images/open-icon.png"}
                  alt="open-icon.png"
                  className="outside"
                />
              </span>
            </div>
          </div>

          <div className="dashboard__menuMain">
            <div className="dashboard__menuTop">
              <ul>
                {window.location.pathname == "/register" ||
                window.location.pathname == "/contact-us-query" ||
                window.location.pathname == "/alertNotification"
                  ? menuTree.map((elem, index) => {
                      const {
                        id,
                        name,
                        iconOffHover,
                        iconOnHover,
                        path,
                        className,
                      } = elem;
                      return (
                        <li key={`parentLi__${index}`} title={name}>
                          <div id={className}>
                            <NavLink
                              to={path}
                              className="link"
                              onClick={() => menuChildTrigger(id, null, path)}
                            >
                              <figure className="icon">
                                {iconOffHover}
                                {iconOnHover}
                              </figure>
                              <span className="link_text">{name}</span>
                            </NavLink>
                          </div>
                        </li>
                      );
                    })
                  : menuTree.map((elem, index) => {
                      const matchingModules =
                        moduleAccess &&
                        moduleAccess?.filter(
                          (module) =>
                            elem.moduleAccessValue === module.moduleName
                        );
                      if (matchingModules && matchingModules.length > 0) {
                        return (
                          <ul key={index}>
                            {matchingModules.map((module) => {
                              const {
                                id,
                                name,
                                iconOffHover,
                                iconOnHover,
                                dropdown,
                                path,
                                isChildVisible,
                                isActive,
                                className,
                                ids,
                              } = elem;

                              return (
                                <li key={id} title={name}>
                                  {path ? (
                                    <div
                                      className={
                                        module.isEnable === false
                                          ? className
                                          : ""
                                      }
                                      id={ids}
                                    >
                                      <NavLink
                                        to={path}
                                        className="link"
                                        onClick={() =>
                                          menuChildTrigger(id, null, path)
                                        }
                                      >
                                        <figure className="icon">
                                          {iconOffHover}
                                          {iconOnHover}
                                        </figure>
                                        <span className="link_text">
                                          {name}
                                        </span>
                                      </NavLink>
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        module.isEnable === false
                                          ? className
                                          : ""
                                      }
                                      id={ids}
                                    >
                                      <button
                                        type="button"
                                        className={
                                          isChildVisible
                                            ? "link active"
                                            : "link"
                                        }
                                        onClick={() =>
                                          menuChildTrigger(id, null)
                                        }
                                      >
                                        <figure className="icon">
                                          {iconOffHover}
                                          {iconOnHover}
                                        </figure>
                                        <span className="link_text">
                                          {name}
                                        </span>
                                        {!isActive ? (
                                          <span className="link_icon">
                                            <i
                                              className="fa fa-chevron-right"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        ) : (
                                          <span className="link_icon">
                                            <i
                                              className="fa fa-chevron-down"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        )}
                                      </button>
                                    </div>
                                  )}
                                  {dropdown && isActive && (
                                    <ul className="childrenDropdown">
                                      {dropdown.map((each, idx) => {
                                        const { path, name } = each;
                                        return (
                                          <li
                                            key={`childLi__${id}__${idx}`}
                                            title={name}
                                          >
                                            <NavLink
                                              to={path}
                                              onClick={() =>
                                                childrenMenu(
                                                  id,
                                                  null,
                                                  null,
                                                  path
                                                )
                                              }
                                            >
                                              {name}
                                            </NavLink>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        );
                      }
                      return null;
                    })}
              </ul>
            </div>

            <div className="dashboard__menuBottom">
              <ul>
                {Dashboard === "UD" &&
                  !location.pathname.includes("contact-us-query") &&
                  !location.pathname.includes("register") &&
                  !location.pathname.includes("alertNotification") && (
                    <>
                      {!isEmailDash && (
                        <>
                          <li>
                            <button
                              id="intro_documentation"
                              className={
                                location.pathname.includes("api") ||
                                location.pathname.includes("contact-us")
                                  ? "link active"
                                  : "link"
                              }
                              type="button"
                              onClick={() => menuChildTrigger("btn", 2)}
                            >
                              <figure className="icon">
                                <img
                                  src={"/Images/apiDocument.png"}
                                  alt=""
                                  className="offHover"
                                />
                                <img
                                  src={"/Images/apiDocument-hover.png"}
                                  alt=""
                                  className="onHover"
                                />
                              </figure>
                              <span className="link_text">Documentation</span>
                              {!dropdownDocBtn ? (
                                <span className="link_icon">
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span span className="link_icon">
                                  <i
                                    className="fa fa-chevron-down"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </button>
                            {dropdownDocBtn && activeBtn && (
                              <ul className="childrenDropdown childrenDropdown_2">
                                <li>
                                  <NavLink
                                    to="/intro"
                                    id=""
                                    onClick={() => {
                                      localStorage.setItem("firstLogin", "Y");
                                      dispatch(modalCard(key + 1));
                                      setKey(key + 1);
                                    }}
                                    state={{ data: key + 1 }}
                                  >
                                    Novus Loyalty's Tour
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/generate-access-key-api"
                                    id="documentationm"
                                    onClick={() => childrenMenu("btn", 2)}
                                  >
                                    Generate Access Key
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/customer-registration-api"
                                    onClick={() => childrenMenu("btn", 2)}
                                  >
                                    Customers
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/transaction-api"
                                    onClick={() => childrenMenu("btn", 2)}
                                  >
                                    Transactions
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/redemption-api"
                                    onClick={() => childrenMenu("btn", 2)}
                                  >
                                    Redemption
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/help-details"
                                    id="help_FAQ"
                                    onClick={() => childrenMenu("btn", 2)}
                                  >
                                    Help & FAQs
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/contact-us"
                                    onClick={() => childrenMenu("btn", 2)}
                                  >
                                    Contact Us
                                  </NavLink>
                                </li>
                              </ul>
                            )}
                          </li>
                        </>
                      )}
                    </>
                  )}
                <li title="Logout">
                  <NavLink
                    className="link"
                    to={Dashboard === "UD" ? "/signin" : "/saas-admin"}
                    aria-current="page"
                    onClick={handleLogout}
                  >
                    <figure className="icon">
                      <img
                        src={"/Images/logout.png"}
                        alt=""
                        className="offHover"
                      />
                      <img
                        src={"/Images/logout-hover.png"}
                        alt=""
                        className="onHover"
                      />
                    </figure>
                    <span className="link_text">Logout</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="dashboard__mainPage">{children}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  // dropdown:state.dropDownSetting
});

export default connect(mapStateToProps, {
  logout,
  clearCampaigns,
  clearCustomer,
  clearDashboard,
  clearSetting,
  clearTransaction,
  clearBusinessGoal,
})(Sidebar);
