import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import { getMembersipData, UpdateMembership } from "../../../actions/auth";
import Paginated from "./Paginated";
import { COLUMNS } from "./column";
import HanldeView, { handleModal } from "./HanldeView";
import { getDate } from "../../../utils/helper";
import { Link } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import { decrypt } from "../../../utils/encodingdecoding";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../partial/TextValidator";

const Membership = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [allDAta, setAllData] = useState();
  const [changeData, setChangeData] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const [formData, setFormData] = useState([]);

  const fetch = async () => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    tempToken = tempToken?.access_token;
    const id = localStorage.getItem("Id");
    await getMembersipData(tempToken, id)
      .then((res) => {
        setFormData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let token = JSON.parse(localStorage.getItem("token_gen"));
    const id = decrypt(localStorage.getItem("Id"));
    const body = {
      TransactionDate: allDAta.TransactionDate,
      membershipType: allDAta.membershipType,
      amount: allDAta.amount ? allDAta.amount : "N/A",
      transactionStatus: allDAta.transactionStatus,
      remarks: changeData.trim(),
    };
    UpdateMembership(token?.access_token, id, allDAta.mobile, body)
      .then((res) => {
        fetch();
        setLoader(false);
        setShowLogin(false);
        setChangeData();
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleChange = (e) => {
    setChangeData(e.target.value);
  };
  const handleViewModal = (data) => {
    setAllData(data);
    setShowLogin(true);
  };
  useEffect(() => {
    const tempData = formData;
    if (tempData) {
      tempData?.map((val, ind) => {
        val.name = val.MembershipTransactionData.name
          ? val.MembershipTransactionData.name
          : "-";
        val.email = val.MembershipTransactionData.email
          ? val.MembershipTransactionData.email
          : "-";
        val.mobile = val.MembershipTransactionData.mobile
          ? val.MembershipTransactionData.mobile
          : "-";
        val.membershipType = val.MembershipTransactionData.membershipType;
        val.remarks = val.MembershipTransactionData.remarks
          ? val.MembershipTransactionData.remarks
          : "N/A";
        val.amount = val.MembershipTransactionData.amount;
        val.date = getDate(
          val.MembershipTransactionData.TransactionDate,
          "DD-MMM-YYYY"
        );
        val.status =
          val.MembershipTransactionData.transactionStatus === true
            ? "Yes"
            : "No";
        val.edit = (
          <Link
            state={{ transactionId: val.MembershipTransactionData }}
            onClick={() => handleViewModal(val.MembershipTransactionData)}
            className="edit_btn"
            csvalue="Edit"
          >
            <i className="fa-solid fa-pen"></i>
          </Link>
        );

        return val;
      });
    }
    setData(tempData);
  }, [formData]);

  return (
    <>
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Header name={"Membership"} />
      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="dashboard__customersCard">
            <div className="dashboard__header">
              <div className="dashboard__left">
                <h4>Membership Details</h4>
              </div>
            </div>
            {showLogin && (
              <Modal
                show={showLogin}
                onHide={() => setShowLogin(false)}
                size="lg"
                centered
              >
                <Modal.Header>
                  <Modal.Title style={{ fontSize: "20px" }}>
                    Remarks
                  </Modal.Title>
                  <CloseButton
                    onClick={() => setShowLogin(false)}
                    variant="black"
                  />
                </Modal.Header>
                <Modal.Body
                  className="dashboard__transactionsModal"
                  style={{ position: "relative", minHeight: "85px" }}
                >
                  <ValidatorForm
                    className="signUpPage__formSection"
                    onSubmit={handleSubmit}
                  >
                    <div className="modal-body">
                      <div className="form-group">
                        <TextValidator
                          placeholder="Remarks"
                          onChange={handleChange}
                          value={changeData}
                          type="text"
                          name="remarks"
                          className="form-control"
                          validators={["required"]}
                          errorMessages={["Please enter your remarks"]}
                          onKeyDown={(e) => {
                            if (e.target.value.length === 0 && e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </ValidatorForm>
                </Modal.Body>
              </Modal>
            )}

            <div className="dashboard__body">
              <Paginated data={data || []} columns={COLUMNS} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Membership;
