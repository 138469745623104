import { Link } from "react-router-dom";

export const COLUMNS = [
    {
        Header: "Title",
        Footer: "Title",
        accessor: "title",
      },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
  },
  {
    Header: "Uploaded Date",
    Footer: "Uploaded Date",
    accessor: "date",
  },
  {
    Header: "Image",
    Footer: "Image",
    accessor: "image",
    // Cell: ({ value }) => (
    //   <>
    //     <Link target="_blank" to={value[0]}>
    //       <i className="fa-solid fa-image" />
    //     </Link>
    //     {value[1]?.length > 0 && (
    //       <Link target="_blank" to={value[1]}>
    //         <i className="fa-solid fa-image" />
    //       </Link>
    //     )}
    //   </>
    // ),
  },
  {
    Header: "Remarks",
    Footer: "Remarks",
    accessor: "remarkId",
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "status",
  },
];
