export const COLUMNS = [
  {
    Header: "Product Id",
    Footer: "Product Id",
    accessor: "productId",
    sticky: "left",
  },
  {
    Header: "Template Code",
    Footer: "Template Code",
    accessor: "code",
    disableFilters: true,
    sticky: "left",
  },
  {
    Header: "Generated QR",
    Footer: "Generated QR",
    accessor: "totalqrcodecount",
  },

  {
    Header: "Status",
    Footer: "Status",
    accessor: "status",
  },

  {
    Header: "Generate QR",
    Footer: "Generate QR",
    accessor: "action",
  },
];
