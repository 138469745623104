import React, { memo, useEffect, useState } from "react";
import Header from "../../layout/Header";
import { Link, useLocation } from "react-router-dom";
import { dashboardIconOne, dashboardIconTwo, dashboardIconThree, backIcon2 } from "../../../Image/Index";
import LineChartAnalyticalReport1 from "../../charts/LineChartAnalyticalReport1";
import BarChartAnalyticalReport1 from "../../charts/BarChartAnalyticalReport1";
import BarChartAnalyticalReport2 from "../../charts/BarChartAnalyticalReport2";
import DoughnutChartAnalyticalReport1 from "../../charts/DoughnutChartAnalyticalReport1";
import { getDasboardReport, getUserJourneyReport } from "../../../actions/dashboard";
import { decrypt } from "../../../utils/encodingdecoding";

const Dashboard = memo(({ }) => {
    const location = useLocation();
    const [FormData, setFormData] = useState([]);
    const [year, setYear] = useState({});
    const [Report1, setReport1] = useState([]);
    const [Report2, setReport2] = useState([]);
    const [loader, setLoader] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const Id = localStorage.getItem("Id");
    const dataID = decrypt(Id)

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));

        if (decrypt(Id) === "MER000252" || decrypt(Id) === "MER000289" ) {
            await getDasboardReport(token?.access_token, Id).then((res) => {
                setFormData(res);
            }).catch((err) => {

            })
        }
        else {
            await getUserJourneyReport(token?.access_token, Id).then((res) => {
                setFormData(res);
            }).catch((err) => {

            })
        }

        setFirstRender(false);
        setLoader(false);
    };
    useEffect(() => {
        fetch();
    }, []);


    useEffect(() => {
        if (dataID === "MER000252" || dataID === "MER000289") {
            if (FormData?.data?.monthWiseTransactionCount) {
                const years = Array.from(
                    new Set(FormData.data.monthWiseTransactionCount.map((item) => item.monthyear?.split("-")[1]))
                );
                setYear(years.sort((a, b) => b - a));
                filterDatas(years[0], FormData.data.monthWiseTransactionCount).then((res) => {
                    setReport1(res);
                });
            }
        } else {
            if (FormData[0]?.Monthwisetrn_count) {
                const asArray = Object.entries(FormData[0]?.Monthwisetrn_count).map((arr) => {
                    const data = arr[0];
                    return data?.split("-")[1];
                });
                function onlyUnique(value, index, array) {
                    return array.indexOf(value) === index;
                }
                var uniques = asArray.filter(onlyUnique);
                setYear(uniques.sort().reverse());
                filterData(uniques[0], FormData[0]?.Monthwisetrn_count).then((res) => {
                    setReport1(res);
                });
            }
        }

        // else {
        //     setReport1(Object.values(Dashboard.MonthwiseTransaction));
        // }
    }, [FormData]);

    useEffect(() => {
        if (FormData[0]?.Monthwisetrn_result) {
            const asArray = Object.entries(FormData[0]?.Monthwisetrn_result).map((arr) => {
                const data = arr[0];
                return data?.split("-")[1];
            });
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            var uniques = asArray.filter(onlyUnique);
            setYear(uniques.sort().reverse());
            filterData(uniques[0], FormData[0]?.Monthwisetrn_result).then((res) => {
                setReport2(res);
            });
        }
        // else {
        //     setReport2(Object.values(Dashboard.MonthwiseTransaction));
        // }
    }, [FormData]);

    const filterData = async (data, object) => {
        if (FormData[0]?.Monthwisetrn_result) {
        const asArray = await Object?.entries(object);
        let filtered = await asArray?.filter(([key, value]) => key?.split("-")[1] === data);
        let year = filtered[0];
        if (year && year[0]) {
            year = year[0]?.split("-")[1];
        }

        let allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        let mergedArray = allMonths.map((month) => {
            let matchingData = filtered.find((d) => parseInt(d[0]?.split("-")[0]) == month);
            return matchingData ? matchingData : [`${month}-${year}`, 0];
        });

        const n = mergedArray.length;
        for (let i = 0; i < n - 1; i++) {
            for (let j = 0; j < n - i - 1; j++) {
                const monthA = parseInt(mergedArray[j][0]?.split("-")[0]);
                const monthB = parseInt(mergedArray[j + 1][0]?.split("-")[0]);
                if (monthA > monthB) {
                    const temp = mergedArray[j];
                    mergedArray[j] = mergedArray[j + 1];
                    mergedArray[j + 1] = temp;
                }
            }
        }
        const tempdata = await mergedArray.map((arr) => {
            return arr[1];
        });
        return tempdata;
    }
    };
    const handleSelectChange = async (data, arr, type) => {
        filterData(data, arr).then((res) => {
            if (type) setReport1(res);
            else setReport2(res);
        });
    };
    const handleSelectChanges = async (data, arr, type) => {
        filterDatas(data, arr).then((res) => {
            if (type) setReport1(res);
            else setReport2(res);
        });
    };
    const filterDatas = async (selectedYear, data) => {
        const groupedData = data.reduce((acc, item) => {
            const { monthyear, TotalTransactionCount } = item;
            acc[monthyear] = (acc[monthyear] || 0) + TotalTransactionCount;
            return acc;
        }, {});
        const allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        const result = allMonths.map((month) => {
            const key = `${month}-${selectedYear}`;
            return groupedData[key] || 0; 
        });
    
        return result;
    };

    return (
        <>
            <Header name={`${FormData[0]?.datastatus ? FormData[0]?.datastatus : ""}Dashboard`} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="analyticalReport">
                <div className="analyticalReportRFMAnalysis">
                    <div className="analyticalReportRFMAnalysis__topSection">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__leftSection">
                                <div className="dashboard__headingBackLink dashboard__headingBackLink2">
                                    {location?.state?.prevPath && (
                                        <Link to={"/upload-existing-data"}>
                                            <img style={{ width: "18px" }} src={backIcon2} alt="" />
                                            <span>Back</span>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="analyticalReportChartAnalysisOne">
                    <div id="dashboard" className="container">
                        <div className="analyticalReportChartAnalysisOne__bottomSection">
                            <div className="analyticalReportChartAnalysisOne__heading">
                                <h2>Customer Spending Overview</h2>
                            </div>
                            <div className="analyticalReportChartAnalysisOne__leftSection">
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconThree} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3> Customers</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                      dataID === "MER000252"  || dataID === "MER000289"  ?
                                      (
                                        <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{firstRender ? "" : FormData?.data?.totalCustomers ? FormData?.data?.totalCustomers : 0}</h3>
                                    </div>
                                      ) :
                                      (
                                        <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{firstRender ? "" : FormData[0]?.TotalCustomer ? FormData[0]?.TotalCustomer : 0}</h3>
                                    </div>
                                      )
                                    }
                                 
                                </div>

                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconThree} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3>Transactions</h3>
                                            </div>
                                        </div>
                                        {/* <div className="analyticalReportChartAnalysisOne__right">
                                            <div className="analyticalReportChartAnalysisOne__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                    {
                                      dataID === "MER000252" || dataID === "MER000289" ?
                                      (
                                        <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{firstRender ? "" : FormData?.data?.totalTransactionCount ? FormData?.data?.totalTransactionCount : 0}</h3>
                                    </div>
                                      ) :
                                      (
                                        <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{firstRender ? "" : FormData[0]?.Totaltrn ? FormData[0]?.Totaltrn : 0}</h3>
                                    </div>
                                      )
                                    }
                               
                                </div>
                                {
                                      dataID === "MER000252" || dataID === "MER000289" ?
                                      (
                                        <div className="analyticalReportChartAnalysisOne__box">
                                        <div className="analyticalReportChartAnalysisOne__header">
                                            <div className="analyticalReportChartAnalysisOne__left">
                                                <div className="analyticalReportChartAnalysisOne__image">
                                                    <img src={dashboardIconTwo} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisOne__details">
                                                    <h3>Total Campaign</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{firstRender ? "" : FormData?.data?.totalCampaigns ? FormData?.data?.totalCampaigns : 0}</h3>
                                        </div>
                                    </div>
                                      ) :
                                      (
                                        <div className="analyticalReportChartAnalysisOne__box">
                                        <div className="analyticalReportChartAnalysisOne__header">
                                            <div className="analyticalReportChartAnalysisOne__left">
                                                <div className="analyticalReportChartAnalysisOne__image">
                                                    <img src={dashboardIconTwo} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisOne__details">
                                                    <h3>Customer Average Spend</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="analyticalReportChartAnalysisOne__reportSection">
                                            <h3>{firstRender ? "" : FormData[0]?.Average ? Number(FormData[0]?.Average).toFixed(0) : 0}</h3>
                                        </div>
                                    </div>
                                      )
                                    }
                            
                            </div>
                            <div className="analyticalReportChartAnalysisOne__rightSection">
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconOne} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3>Transactions Count</h3>
                                            </div>
                                        </div>
                                        <div className="analyticalReportChartAnalysisOne__right">
                                            <div className="analyticalReportChartAnalysisOne__select">
                                            { dataID === "MER000252" || dataID === "MER000289" ? (
                                                  <select className="form-control selectArrow" onChange={(event) => handleSelectChanges(event.target.value, FormData?.data?.monthWiseTransactionCount , 1)}>
                                                  {Array.isArray(year) &&
                                                      year?.map((opt) => (
                                                          <option key={opt} value={opt}>
                                                              {opt}
                                                          </option>
                                                      ))}
                                              </select>
                                            ) : (
                                                <select className="form-control selectArrow" onChange={(event) => handleSelectChange(event.target.value, FormData[0]?.Monthwisetrn_count, 1)}>
                                                {Array.isArray(year) &&
                                                    year?.map((opt) => (
                                                        <option key={opt} value={opt}>
                                                            {opt}
                                                        </option>
                                                    ))}
                                            </select>
                                            )}
                                                  

                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisOne__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__chartSection">
                                        <BarChartAnalyticalReport1 data={Report1} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="analyticalReportChartAnalysisOne__topSection">
                            <div className="analyticalReportChartAnalysisOne__heading">
                                <h2>Data Overview</h2>
                            </div>
                            <div className="analyticalReportChartAnalysisOne__fullSection">
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconOne} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3>Transactions Amount</h3>
                                            </div>
                                        </div>
                                        <div className="analyticalReportChartAnalysisOne__right">
                                            <div className="analyticalReportChartAnalysisOne__select">
                                                <select className="form-control selectArrow" onChange={(event) => handleSelectChange(event.target.value, FormData[0]?.Monthwisetrn_result, 0)}>
                                                    {Array.isArray(year) > 0 &&
                                                        year?.map((opt) => (
                                                            <option key={opt} value={opt}>
                                                                {opt}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisOne__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__chartSection">
                                        <LineChartAnalyticalReport1 data={Report2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="analyticalReportChartAnalysisTwo">
                    <div className="container">
                        <div className="analyticalReportChartAnalysisTwo__topSection">
                            <div className="analyticalReportChartAnalysisTwo__heading">{Object.values(FormData[0]?.AgeWise ? FormData[0]?.AgeWise : {}).filter((val) => val > 0).length > 0 && <h2>Customer Demographic</h2>}</div>
                            {Object.values(FormData[0]?.AgeWise ? FormData[0]?.AgeWise : {}).filter((val) => val > 0).length > 0 && (
                                <div className="analyticalReportChartAnalysisTwo__leftSection">
                                    <div className="analyticalReportChartAnalysisTwo__box">
                                        <div className="analyticalReportChartAnalysisTwo__header">
                                            <div className="analyticalReportChartAnalysisTwo__left">
                                                <div className="analyticalReportChartAnalysisTwo__image">
                                                    <img src={dashboardIconTwo} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisTwo__details">
                                                    <h3>Age Bifurcation</h3>
                                                </div>
                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisTwo__right">
                                            <div className="analyticalReportChartAnalysisTwo__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className="analyticalReportChartAnalysisTwo__chartSection">
                                            <BarChartAnalyticalReport2 data={FormData[0]?.AgeWise} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {Object.values(FormData[0]?.AgeWise ? FormData[0]?.AgeWise : {}).filter((val) => val > 0).length > 0 && (
                                <div className="analyticalReportChartAnalysisTwo__rightSection">
                                    <div className="analyticalReportChartAnalysisTwo__box">
                                        <div className="analyticalReportChartAnalysisTwo__header">
                                            <div className="analyticalReportChartAnalysisTwo__left">
                                                <div className="analyticalReportChartAnalysisTwo__image">
                                                    <img src={dashboardIconThree} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisTwo__details">
                                                    <h3>Gender Bifurcation</h3>
                                                </div>
                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisTwo__right">
                                            <div className="analyticalReportChartAnalysisTwo__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className="analyticalReportChartAnalysisTwo__chartSection">
                                            <DoughnutChartAnalyticalReport1 Total_Male={FormData[0]?.Total_Male} Total_female={FormData[0]?.Total_female} Others={0} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="analyticalReportChartAnalysisTwo__bottomSection">
                            {location?.state?.prevPath && (
                                <div className="analyticalReportChartAnalysisTwo__rightSection">
                                    <div className="analyticalReportChartAnalysisTwo__paragraph">
                                        <p>With the Help of AI and existing data, use Novus Loyalty to set up campaign to supercharge your growth.</p>
                                    </div>
                                    <div className="analyticalReportChartAnalysisTwo__buttonSection">
                                        <Link to="/business-goals-selection" className="btn btn-primary">
                                            Select Your Business Goals
                                            <i className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                        {/* <Link to="" className="btn btn-outline-primary">
                                        Contact Novus Support
                                        <i className="fa-solid fa-comment-dots"></i>
                                    </Link> */}
                                        <Link to="/campaign-selection" state={{ path: "/analytical-report" }} className="btn btn-link">
                                            Skip
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default Dashboard;
