import React, { useState } from "react";
import swal from "sweetalert";
import { Link, useLocation } from "react-router-dom";

import { logo } from "../../Image/Index";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import { signup, Token_Generator } from "../../actions/auth";

function Thankyou() {
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const handleResendLink = async () => {
    const token = await Token_Generator();
    const response = await signup(token?.access_token, state.data);
    if (response.message === "User Created") {
      setLoader(false);
      swal({
        position: "center",
        icon: "success",
        title: `Resend link on ${state?.emailID} successfully`,
        showConfirmButton: false,
        timer: 5000,
      });
    } else if (response.message === "User Updated") {
      setLoader(false);
      swal({
        position: "center",
        icon: "success",
        title: `Resend link on ${state?.emailID} successfully`,
        showConfirmButton: false,
        timer: 5000,
      });
    } else {
      swal({
        title: " Oops!",
        text: response.message,
        icon: "error",
        button: "oh no!",
        timer: 5000,
      });
      setLoader(false);
    }
  };

  return (
    <section className="section signUpPage">
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="signUpPage__main">
        <AuthenticationSliderLeft />
        {state?.additionalUpdate ? (
          <div className="signUpPage__right">
            <div className="signUpPage__heading">
              <figure>
                <img src={logo} alt="logo" />
              </figure>

              <h1>Thank You for Coming again</h1>
              <h4 className="thankyouHeading">
                We have send again a confirmation e-mail to:{" "}
                <span>{state?.emailID}</span> <br />
                <p>
                  <Link
                    to=""
                    className="successMessage"
                    onClick={(e) => handleResendLink()}
                  >
                    Resend Link
                  </Link>
                </p>
                <strong>Click on the Activation Link</strong> to complete your
                sign in.
              </h4>
              <p>
                <Link
                  to="/contactus"
                  //   onClick={(e) =>
                  //     (window.location.href = "mailto:example@email.com")
                  //   }
                  className="btn btn-primary"
                >
                  Contact us
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <div className="signUpPage__right">
            <div className="signUpPage__heading">
              <figure>
                <img src={logo} alt="logo" />
              </figure>

              <h1>Thank You for Signing up!</h1>
              <h4 className="thankyouHeading">
                We just send you a confirmation e-mail to:{" "}
                <span>{state?.emailID}</span> <br />
                <p>
                  <Link
                    to=""
                    className="successMessage"
                    onClick={(e) => handleResendLink()}
                  >
                    Resend Link
                  </Link>
                </p>
                <strong>Click on the Activation Link</strong> to complete your
                sign in.
              </h4>
              <p>
                <Link
                  to="/contactus"
                  //   onClick={(e) =>
                  //     (window.location.href = "mailto:example@email.com")
                  //   }
                  className="btn btn-primary"
                >
                  Contact us
                </Link>
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Thankyou;
