import React, { useEffect, useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { ValidatorForm } from "react-form-validator-core";
import swal from "sweetalert";

import Header from "../../layout/Header";
import TextValidator from "../../partial/TextValidator";
import { GenerateCardData, getGeneratedCardData } from "../../../actions/auth";
import { getDate } from "../../../utils/helper";
import Paginated from "./Paginated";
import { COLUMNS } from "./membershipCardColumn";

const MembershipCard = () => {
  const [state, setState] = useState({
    showModal: false,
    count: null,
    data: [],
  });

  const [loader, setLoader] = useState(false);
  let token = JSON.parse(localStorage.getItem("token_gen"));
  const id = localStorage.getItem("Id");

  useEffect(() => {
    fetchGeneratedCardData();
    //eslint-disable-next-line
  }, []);

  const fetchGeneratedCardData = async () => {
    let response;
    try {
      setLoader(true);
      response = await getGeneratedCardData(token?.access_token, id);

      if (Array.isArray(response?.data) && response?.data?.length > 0) {
        setState((prev) => ({
          ...prev,
          data: response.data.map((ele) => {
            return {
              memberCardNumber: ele.memberCardNumber,
              createdDate: getDate(ele.createdDate, "DD-MMM-YYYY"),
              ExpiryDate: getDate(ele.ExpiryDate, "DD-MMM-YYYY"),
              status: ele.status ? "Active" : "Inactive",
            };
          }),
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const generateCSV = async () => {
    setLoader(true);
    try {
      const csvHeaders = [
        "Card Number",
        "Created Date",
        "Expiry Date",
        "Status",
      ];
      const csvData = [
        csvHeaders,
        ...state.data?.map((row) => [
          row.memberCardNumber,
          row.createdDate,
          row.ExpiryDate,
          row.status,
        ]),
      ];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((row) => row.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating CSV:", error.message || error);
    } finally {
      setLoader(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    try {
      const body = {};
      setLoader(true);
      response = await GenerateCardData(
        token?.access_token,
        id,
        state.count,
        body
      );
      setState((prev) => ({
        ...prev,
        showModal: false,
        count: null,
      }));
      if (response.message === "Generated membership data successfully.") {
        swal({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      fetchGeneratedCardData();
    } catch (error) {
      swal({
        title: " Oops!",
        text: response.message,
        icon: "error",
        button: "oh no!",
        timer: 5000,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Header name={"Membership Card Number "} />
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="dashboard__heading">
                <div className="dashboard__headingLeft">
                  <div className="dashboard__headingContent">
                    <Button
                      id="voucher"
                      className="btn btn-primary btn-lg"
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          showModal: true,
                        }))
                      }
                    >
                      Generate Card Number
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {state.showModal && (
              <Modal
                show={state.showModal}
                onHide={() =>
                  setState((prev) => ({
                    ...prev,
                    showModal: false,
                  }))
                }
                size="lg"
                centered
              >
                <Modal.Header>
                  <Modal.Title style={{ fontSize: "20px" }}>
                    Generate Card Number
                  </Modal.Title>
                  <CloseButton
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        showModal: false,
                        count: null,
                      }))
                    }
                    variant="black"
                  />
                </Modal.Header>
                <Modal.Body
                  className="dashboard__transactionsModal"
                  style={{ position: "relative", minHeight: "85px" }}
                >
                  <ValidatorForm
                    className="signUpPage__formSection"
                    onSubmit={handleSubmit}
                  >
                    <div className="modal-body">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="form-group">
                          <TextValidator
                            onChange={(e) =>
                              setState((prev) => ({
                                ...prev,
                                count: e.target.value,
                              }))
                            }
                            value={state.count}
                            placeholder="Enter number of card to be generate"
                            name="count"
                            className="form-control"
                            validators={[
                              "required",
                              "matchRegexp:^\\d+$",
                              "minNumber:1",
                            ]}
                            errorMessages={[
                              "This field is required",
                              "Please enter a valid number",
                              "Please enter a value greater than 0",
                            ]}
                            onKeyDown={(e) => {
                              if (
                                !/^\d$/.test(e.key) &&
                                ![
                                  "Backspace",
                                  "Delete",
                                  "ArrowLeft",
                                  "ArrowRight",
                                  "Tab",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }

                              if (
                                e.target.value.length === 0 &&
                                e.key === " "
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </ValidatorForm>
                </Modal.Body>
              </Modal>
            )}
            <div className="dashboard__customersCard">
              <div className="dashboard__header">
                <div className="dashboard__left">
                  <h4 id="voucher_details">Card Details</h4>
                </div>
                <div className="dashboard__right">
                  <button onClick={generateCSV} className="btn btn-default">
                    <i
                      class="fa-solid fa-download"
                      style={{ marginRight: "10px" }}
                    ></i>
                    Download Card Details
                  </button>
                </div>
              </div>
              <div className="dashboard__body">
                <Paginated data={state.data || []} columns={COLUMNS} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipCard;
