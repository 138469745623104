import { Link } from "react-router-dom";
export const DOCUMENT_COLUMN = [
  {
    Header: "Document Name",
    Footer: "Document Name",
    accessor: "mediaName",
    sticky: "left",
  },
  {
    Header: "Document Link",
    Footer: "Document Link",
    accessor: "blobUrl",
    Cell: ({ value }) => (
      <Link to={value}>
        <i className="fa-solid fa-download"></i>
      </Link>
    ),
  },
  {
    Header: "Delete",
    Footer: "Delete",
    accessor: "delete",
    // Cell: ({ value }) => (
    //     <Link to={value}>
    //     <i class="fa-solid fa-x"></i>
    //     </Link>
    // ),

    // Cell: ({ value }) => {
    // 	return format(new Date(value), "dd/MM/yyyy");
    // },
  },
];
