import { Link } from "react-router-dom";

export const COLUMNS = [
  
    {
        Header: "Customer Code",
        Footer: "Customer Code",
        accessor: "customerCode",
      
    },

    {
        Header: "Voucher Code",
        Footer: "Voucher Code",
        accessor: "vouchercode",
    },
    {
        Header: "Voucher Name",
        Footer: "Voucher Name",
        accessor: "voucherName",
    },
    {
        Header: "Value",
        Footer: "Value",
        accessor: "value",
    },

    {
        Header: "Expire Date",
        Footer: "Expire Date",
        accessor: "expiredate",
    },
    
   

    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
    },
];
