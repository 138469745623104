import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import Paginated from "./Paginated";
import { COLUMNS } from "./column";
import { getDate } from "../../../utils/helper";
import { getUploadData, UpdateMembership } from "../../../actions/auth";
import {
  transactionRegistration,
  UpdateUploadInVoiceData,
} from "../campaigns/services";
import swal from "sweetalert";
import { connect } from "react-redux";
import { getCustomer, clearCustomer } from "../../../actions/customer";
import { FaImage } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import TextValidator from "../../partial/TextValidator";
import { ValidatorForm } from "react-form-validator-core";

const UploadInvoice = ({ getCustomer, Customer, clearCustomer }) => {
  const [reponseData, setresponseData] = useState("");

  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const [data, setData] = useState([]);
  const [totaldata, setTotalData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [remarks, setRemarks] = useState({});
  const [cusData, setCusData] = useState([]);

  const fetch = async () => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    tempToken = tempToken?.access_token;
    const id = localStorage.getItem("Id");
    try {
      const res = await getUploadData(tempToken, id);
      const cusData = getCustomer(tempToken, id, 1, 10000, " ");
      setFormData(res.data);
    } catch (error) {
      console.error("Error fetching upload data:", error);
    } finally {
      setLoader(false);
    }
  };
  const handleImageClick = (imageUrl, invoiceId) => {
    setSelectedImage(imageUrl);
    setSelectedInvoiceId(invoiceId);
    setShowModal(true);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setCusData(Customer?.data?.data);
  }, [Customer]);

  useEffect(() => {
    if (formData?.length > 0) {
      const tempData = formData.map((ele) => {
        if (cusData?.data?.length > 0) {
          const flag = cusData.data.find(
            (cus) => cus.customerData.mobile === ele.mobile
          );
          if (flag) {
            return {
              ...ele,
              name: flag.customerData.name,
            };
          }
        }
        return ele;
      });
      setTotalData(tempData?.length ? tempData : []);
    }
  }, [cusData, formData]);

  useEffect(() => {
    const tempData = totaldata.map((val) => {
      val.title = val.invoicetitle ? val.invoicetitle : "-";
      val.name = val.name ? val.name : "-";
      val.mobile = val.mobile ? val.mobile : "-";
      const image1 = val?.imagesData[0]?.blobImageUrl || "";
      const image2 = val?.imagesData[1]?.blobImageUrl || "";
      val.image = (
        <>
          {image1 && (
            <FaImage
              className="image-icon"
              onClick={() => handleImageClick(image1, val.invoiceId)}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                color: "#007bff",
                marginRight: "10px",
              }}
            />
          )}
          {image2 && (
            <FaImage
              className="image-icon"
              onClick={() => handleImageClick(image2, val.invoiceId)}
              style={{ cursor: "pointer", fontSize: "20px", color: "#007bff" }}
            />
          )}
        </>
      );

      val.date = getDate(val.uploadedDate, "DD-MMM-YYYY");
      if (val?.isApproved === true) {
        val.status = "Approved";
      } else if (val.isApproved === false) {
        val.status = "Rejected";
      } else {
        val.status = (
          <div>
            <button
              className="btn btn-success btn-sm d-inline-flex"
              onClick={() => handleApprove(val)}
            >
              Approve
            </button>
            <button
              className="btn btn-danger btn-sm d-inline-flex ml-2"
              onClick={() => handleReject(val)}
            >
              Reject
            </button>
          </div>
        );
      }
      val.remarkId =
        val?.merchantFeedback?.length > 0 ? (
          val.merchantFeedback
        ) : (
          <input
            type="text"
            value={remarks[val.invoiceId] || ""}
            onChange={(e) => handleRemarkChange(val.invoiceId, e.target.value)}
            placeholder="Enter remark"
          />
        );
      return val;
    });
    setData(tempData);
  }, [totaldata, remarks]);

  const handleRemarkChange = (id, value) => {
    setRemarks((prevRemarks) => ({
      ...prevRemarks,
      [id]: value,
    }));
  };

  const handleSubmit = async (val) => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    try {
      const tempReview = await UpdateUploadInVoiceData(
        tempToken?.access_token,
        selectedInvoiceId,
        false,
        "N/A",
        reponseData.trim()
      );
      if (tempReview.message === "Invoice data updated successfully.") {
        swal({
          position: "center",
          icon: "success",
          title: "Invoice data updated successfully.",
          showConfirmButton: false,
          timer: 2000,
        });
        fetch();
      } else {
        swal({
          position: "center",
          icon: "error",
          title: tempReview.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setresponseData("");
      setShowModal(false);
    } catch (error) {
      console.error("Error updating invoice:", error);
    } finally {
      setLoader(false);
    }
  };
  const handleChange = (e) => {
    setresponseData(e.target.value);
  };
  const handleApprove = async (val) => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    const invoiceId = val?.invoiceId;
    const remark = remarks[val?.invoiceId] || "N/A";
    try {
      const tempReview = await UpdateUploadInVoiceData(
        tempToken?.access_token,
        invoiceId,
        true,
        "N/A",
        remark
      );
      const transBody = {
        activity: "photoupload",
        mobile: val?.mobile,
        schemacode: "TSC000022",
      };
      const transData = await transactionRegistration(
        tempToken?.access_token,
        JSON.stringify(transBody)
      );
      if (transData.message === "Request processed successfully.") {
        swal({
          position: "center",
          icon: "success",
          title: "Invoice data updated successfully.",
          showConfirmButton: false,
          timer: 2000,
        });
        fetch();
      } else {
        swal({
          position: "center",
          icon: "error",
          title: "Invoice data not updated successfully.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setRemarks("");
    } catch (error) {
      console.error("Error updating invoice:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleReject = async (val) => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    const invoiceId = val?.invoiceId;
    const remark = remarks[val?.invoiceId] || "N/A";
    try {
      const tempReview = await UpdateUploadInVoiceData(
        tempToken?.access_token,
        invoiceId,
        false,
        "N/A",
        remark
      );
      if (tempReview.message === "Invoice data updated successfully.") {
        swal({
          position: "center",
          icon: "success",
          title: "Invoice data updated successfully.",
          showConfirmButton: false,
          timer: 2000,
        });
        fetch();
      } else {
        swal({
          position: "center",
          icon: "error",
          title: tempReview.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setRemarks("");
    } catch (error) {
      console.error("Error updating invoice:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Header name={"Upload Invoice"} />
      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="dashboard__customersCard">
            <div className="dashboard__header">
              <div className="dashboard__left">
                <h4>Upload Invoice Details</h4>
              </div>
            </div>
            <Modal
              show={showModal}
              onHide={() => {
                setShowModal(false);
                setresponseData("");
              }}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Uploaded Image</Modal.Title>
              </Modal.Header>

              <div className="modal-body d-flex justify-content-center">
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Invoice"
                    className="uploadedImgVieww"
                    height={400}
                  />
                )}
              </div>
              <ValidatorForm
                className="signUpPage__formSection"
                onSubmit={handleSubmit}
              >
                <div className="modal-body">
                  <div className="form-group">
                    <label>Reason to reject</label>
                    <TextValidator
                      placeholder="Reason"
                      onChange={handleChange}
                      value={reponseData}
                      type="text"
                      name="reason"
                      className="form-control"
                      validators={["required"]}
                      errorMessages={["Please enter your reason"]}
                      onKeyDown={(e) => {
                        if (e.target.value.length === 0 && e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </ValidatorForm>
            </Modal>
            <div className="dashboard__body">
              <Paginated data={data || []} columns={COLUMNS} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Customer: state.Customer,
});
const mapDispatchToProps = {
  getCustomer,
  clearCustomer,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadInvoice);
