import React, { useEffect, useState, useRef } from "react";
import Header from "../../layout/Header";
import TextValidator from "../../partial/TextValidator";
import TextAreaValidator from "../../partial/TextAreaValidator";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SelectValidator from "../../partial/SelectValidator";
import { getDate, getDropdownObject } from "../../../utils/helper";
import { connect } from "react-redux";
import {
  getProfile,
  updateProfile,
  clearSetting,
  merchantBannerRecieptData,
  merchantBannerData,
  updateCatCache,
  mediaUpload,
  getMediaUpload,
  deleteMediaUpload,
} from "../../../actions/setting";
import swal from "sweetalert";
import { merchantLogo, merchantBanner } from "../../../actions/setting";
import {
  SendEmail,
  getsendEmail,
  sendEmailUpdate,
  sendEmailDelete,
  SocialMediaData,
  UpdateTrasactionProcessing,
  deleteBanner,
  getMerchantAndBanner,
  getMerchantAndLogo,
  getProgramPoint,
  BarCode,
} from "../../../actions/auth";
import { COLUMN } from "./coulmn1";
import { COLUMNS } from "./columnn";
import { Upload } from "./upload";
import { SMSCOLUMN } from "./smsColumn";
import { DOCUMENT_COLUMN } from "./docColumn";
import { OFFER_COLUMN } from "./offerColumn";
import { EMAIL_TEMPLATE_COLUMN } from "./emailTemplateCol";

import { decrypt, encrypt } from "../../../utils/encodingdecoding";
import { profileDropdown, documentDropdown } from "../../../actions/intro";
import { useDispatch, useSelector } from "react-redux";
import {
  eyeSeen,
  eyeSeenFocus,
  eyeUnseen,
  eyeUnseenFocus,
  backIcon2,
} from "../../../Image/Index";
import {
  UpdateCustomerPortalData,
  getMerchantNotificationAlert,
  merchantNotification,
  updatePassword,
  customerPortalSetting,
  getCustomerPortalSetting,
  deleteCustomerPortalSetting,
  uploadOffer,
  getOffer,
  deleteOffer,
  emailTemplateSetting,
  getEmailTemplateSetting,
  smsSetting,
  getSmsSetting,
} from "../campaigns/services";
import $ from "jquery";
import introJs from "intro.js";
import "intro.js/introjs.css";

import DatePicker from "react-datepicker";
import ModalCard from "../../layout/ModalCard";
import PasswordValidator from "../../partial/PasswordValidator";
import UploadBanner from "./UploadBanner";
import SmsSetting from "./SmsSetting";

// import { htmlToText } from 'html-to-text';
import CKEditor from "ckeditor4-react";
// import { CKEditor } from 'ckeditor4-react';
import EmailTemplateModal from "./EmailTemplateModal";
import parse from "html-react-parser";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import { useSocket } from "../../../context/socket/SocketProvider";

const ProfileSettings = ({
  getProfile,
  updateProfile,
  Setting,
  clearSetting,
}) => {
  // const { socket, isVisible } = useSocket();
  const formRef = useRef();

  const [options] = useState([
    { value: "External", label: "Display External Voucher First" },
    { value: "Internal", label: "Display internal Voucher First " },
  ]);
  const [optionsDisplay] = useState([
    { value: "desktop", label: "Desktop" },
    { value: "mobile", label: "Mobile" },
  ]);

  const [typeDisplay] = useState([
    { value: "Transaction", label: "Transaction" },
    { value: "Redemption", label: "Redemption" },
    { value: "Voucher Purcahse", label: "Voucher Purchase" },
    { value: "Giftcard Purchase", label: "GiftCard Purchase" },
  ]);
  const [socialMediaData, setSocialMediaData] = useState({ socialMedia: [] });
  const [isSocialMediaData, setIsSocialMediaData] = useState(true);
  const [displayTypedata, setDisplayTypeData] = useState("");
  const [display, setDisplay] = useState("");

  const location = useLocation();
  const imageInputRef = useRef();
  const [errorMessageData, setErrorMessageData] = useState();
  const [inStoreRedeption, setInStoreRedemption] = useState(false);
  const [isReceiptUpload, setIsReceiptUpload] = useState(false);
  const [changeInisReciptUpload, setChangeInisReciptUpload] = useState(false);
  const [changeIninStoreRedeption, setChangeIninStoreRedeption] =
    useState(false);
  const imageInputRefBanner = useRef();
  const uploadDocRef = useRef();
  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [brandName, setBrandName] = useState("");
  const [minimumData, setMinimumData] = useState("");
  const [bannerName, setBannerName] = useState("");
  const [size, setSIze] = useState(false);
  const [bannerSize, setBannerSize] = useState(false);
  const [internal, setInternal] = useState(false);
  const [external, setExternal] = useState(false);
  const [isDropDown, setIsDropDown] = useState(false);
  const [isPointImg, setIsPointImg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadDoc, setUploadDoc] = useState({
    size: "",
    name: "",
    file: "",
    uploadDocList: [],
  });
  const [formData, setFormData] = useState({
    name: "",
    emailID: "",
    contactNumber: "",
    selectedTypeId: "",
    companyName: "",
    rewardPortal: "",
    detailAboutProject: "",
    tAndC: false,
    error: false,
    rewardRatio: "25",
    timeZone: "US",
    currency: "USD",
    needSelectedTypeId: "",
  });

  const [emailid, setEmailID] = useState({
    id: "",
    merchantCode: "",
  });
  const [whatsApp, setWhatsapp] = useState({
    version: "",
    numId: "",
    senderNumber: "",
    accountId: "",
    template: "",
    whtspToken: "",
    status: false,
    merchantId: decrypt(localStorage.getItem("merchantcode")),
  });
  const { template, version, senderNumber, accountId, numId, whtspToken } =
    whatsApp;
  const fileRef = useRef();
  const [formMinimumData, setFromMinimumData] = useState({
    code: "string",
    redemptionOrder: true,
    registerEachAnonymousCustomer: false,
    processCampaignForAnonymousCustomer: true,
    useAnonymousCustomer: [
      {
        discriminator: "AnonymousCustomera",
        Select: true,
        customercode: "CUS63435844",
        EmailId: "test@clavax.com",
        MobileNumber: "9938000567",
        ActiveCustomer: true,
      },
    ],
    userRedeemOTPValidation: true,
    userRedeemForm: true,
    minimumRedeemValue: minimumData,
  });

  const intro = introJs();
  const dispatch = useDispatch();
  const modalOpen = useSelector((state) => state.intro.modalOpen);
  const [showModal, setShowModal] = useState(false);
  let packageNameList =
    localStorage.getItem("packageNameList") &&
    JSON.parse(decrypt(localStorage.getItem("packageNameList")));
  const [enterprisePackage, setEnterprisePackage] = useState();
  const [professionalPackage, setProfessionalPackage] = useState();
  const [starterPackage, setStarterPackage] = useState();
  const [litePackage, setLitePackage] = useState();
  const [currentPasswordFocus, setCurrentPasswordFocus] = useState(false);
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);
  const [isRevealOldPassword, setIsRevealOldPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [confirmFocus, setConfirmFocus] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [dataBanner, setDataBanner] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedEndData, setSelectedEndData] = useState(null);
  const [formDataPassword, setFormDataPassword] = useState({
    emailId: localStorage.getItem("email"),
    oldPassword: "",
    newPassword: "",
    comfirmPassword: "",
  });

  const [emailSetting, setEmailSetting] = useState({
    SendGridSMS: [
      {
        type: "",
        sendgridApiKey: "",
        status: false,
      },
    ],

    EmailTemplateSetting: [
      {
        EmailType: "",
        Subject: "",
        Body: "",
        fromEmail: "",
        fromName: "",
        sendgridApiKey: "",
        status: false,
      },
    ],
    emailTemplateData: {
      EmailType: "",
      Subject: "",
      Body: "",
      fromEmail: "",
      templateName: "",
      selectEmailType: "",
      fromName: "",
      sendgridApiKey: "",
      status: false,
    },
    smsData: {
      type: "",
      sendgridApiKey: "",
      title: "",
      header: "",
      status: false,
    },
    EarningBy: {
      EarningIdent: "mobile",
    },
  });
  const [templateModal, showTemplateModal] = useState({
    isOpen: false,
    body: "",
  });

  const [formDatas, setFormDatas] = useState({
    merchant: {
      tital: "",
      discription: "",
      startDateTime: "",
      endDateTime: "",
      status: false,
    },

    customer: {
      tital: "",
      discription: "",
      startDateTime: "",
      endDateTime: "",
      status: false,
    },
  });

  const [notificationErr, setNotificationErr] = useState({
    startDateErr: false,
    endDateErr: false,
  });

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setFormDatas((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        [name]: value,
      },
    }));
  };

  const handleTypeChange = (e, type) => {
    if (type === "sms") {
      setEmailSetting((prev) => ({
        ...prev,
        smsData: {
          ...prev.smsData,
          type: e.value,
        },
      }));
    } else {
      setEmailSetting((prev) => ({
        ...prev,
        emailTemplateData: {
          ...prev.emailTemplateData,
          EmailType: e.value,
          selectEmailType: e.value,
          templateName: "",
        },
      }));
    }
  };

  const handleSmsChange = (e, toggle) => {
    if (toggle) {
      setEmailSetting((prev) => ({
        ...prev,
        smsData: {
          ...prev.smsData,
          status: !emailSetting?.smsData?.status,
        },
      }));
    } else {
      setEmailSetting((prev) => ({
        ...prev,
        smsData: {
          ...prev.smsData,
          [e.target.name]: e.target.value,
        },
      }));
    }
  };

  const smsSubmit = async () => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    tempToken = tempToken?.access_token;

    smsSetting(tempToken, emailSetting.smsData).then((res) => {
      setLoader(false);
      if (res.message === "SMS Settings Updated Successfully") {
        swal({
          position: "center",
          icon: "success",
          title: "SMS Setting Set Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        fetchSMSTemplate();
        setEmailSetting((prev) => ({
          ...prev,
          smsData: {
            type: "",
            sendgridApiKey: "",
            title: "",
            header: "",
            status: false,
          },
        }));
        setLoader(false);
      } else {
        swal({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        setLoader(false);
      }
    });
  };

  const handleDateChange = (date) => {
    setSelectedData(date);
    setFormDatas((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        startDateTime: getDate(date, "DD/MM/YYYY:HH:mm"),
      },
    }));
    setNotificationErr((prev) => ({
      ...prev,
      startDateErr: false,
    }));
  };

  const handleDateEndChange = (date) => {
    setSelectedEndData(date);
    setFormDatas((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        endDateTime: getDate(date, "DD/MM/YYYY:HH:mm"),
      },
    }));
    setNotificationErr((prev) => ({
      ...prev,
      endDateErr: false,
    }));
  };

  const handleStatusToggle = () => {
    setFormDatas((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        status: !prevState.customer.status,
      },
    }));
  };

  const { emailId, oldPassword, newPassword, comfirmPassword } =
    formDataPassword;
  const onChangePassword = (e) =>
    setFormDataPassword({
      ...formDataPassword,
      ...{ [e.target.name]: e.target.value },
    });

  const handleCancelPassword = () =>
    setFormDataPassword({
      ...formDataPassword,
      emailId: "",
      oldPassword: "",
      newPassword: "",
      comfirmPassword: "",
    });

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== newPassword && value !== "") {
        return false;
      }
      return true;
    });
  }, [comfirmPassword, newPassword]);

  useEffect(() => {
    if (
      oldPassword === newPassword &&
      newPassword !== "" &&
      oldPassword !== ""
    ) {
      setErrorMessageData(
        "New password should not be matched with current password."
      );
    } else {
      setErrorMessageData("");
    }
  }, [oldPassword, newPassword]);

  const handleSubmit = async () => {
    if (newPassword === comfirmPassword) {
      let body = {
        email: emailId,
        oldpassword: oldPassword,
        newpassword: newPassword,
      };
      setLoader(true);
      let token = await JSON.parse(localStorage.getItem("token_gen"));
      let response = await updatePassword(token?.access_token, body);
      const { message } = response;
      setLoader(false);
      if (message.toLowerCase() === "your password is sucessfully updated") {
        setFormData({
          ...formData,
          oldPassword: "",
          newPassword: "",
          comfirmPassword: "",
        });
        swal({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        swal({
          position: "center",
          icon: "error",
          title: message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    } else {
      swal({
        title: " Oops!",
        text: "please match new and confirm password",
        icon: "warning",
        button: "oh no!",
        timer: 5000,
      });
    }
  };
  let tempPlan =
    localStorage.getItem("plan") && decrypt(localStorage.getItem("plan"));
  const {
    name,
    emailID,
    contactNumber,
    selectedTypeId,
    companyName,
    detailAboutProject,
    needSelectedTypeId,
    rewardRatio,
    timeZone,
    currency,
    rewardPortal,
  } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });

  const onChangeEmail = (e, toggle) => {
    if (toggle === "toggle") {
      setEmailSetting((prev) => ({
        ...prev,
        emailTemplateData: {
          ...prev.emailTemplateData,
          status: !emailSetting?.emailTemplateData?.status,
        },
      }));
    } else {
      if (toggle === "templateName") {
        return setEmailSetting((prev) => ({
          ...prev,
          emailTemplateData: {
            ...prev.emailTemplateData,
            EmailType: e.target.value,
            [e.target.name]: e.target.value,
            selectEmailType: "",
          },
        }));
      }
      setEmailSetting((prev) => ({
        ...prev,
        emailTemplateData: {
          ...prev.emailTemplateData,
          [e.target.name]: e.target.value,
        },
      }));
    }
  };

  const handleEditorChange = (e) => {
    const data = e.editor.getData();
    setEmailSetting((prev) => ({
      ...prev,
      emailTemplateData: {
        ...prev.emailTemplateData,
        Body: data,
      },
    }));
  };

  useEffect(() => {
    if (internal && external) {
      setIsDropDown(true);
    } else {
      setIsDropDown(false);
    }
  }, [internal, external]);

  const handleCancel = () =>
    setFormData({
      ...formData,
      contactNumber: "",
      rewardRatio: "",
      timeZone: "",
      currency: "",
    });

  useEffect(() => {
    setFormData({
      name: Setting.name,
      emailID: Setting.email,
      contactNumber: Setting.contactnumber,
      selectedTypeId: Setting.industry,
      companyName: Setting.companyname,
      rewardPortal: Setting.rewardportal,
      detailAboutProject: Setting.projectdetails,
      rewardRatio: Setting.rewardRatio,
      timeZone: Setting.timeZone,
      currency: Setting.currency,
      needSelectedTypeId: Setting.need,
    });

    if (tempPlan != Setting.plan && Setting.plan !== "") {
      localStorage.setItem("cardStatus", encrypt(Setting.cardStatus));
      localStorage.setItem("plan", encrypt(Setting.plan));
      localStorage.setItem(
        "moduleAccess",
        encrypt(JSON.stringify(Setting.moduleAccess))
      );
      localStorage.setItem(
        "packageNameList",
        encrypt(JSON.stringify(Setting.packageNameList))
      );
    }
  }, [Setting]);

  useEffect(() => {
    packageNameList.forEach((elem) => {
      if (elem.plan === "Enterprise") {
        setEnterprisePackage(elem);
      }
      if (elem.plan === "Professional") {
        setProfessionalPackage(elem);
      }
      if (elem.plan === "Starter") {
        setStarterPackage(elem);
      }
      if (elem.plan === "Lite") {
        setLitePackage(elem);
      }
    });
  }, []);

  const openTab = (
    id1,
    heading,
    id6,
    id2,
    id3,
    id4,
    id5,
    id7,
    id8,
    id9,
    id10,
    id11,
    id12,
    id13,
    id14
  ) => {
    $(id1).addClass("show");
    $(".accordion-item h2 button").addClass("collapsed");
    $(`${heading} button`).removeClass("collapsed");
    $(id6).removeClass("show");
    $(id2).removeClass("show");
    $(id3).removeClass("show");
    $(id4).removeClass("show");
    $(id5).removeClass("show");
    $(id7).removeClass("show");
    $(id8).removeClass("show");
    $(id9).removeClass("show");
    $(id10).removeClass("show");
    $(id11).removeClass("show");
    $(id12).removeClass("show");
    $(id13).removeClass("show");
    $(id14).removeClass("show");
  };

  useEffect(() => {
    if (window.location.search == "?p02") {
      openTab(
        "#collapseTwo",
        "#headingTwo",
        "#collapseOne",
        "#collapseThree",
        "#collapseFour",
        "#collapseFive",
        "#collapseSix",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p03") {
      openTab(
        "#collapseFour",
        "#headingFour",
        "#collapseOne",
        "#collapseThree",
        "#collapseTwo",
        "#collapseFive",
        "#collapseSix",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p06") {
      openTab(
        "#collapseSix",
        "#headingSix",
        "#collapseOne",
        "#collapseThree",
        "#collapseTwo",
        "#collapseFive",
        "#collapseFour",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p01") {
      openTab(
        "#collapseOne",
        "#headingOne",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseFive",
        "#collapseSix",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p04") {
      openTab(
        "#collapseThree",
        "#headingThree",
        "#collapseTwo",
        "#collapseOne",
        "#collapseFour",
        "#collapseFive",
        "#collapseSix",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p05") {
      openTab(
        "#collapseFive",
        "#headingFive",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p07") {
      openTab(
        "#collapseSeven",
        "#headingSeven",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p08") {
      openTab(
        "#collapseEight",
        "#headingEight",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseSeven",
        "#collapseNine",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p09") {
      openTab(
        "#collapseNine",
        "#headingNine",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseSeven",
        "#collapseEight",
        "#collapseTen",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p10") {
      openTab(
        "#collapseTen",
        "#headingTen",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseEleven",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p11") {
      openTab(
        "#collapseEleven",
        "#headingEleven",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseTen",
        "#collapseTwelve",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p12") {
      openTab(
        "#collapseTwelve",
        "#headingTwelve",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseEleven",
        "#collapseTen",
        "collapseThirteen",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p13") {
      openTab(
        "#collapseThirteen",
        "#headingThirteen",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseEleven",
        "#collapseTen",
        "collapseTwelve",
        "#collapseFourteen"
      );
    } else if (window.location.search == "?p14") {
      openTab(
        "#collapseThirteen",
        "#headingFourteen",
        "#collapseTwo",
        "#collapseThree",
        "#collapseFour",
        "#collapseOne",
        "#collapseSix",
        "#collapseFive",
        "#collapseSeven",
        "#collapseEight",
        "#collapseNine",
        "#collapseEleven",
        "#collapseTen",
        "collapseTwelve",
        "#collapseFourteen"
      );
    }
  }, [location]);

  const fetch = async () => {
    setLoader(true);
    await clearSetting();
    let body = {
      email: localStorage.getItem("email"),
      merchcode: localStorage.getItem("merchantcode"),
    };
    let token = await JSON.parse(localStorage.getItem("token_gen"));
    await getProfile(token?.access_token, body);
  };

  const onSubmit = async () => {
    setLoader(true);
    let body = {
      email: emailID,
      rewardRatio: rewardRatio,
      timeZone: timeZone,
      projectdetails: detailAboutProject,
      contactnumber: contactNumber,
      currency: currency,
    };
    let token = await JSON.parse(localStorage.getItem("token_gen"));
    await updateProfile(token?.access_token, body);
    await fetch();
    setLoader(false);
    swal({
      position: "center",
      icon: "success",
      title: "Profile Setting Details Updated",
      showConfirmButton: false,
      timer: 10000,
    });
  };
  const onChangeData = (e) => {
    setBrandName(e.target.value.replace(/^ +/gm, ""));
  };
  const onChangeMinimumData = (e) => {
    setMinimumData(e.target.value);
  };

  const onChangeBanner = (e) => {
    setBannerName(e.target.value.replace(/^ +/gm, ""));
  };

  const handleFile = (e) => {
    if (e.target.files[0] && e.target.files[0]?.size > 1 * 1024 * 1024) {
      setSIze(true);
    } else {
      const filedata = e.target.files[0];
      setSelectedFile(filedata);
      setSIze(false);
    }
  };

  const handleFileBanner = (e) => {
    if (e.target.files[0] && e.target.files[0]?.size > 1 * 1024 * 1024) {
      setBannerSize(true);
    } else {
      const filedata = e.target.files[0];
      setSelectedBanner(filedata);
      setBannerSize(false);
    }
  };

  const handleDocChange = (e) => {
    if (e.target.files[0] && e.target.files[0]?.size > 1 * 1024 * 1024) {
      setUploadDoc((prev) => ({
        ...prev,
        size: true,
      }));
    } else {
      const filedata = e.target.files[0];
      setUploadDoc((prev) => ({
        ...prev,
        size: false,
        file: filedata,
      }));
    }
  };

  const handleCancelData = () => {
    imageInputRef.current.value = "";
    setBrandName("");
  };

  const Submit = async (e) => {
    e.preventDefault();
    let token = JSON.parse(localStorage.getItem("token_gen"));
    setLoader(true);
    (await !size) &&
      merchantLogo(token?.access_token, brandName, selectedFile)
        .then((Setting) => {
          if (Setting.message === "success") {
            swal({
              position: "center",
              icon: "success",
              title: "logo updated successfully",
              showConfirmButton: false,
              timer: 10000,
            });
          }

          setSelectedFile(null);
          fetchDataBanner();
          // fetchData();
          setBrandName("");
          setLoader(false);
        })
        .catch((err) => {
          swal({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 10000,
          });
          setLoader(false);
        });
    imageInputRef.current.value = "";
  };

  // const fetchRedeemData = async () => {
  //     setLoader(true);
  //     let token = await JSON.parse(localStorage.getItem("token_gen"));
  //     const response = await getProgramPoint(token?.access_token);
  //     setMinimumData(response?.data[0]?.transactionPorcessingSettings.minimumRedeemValue);
  // };
  // useEffect(() => {
  //     fetchRedeemData();
  // }, []);
  function validateNumbersOnly(event) {
    const input = event.target.value;
    const regex = /^[0-9]*$/;

    if (!regex.test(input)) {
      event.target.value = input.replace(/[^0-9]/g, "");
    }
  }
  const handleSubmitData = async (e) => {
    e.preventDefault();
    let token = JSON.parse(localStorage.getItem("token_gen"));
    setLoader(true);
    const minimumRedeemValue = parseInt(minimumData);
    UpdateTrasactionProcessing(token?.access_token, {
      ...formMinimumData,
      minimumRedeemValue,
    })
      .then((Setting) => {
        swal({
          position: "center",
          icon: "success",
          title: "Redeem Value Updated Successfully",
          showConfirmButton: false,
          timer: 10000,
        });
        // fetchRedeemData();
        fetchDataBanner();
      })
      .catch((err) => {
        swal({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 10000,
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleCancelBanner = () => {
    imageInputRefBanner.current.value = "";
    setBannerName("");
  };

  const SubmitBanner = async (e) => {
    setLoader(true);
    e.preventDefault();
    let token = JSON.parse(localStorage.getItem("token_gen"));

    (await !bannerSize) &&
      merchantBanner(
        token?.access_token,
        bannerName,
        true,
        false,
        false,
        false,
        false,
        selectedBanner
      )
        .then((Setting) => {
          updateCatCache(token?.access_token);
          if (Setting.message === "success") {
            swal({
              position: "center",
              icon: "success",
              title: "Banner Uploaded Successfully",
              showConfirmButton: false,
              timer: 10000,
            });
          }

          setSelectedBanner(null);
          fetchDataBanner();
          setBannerName("");
          setLoader(false);
        })
        .catch((err) => {
          swal({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 10000,
          });
          setLoader(false);
        });
    imageInputRefBanner.current.value = "";
  };

  const fetchUploadDoc = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("token_gen"));
    const id = localStorage.getItem("Id");

    const res = await getMediaUpload(token?.access_token, id);
    if (res.message === "Media upload data retrieved successfully") {
      if (Array.isArray(res?.data) && res?.data?.length > 0) {
        setUploadDoc((prev) => ({
          ...prev,
          uploadDocList: res.data
            .filter((ele) => ele.filesData?.length > 0)
            .map((ele) => {
              return {
                mediaName: ele.mediaName,
                blobUrl: ele.filesData[0]?.blobUrl,
                delete: (
                  <Link
                    onClick={() =>
                      handleDeleteUploadDoc(ele.filesData[0]?.mediaId)
                    }
                  >
                    <i
                      className="fa fa-times-circle"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </Link>
                ),
              };
            }),
        }));
      }
    }
  };

  const handleDeleteUploadDoc = (id) => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    tempToken = tempToken?.access_token;
    deleteMediaUpload(tempToken, id).then((res) => {
      if (res.message === "Media deleted successfully.") {
        swal({
          position: "center",
          icon: "success",
          title: res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        fetchUploadDoc();
        setLoader(false);
      } else {
        swal({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        setLoader(false);
      }
    });
  };

  const handleUploadDoc = async (e) => {
    setLoader(true);
    e.preventDefault();
    let token = JSON.parse(localStorage.getItem("token_gen"));
    const id = localStorage.getItem("Id");

    (await !uploadDoc.size) &&
      mediaUpload(token?.access_token, id, uploadDoc.name, uploadDoc.file)
        .then((res) => {
          if (res.message === "Files uploaded and data stored successfully.") {
            swal({
              position: "center",
              icon: "success",
              title: "Document Uploaded Successfully",
              showConfirmButton: false,
              timer: 10000,
            });
          }

          setUploadDoc((prev) => ({
            ...prev,
            file: null,
            name: "",
          }));
          fetchUploadDoc();
          setLoader(false);
        })
        .catch((err) => {
          swal({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 10000,
          });
          setLoader(false);
        });
    uploadDocRef.current.value = "";
  };

  // const fetchData = async () => {
  //     setLoader(true);
  //     const token = JSON.parse(localStorage.getItem("token_gen"));
  //     const id = localStorage.getItem("Id");
  //     const response = await getMerchantAndLogo(token?.access_token, id);
  //     Array.isArray(response) && setData(response);
  // };
  const [dataa, setDataa] = useState();
  const values =
    decrypt(localStorage.getItem("merchantcode")) === "MER000844"
      ? `https://demo.novusloyalty.com/auth/login`
      : decrypt(localStorage.getItem("merchantcode")) === "MER000014"
      ? `https://makemytrip.novusloyalty.com/auth/login`
      : decrypt(localStorage.getItem("merchantcode")) === "MER000003"
      ? "https://domino.novusloyalty.com/auth/login"
      : `https://rewards-novusloyalty.azurewebsites.net/${decrypt(
          localStorage.getItem("merchantcode")
        )}`;

  const fetchBar = async () => {
    const token = JSON.parse(localStorage.getItem("token_gen"));
    const response = await BarCode(
      token?.access_token,
      `https://${rewardPortal}.paybypoint.com/signup`
    );
    setDataa(response.data);
    const link = document.createElement("a");
    link.href = response.data;
    link.download = "QRCode.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("maximumlength", (value) => {
      if (value.length > 500) {
        return false;
      }
      return true;
    });
  }, [detailAboutProject]);
  const initializeSocialMediaData = () => {
    return {
      socialMedia: socialMediaOptions.map((option) => ({
        socialMediaName: option.value,
        socialMediaLink: "",
        status: false,
      })),
    };
  };

  const handleViewModal = (body) => {
    showTemplateModal((prev) => ({
      ...prev,
      isOpen: true,
      body: body,
    }));
  };

  const fetchDataBanner = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("token_gen"));
    const id = localStorage.getItem("Id");
    // const response = await getMerchantAndBanner(token?.access_token, id);
    const res = await getCustomerPortalSetting(token?.access_token, id);
    if (res.message === "Settings retrieved successfully") {
      const {
        EarningBy,
        EmailTemplateSetting,
        MerchantBanner,
        MerchantLogo,
        MinimumRedeemValue,
      } = res.data;

      setDisplay(MerchantLogo?.length > 0 && MerchantLogo[0].displayFirst);
      setResponseData(MerchantBanner || []);
      setIsReceiptUpload(
        MerchantLogo?.length > 0 && MerchantLogo[0].isReciptUpload
      );
      setInStoreRedemption(
        MerchantLogo?.length > 0 && MerchantLogo[0].inStoreRedeption
      );
      setInternal(MerchantLogo?.length > 0 && MerchantLogo[0].internall);
      setExternal(MerchantLogo?.length > 0 && MerchantLogo[0].external);
      setIsPointImg(MerchantLogo?.length > 0 && MerchantLogo[0].isPointImg);
      setMinimumData(MinimumRedeemValue);
      Array.isArray(MerchantLogo) && setData(MerchantLogo);
      if (res.data.socialMedia === null || res.data.socialMedia === undefined) {
        setSocialMediaData(initializeSocialMediaData);
        setIsSocialMediaData(false);
      } else {
        const isAnyActive = res?.data?.socialMedia?.some(
          (item) => item.socialMediaLink !== "" || item.status === true
        );
        if (!isAnyActive) {
          setIsSocialMediaData(false);
        } else setIsSocialMediaData(true);
        setSocialMediaData({ socialMedia: res.data.socialMedia });
      }
    }
  };

  const fetchSMSTemplate = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("token_gen"));
    const id = localStorage.getItem("Id");
    const res = await getSmsSetting(token?.access_token, id);
    if (res.message === "SMS settings retrieved successfully") {
      const { SendGridSMS } = res.data;
      if (SendGridSMS?.length > 0) {
        SendGridSMS?.map((val) => {
          return (
            (val.type = val.type),
            (val.sendgridApiKey = val.sendgridApiKey),
            (val.status = val.status?.toString()),
            (val.delete = (
              <Link onClick={() => handleDeleteSMS(val.id, "SMS")}>
                <i
                  className="fa fa-times-circle"
                  style={{ fontSize: "18px" }}
                ></i>
              </Link>
            ))
          );
        });
      }
      setEmailSetting((prev) => ({
        ...prev,
        SendGridSMS: SendGridSMS,
      }));
    }
  };

  const handleDeleteSMS = (id, type) => {
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    tempToken = tempToken?.access_token;
    deleteCustomerPortalSetting(tempToken, id).then((res) => {
      setLoader(false);
      if (res.message === "Settings updated successfully") {
        swal({
          position: "center",
          icon: "success",
          title: `${type} delete Successfully`,
          showConfirmButton: false,
          timer: 2000,
        });
        fetchSMSTemplate();
        setLoader(false);
      } else {
        swal({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    (async () => {
      await fetch();
      // await fetchData();
      await fetchDataBanner();
      await fetchSMSTemplate();
      // await fetchRedeemData();
      await fetchNotificationAlert();
      await fetchUploadDoc();
      setLoader(false);
    })();
  }, []);

  const handleDelete = async (blobFilePath) => {
    setLoader(true);
    let token = JSON.parse(localStorage.getItem("token_gen"));
    const id = localStorage.getItem("Id");
    const blobPath = extractBlobPath(blobFilePath);
    deleteBanner(token?.access_token, id, blobPath)
      .then((Setting) => {
        if (Setting.message === "Success") {
          swal({
            position: "center",
            icon: "success",
            title: "Banner deleted Successfully",
            showConfirmButton: false,
            timer: 10000,
          });
        }

        fetchDataBanner();
        setLoader(false);
      })
      .catch((err) => {
        swal({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 10000,
        });
        setLoader(false);
      });
  };

  const extractBlobPath = (fullPath) => {
    const parts = fullPath.split("/");
    const blobPath = parts.slice(4).join("/");
    return blobPath;
  };

  useEffect(() => {
    if (!responseData) {
      return;
    }
    const tempData = responseData;
    if (tempData) {
      tempData?.map((val, ind) => {
        return (val.uploadedSource = val.uploadedSource);
      });
      tempData?.map((val, ind) => {
        return (val.blobFilePath = val.blobFilePath);
      });
      tempData?.map((val, ind) => {
        return (val.delete = (
          <Link onClick={() => handleDelete(val.blobFilePath)}>
            <i className="fa fa-times-circle" style={{ fontSize: "18px" }}></i>
          </Link>
        ));
      });
    }
    setDataBanner(tempData);
  }, [responseData]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (id === "signup_terms_inStoreRedeption") {
      setInStoreRedemption(checked);
      setChangeIninStoreRedeption(true);
    } else if (id === "signup_terms_isReceiptUpload") {
      setIsReceiptUpload(checked);
      setChangeInisReciptUpload(true);
    } else if (id === "internal") {
      setInternal(checked);
    } else if (id === "external") {
      setExternal(checked);
    } else {
      setIsPointImg(checked);
    }
  };

  const onHandleChange = (e) => {
    setDisplayTypeData(e.value);
  };

  const onSelectChange = (e) => {
    setDisplay(e.value);
  };

  const SubmitData = async (e) => {
    e.preventDefault();
    let token = JSON.parse(localStorage.getItem("token_gen"));
    setLoader(true);
    UpdateCustomerPortalData(
      token?.access_token,
      isReceiptUpload,
      inStoreRedeption,
      display,
      false,
      false,
      isPointImg ? isPointImg : false
    )
      .then((Setting) => {
        swal({
          position: "center",
          icon: "success",
          title: "Customer Portal Updated Successfully",
          showConfirmButton: false,
          timer: 10000,
        });
        setLoader(false);
      })
      .catch((err) => {
        swal({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 10000,
        });
        setLoader(false);
      });
  };

  const handleSubmitAlert = async () => {
    if (!formDatas.customer.startDateTime || !formDatas.customer.endDateTime) {
      if (!formDatas.customer.startDateTime)
        setNotificationErr((prev) => ({
          ...prev,
          startDateErr: true,
        }));
      if (!formDatas.customer.endDateTime)
        setNotificationErr((prev) => ({
          ...prev,
          endDateErr: true,
        }));
    } else {
      setLoader(true);
      const token = JSON.parse(localStorage.getItem("token_gen"));
      await merchantNotification(token?.access_token, formDatas, false).then(
        (res) => {
          swal({
            position: "center",
            icon: "success",
            title: "Request processed successfully.",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      );

      setLoader(false);
    }
  };

  const fetchNotificationAlert = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("token_gen"));
    await getMerchantNotificationAlert(token?.access_token).then((res) => {
      if (res?.data?.cutomer) {
        const customerData = res.data.cutomer;
        const merchantData = res.data.merchant;
        setFormDatas((prevState) => ({
          ...prevState,
          merchant: {
            tital: merchantData?.tital || "",
            discription: merchantData?.discription || "",
            startDateTime: merchantData?.startDateTime || "",
            endDateTime: merchantData?.endDateTime || "",
            status: merchantData?.status || false,
          },
          customer: {
            tital: customerData.tital || "",
            discription: customerData.discription || "",
            startDateTime: customerData.startDateTime || "",
            endDateTime: customerData.endDateTime || "",
            status: customerData.status || false,
          },
        }));
      }
    });
  };
  useEffect(() => {
    const validateName = (name) => {
      return /^[A-Za-z0-9\s&,$%*@!]*$/.test(name);
    };
    ValidatorForm.addValidationRule("validName", (value) => {
      if (validateName(value)) {
        return true;
      }
      return false;
    });
  }, [bannerName]);
  useEffect(() => {
    const validateName = (name) => {
      return /^[A-Za-z0-9\s&,$%*@!]*$/.test(name);
    };
    ValidatorForm.addValidationRule("validNameData", (value) => {
      if (validateName(value)) {
        return true;
      }
      return false;
    });
  }, [brandName]);

  const [socialMediaOptions] = useState([
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "linkedin", label: "Linkedin" },
    { value: "twitter", label: "Twitter" },
  ]);

  useEffect(() => {
    const initialSocialMediaData = socialMediaOptions.map((option) => ({
      socialMediaName: option.value,
      socialMediaLink: "",
      status: false,
    }));
    setSocialMediaData({ socialMedia: initialSocialMediaData });
  }, [socialMediaOptions]);

  const handleInputChange = (index, field, value) => {
    const newSocialMedia = [...socialMediaData.socialMedia];
    newSocialMedia[index][field] = value;
    setSocialMediaData({ socialMedia: newSocialMedia });
  };

  const SubmitMedia = (e) => {
    e.preventDefault();
    setLoader(true);
    let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    tempToken = tempToken?.access_token;
    const body = {
      ...socialMediaData,
      EarningBy: {
        EarningIdent: "email",
      },
    };
    customerPortalSetting(tempToken, body).then((res) => {
      setLoader(false);
      if (res.message === "Settings Updated Successfully") {
        swal({
          position: "center",
          icon: "success",
          title: "Social Profile Updated Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        fetchDataBanner();
        setLoader(false);
      } else {
        swal({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        setLoader(false);
      }
    });
  };
  const handleWhtspFile = (event) => {
    // setState({ ...state, file: event.target.files[0], error: false });
    const file = event.target.files[0];
    const reader = new FileReader();
    const fileExtension = file?.name?.split(".").pop().toLowerCase();
    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split("\n").filter((line) => line.trim() !== "");
      // const headers = lines[0].split(",").map((header) => header.trim());
      const data = lines.slice(1).map((line) => {
        return line.trim();
      });
      setWhatsapp((prev) => ({
        ...prev,
        receiptNumber: data,
      }));
    };
    reader.readAsText(file);
  };

  const handleSubmitWhatsapp = async () => {
    // const recipients = ["918219898872", "918881358668"];
    // for (const recipient of receiptNumber) {
    //     const body = {
    //         messaging_product: "whatsapp",
    //         to: recipient,
    //         type: "template",
    //         template: {
    //             name: template,
    //             language: {
    //                 code: "en_US"
    //             }
    //         }
    //     };
    //     try {
    //         setLoader(true)
    //         const response = await whatsappMessage(whtspToken, version, numId, body);
    //         setLoader(false)
    //     } catch (error) {
    //         setLoader(false)
    //     }
    // }
    // recipients = ["918219898872", "918881358668"]
    // const body = {
    //     messaging_product: "whatsapp",
    //     to: recipients,
    //     type: "template",
    //     template: {
    //         name: template,
    //         language: {
    //             code: "en_US"
    //         }
    //     }
    // }
    // const response = await whatsappMessage(whtspToken, version, numId, body)
  };

  const onChangeWhatsapp = (e, toggle) => {
    if (toggle === "toggle") {
      return setWhatsapp((prev) => ({
        ...prev,
        status: !whatsApp?.status,
      }));
    }
    setWhatsapp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Header name={"Profile Settings"} />
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {showModal && <ModalCard />}
      <div className="dashboard__content">
        <div className="container-fluid">
          {/* <div className="dashboard__profileSettingsMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                        <div className="dashboard__headingContent">
                                            <h3>Profile Settings</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="accordion pp_accordian" id="accordionExample">
            <div id="profile_se" className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  disabled={localStorage.getItem("firstLogin")}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Profile Settings
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    {/* <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3>Profile Settings</h3>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="dashboard__profileSettingsBox">
                      <ValidatorForm
                        className="customForm"
                        ref={formRef}
                        onSubmit={(e) => onSubmit(e)}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group">
                              <label>Name (required)</label>
                              <TextValidator
                                placeholder="ABC Bank"
                                className="form-control"
                                name="name"
                                value={name}
                                disabled
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Email ID</label>
                              <TextValidator
                                placeholder="novus@loyalty.com"
                                className="form-control"
                                name="emailID"
                                value={emailID}
                                disabled
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Contact Number</label>
                              <TextValidator
                                type="text"
                                className="form-control "
                                name="contactNumber"
                                value={contactNumber}
                                validators={[
                                  "required",
                                  "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$",
                                ]}
                                errorMessages={[
                                  "Please enter your contact number",
                                  "Please enter a valid number",
                                ]}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Industry</label>
                              <TextValidator
                                placeholder="Industry"
                                className="form-control"
                                name="industry"
                                value={selectedTypeId}
                                disabled
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>What you Need</label>
                              <TextValidator
                                placeholder="what you need"
                                className="form-control"
                                name="what you need"
                                value={needSelectedTypeId}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Company Name</label>
                              <TextValidator
                                type="text"
                                name="companyName"
                                value={companyName}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="form-group withIcon">
                              <label>Details About your Project</label>
                              <TextValidator
                                type="text"
                                placeholder="Details about your project"
                                className="form-control"
                                name="detailAboutProject"
                                value={detailAboutProject}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="button-section">
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleCancel}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="upload_logo" className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  disabled={localStorage.getItem("firstLogin")}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Upload Logo
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    {/* <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3>Upload Logo</h3>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="dashboard__profileSettingsBox">
                      <ValidatorForm
                        className="customForm"
                        ref={formRef}
                        onSubmit={(e) => Submit(e)}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Logo</label>
                              <input
                                className="form-control"
                                type="file"
                                ref={imageInputRef}
                                accept={
                                  "image/png, image/gif, image/jpeg, image/jpg"
                                }
                                required
                                onChange={handleFile}
                              />
                              {size && (
                                <span style={{ color: "red" }}>
                                  file size not more than 1 MB
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Brand Name</label>
                              <TextValidator
                                placeholder="Brand Name"
                                className="form-control"
                                name="emailID"
                                validators={["required", "validNameData"]}
                                errorMessages={[
                                  "Please enter your name",
                                  "Enter valid Name",
                                ]}
                                value={brandName}
                                onChange={onChangeData}
                              />
                            </div>
                          </div>
                          <div>
                            <p style={{ color: "black", fontSize: "13px" }}>
                              {" "}
                              <span style={{ color: "red" }}>*</span>only one
                              logo can be uploded,Newly uploded logo will
                              replace old
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="button-section">
                              <button type="submit" className="btn btn-primary">
                                Upload
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleCancelData}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                          <h6 style={{ color: "black", fontSize: "18px" }}>
                            Uploaded Logo
                          </h6>
                          {data && (
                            <div>
                              <Upload columns={COLUMNS} data={data} />
                            </div>
                          )}
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="upload_banner" className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button
                  className="accordion-button collapsed"
                  disabled={localStorage.getItem("firstLogin")}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Upload Banner
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    {/* <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3>Upload Logo</h3>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="dashboard__profileSettingsBox">
                      <ValidatorForm
                        className="customForm"
                        ref={formRef}
                        onSubmit={(e) => SubmitBanner(e)}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Banner</label>
                              <input
                                className="form-control"
                                type="file"
                                ref={imageInputRefBanner}
                                accept={
                                  "image/png, image/gif, image/jpeg, image/jpg"
                                }
                                required
                                onChange={handleFileBanner}
                              />
                              {bannerSize && (
                                <span style={{ color: "red" }}>
                                  file size not more than 1 MB
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Banner Name</label>
                              <TextValidator
                                placeholder="Banner Name"
                                className="form-control"
                                name="emailID"
                                validators={["required", "validName"]}
                                errorMessages={[
                                  "Please enter your name",
                                  "Enter valid Name",
                                ]}
                                value={bannerName}
                                onChange={onChangeBanner}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="button-section">
                              <button type="submit" className="btn btn-primary">
                                Upload
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleCancelBanner}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                          <h6 style={{ color: "black", fontSize: "18px" }}>
                            Uploaded Banner
                          </h6>

                          <div>
                            <UploadBanner columns={COLUMN} data={dataBanner} />
                          </div>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Customer Portal
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    {/* <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3> Customer Portal</h3>
                                                </div>
                                            </div>
                                        </div> */}
                    <ValidatorForm
                      className="customForm"
                      ref={formRef}
                      onSubmit={SubmitData}
                    >
                      <div className="dashcheckMainBox">
                        <div className="row">
                          <div className="dashcheckBox col-md-4">
                            <label
                              htmlFor="signup_terms_isReceiptUpload"
                              className="signup_checklabel"
                            >
                              <input
                                type="checkbox"
                                id="signup_terms_isReceiptUpload"
                                checked={isReceiptUpload}
                                onChange={handleCheckboxChange}
                              />

                              <p>Display Recipt Upload</p>
                            </label>
                          </div>
                          <div className="dashcheckBox col-md-4">
                            <label
                              htmlFor="signup_terms_inStoreRedeption"
                              className="signup_checklabel"
                            >
                              <input
                                type="checkbox"
                                id="signup_terms_inStoreRedeption"
                                checked={inStoreRedeption}
                                onChange={handleCheckboxChange}
                              />
                              <p>Display Store Redemption</p>
                            </label>
                          </div>
                          <div className="dashcheckBox col-md-4">
                            <label
                              htmlFor="isPointImg"
                              className="signup_checklabel"
                            >
                              <input
                                type="checkbox"
                                id="isPointImg"
                                checked={isPointImg}
                                onChange={handleCheckboxChange}
                              />
                              <p>Display Point Image</p>
                            </label>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "10px" }}>
                          {/* <div className="dashcheckBox col-md-4">
                                                        <label htmlFor="internal" className="signup_checklabel">
                                                            <input type="checkbox" id="internal" checked={internal} onChange={handleCheckboxChange} />
                                                            <p>Internal</p>
                                                        </label>
                                                    </div> */}
                          {/* <div className="dashcheckBox col-md-4">
                                                        <label htmlFor="external" className="signup_checklabel">
                                                            <input type="checkbox" id="external" checked={external} onChange={handleCheckboxChange} />
                                                            <p>External</p>
                                                        </label>
                                                    </div>

                                                    {isDropDown && (
                                                        <div className="dashcheckBox col-md-4">
                                                            <SelectValidator placeholder="" className="custom-ReactSelect bordered" name="display" value={getDropdownObject(display, options)} options={options} onChange={onSelectChange} validators={["required"]} errorMessages={["Please select gift card order"]} />
                                                        </div>
                                                    )} */}

                          <div
                            className="dashcheckBox with_button"
                            style={{ marginTop: "10px" }}
                          >
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>

                        {/* <div className="dashcheckBox">
                                                    <label htmlFor="signup_terms_isReceiptUpload" className="signup_checklabel">
                                                        <input type="checkbox" id="signup_terms_isReceiptUpload" checked={isReceiptUpload} onChange={handleCheckboxChange} />

                                                        <p>Display Recipt Upload</p>
                                                    </label>
                                                </div>
                                                <div className="dashcheckBox">
                                                    <label htmlFor="signup_terms_inStoreRedeption" className="signup_checklabel">
                                                        <input type="checkbox" id="signup_terms_inStoreRedeption" checked={inStoreRedeption} onChange={handleCheckboxChange} />
                                                        <p>Display Store Redemption</p>
                                                    </label>
                                                </div>
                                                <div className="dashcheckBox">
                                                    <label htmlFor="isPointImg" className="signup_checklabel">
                                                        <input type="checkbox" id="isPointImg" checked={isPointImg} onChange={handleCheckboxChange} />
                                                        <p>Display Point Image</p>
                                                    </label>
                                                </div>
                                                <div className="dashcheckBox">
                                                    <label htmlFor="internal" className="signup_checklabel">
                                                        <input type="checkbox" id="internal" checked={internal} onChange={handleCheckboxChange} />
                                                        <p>Internal</p>
                                                    </label>
                                                </div>
                                                <div className="dashcheckBox">
                                                    <label htmlFor="external" className="signup_checklabel">
                                                        <input type="checkbox" id="external" checked={external} onChange={handleCheckboxChange} />
                                                        <p>External</p>
                                                    </label>
                                                </div>
                                                {isDropDown && (
                                                    <div className="dashcheckBox">
                                                        <SelectValidator placeholder="" className="custom-ReactSelect bordered" name="display" value={getDropdownObject(display, options)} options={options} onChange={onSelectChange} validators={["required"]} errorMessages={["Please select gift card order"]} />
                                                    </div>
                                                )}
                                                <div className="dashcheckBox with_button">
                                                    <button type="submit" className="btn btn-primary">
                                                        Submit
                                                    </button>
                                                </div> */}
                      </div>
                    </ValidatorForm>

                    <div className="dashboard__profileSettingsBox">
                      <div className="profileSettingsBoxCSP">
                        <div className="profileSettingsmerchantbox">
                          <div
                            className="customForm"
                            style={{ fontSize: "13px" }}
                          >
                            {/* {decrypt(localStorage.getItem("merchantcode")) === "MER000844" ? (
                                                            <Link target="_blank" to={`https://demo.novusloyalty.com/auth/login`}>
                                                                {`https://demo.novusloyalty.com/auth/login`}
                                                            </Link>
                                                        ) : decrypt(localStorage.getItem("merchantcode")) === "MER000014" ? (
                                                            <Link target="_blank" to={`https://makemytrip.novusloyalty.com/auth/login`}>
                                                                {`https://makemytrip.novusloyalty.com/auth/login`}
                                                            </Link>
                                                        ) : decrypt(localStorage.getItem("merchantcode")) === "MER000162" ? (
                                                            <Link target="_blank" to={`https://elevate.hypefinancial.com`}>
                                                                {`https://elevate.hypefinancial.com`}
                                                            </Link>
                                                        ) : ( */}
                            <Link
                              target="_blank"
                              to={`https://${rewardPortal}.paybypoint.com/offer`}
                            >
                              {`https://${rewardPortal}.paybypoint.com/offer`}
                            </Link>
                          </div>
                        </div>
                        <div className="profileSettingsqrcodebox">
                          <div className="profileSettingsBoxCSPdetails">
                            <p> Need Custom Domain ?</p>
                            <Link
                              to="/contact-us"
                              className="btn btn-outline-primary"
                            >
                              Contact Novus Support
                              <i className="fa-solid fa-comment-dots"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="profileSettingsqrcodebox">
                        <div
                          className="profileSettingsBoxCSPdetails"
                          style={{ fontWeight: "600" }}
                        >
                          <Link
                            to={dataa}
                            onClick={fetchBar}
                            download
                            className="btn btn-outline-primary"
                            style={{
                              backgroundColor: "#2056a5",
                              color: "white",
                            }}
                          >
                            Generate QR Code
                            <i className="fa-solid fa-download"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Packages
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="dashboard__profileSettingsMain">
                                        <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3> Packages</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__plansNewMainBox">
                                            <div className={litePackage?.id == tempPlan || starterPackage?.id == tempPlan ? "plansNewBox current_plan" : "plansNewBox"}>
                                                {(litePackage?.id == tempPlan || starterPackage?.id == tempPlan) && <div className="current_plan_title">Your Current Plan</div>}
                                                <div className="plansInnerBox">
                                                    <h3>Standard</h3>
                                                    <h4>
                                                        US$99<span>/mo</span>
                                                    </h4>
                                                    <p>Setup Fees - US$ 500</p>
                                                    <h5>&nbsp;</h5>
                                                    <ul>
                                                        <li>Loyalty Engine</li>
                                                        <li>Reward Engine</li>
                                                        <li>Offer Management</li>
                                                        <li>Referral Program</li>
                                                        <li>Marketing Campaigns</li>
                                                        <li>Points Program</li>
                                                        <li>Vouchers</li>
                                                        <li>Limited APIs</li>
                                                        <li>Alerts, Limitations</li>
                                                        <li>Fraud Prevention</li>
                                                        <li>Email Communication</li>
                                                        <li>Automated Reward Fulfillment</li>
                                                    </ul>
                                                </div>
                                                <div className="plan_select_btn">
                                                    {litePackage?.id == tempPlan || starterPackage?.id == tempPlan ? (
                                                        <button disabled>Your Current Plan</button>
                                                    ) : (
                                                        <Link to={litePackage?.payLink + "&prefilled_email=" + decrypt(localStorage.getItem("email"))} target="_blank">
                                                            <button>Select</button>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={starterPackage?.id == tempPlan ? "plansNewBox current_plan" : "plansNewBox"}>
                                                {starterPackage?.id == tempPlan && <div className="current_plan_title">Your Current Plan</div>}
                                                <div className="plansInnerBox">
                                                    <h3>Starter</h3>
                                                    <h4>
                                                        US$250<span>/mo</span>
                                                    </h4>
                                                    <p>Setup Fees - US$ 5,000</p>
                                                    <h5>Everything in Lite, Plus:</h5>
                                                    <ul>
                                                        <li>Tier Management</li>
                                                        <li>SMS Communication</li>
                                                        <li>Voucher Template Customizations</li>
                                                        <li>Field Level Customizations</li>
                                                        <li>Upto 10 Schema Customization</li>
                                                        <li>Brandable User Experiences</li>
                                                        <li>Rewards Customization</li>
                                                    </ul>
                                                </div>
                                                <div className="plan_select_btn">
                                                    {starterPackage?.id == tempPlan ? (
                                                        <button disabled>Your Current Plan</button>
                                                    ) : (
                                                        <Link to={starterPackage?.payLink + "&prefilled_email=" + decrypt(localStorage.getItem("email"))} target="_blank">
                                                            <button>Select</button>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={professionalPackage?.id == tempPlan ? "plansNewBox mostpopular current_plan" : "plansNewBox mostpopular"}>
                                                {professionalPackage?.id == tempPlan && <div className="current_plan_title">Your Current Plan</div>}
                                                <div className="plansInnerBox">
                                                    <div className="mpstripbox">
                                                        <span className="mpstrip">Most Popular</span>
                                                    </div>
                                                    <h3>Professional</h3>
                                                    <h4>
                                                        US$500<span>/mo</span>
                                                    </h4>
                                                    <p>Setup Fees - US$ 10,000</p>
                                                    <h5>Everything in Starter, Plus:</h5>
                                                    <ul>
                                                        <li>Gift Card</li>
                                                        <li>Merchant/Store Management</li>
                                                        <li>Membership</li>
                                                        <li>Discount Program</li>
                                                        <li>Upto 25 Schema Customization</li>
                                                        <li>Third Party Integrations</li>
                                                        <li>Extended APIs</li>
                                                    </ul>
                                                </div>
                                                <div className="plan_select_btn">
                                                    {professionalPackage?.id == tempPlan ? (
                                                        <button disabled>Your Current Plan</button>
                                                    ) : (
                                                        <Link to={professionalPackage?.payLink + "&prefilled_email=" + decrypt(localStorage.getItem("email"))} target="_blank">
                                                            <button>Select</button>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={enterprisePackage?.id == tempPlan ? "plansNewBox current_plan" : "plansNewBox"}>
                                                {enterprisePackage?.id == tempPlan && <div className="current_plan_title">Your Current Plan</div>}
                                                <div className="plansInnerBox">
                                                    <h3>Enterprise</h3>
                                                    <h4>Custom</h4>
                                                    <p>&nbsp;</p>
                                                    <h5>Everything in Pro, Plus:</h5>
                                                    <ul>
                                                        <li>Whatsapp Communication</li>
                                                        <li>Unlimited Schema Customization</li>
                                                        <li>Push Notifications</li>
                                                        <li>Gamification</li>
                                                        <li>Program Sponsors</li>
                                                        <li>Cashback Program</li>
                                                        <li>On Premise Hosting</li>
                                                        <li>Free Support Hours</li>
                                                        <li>Free Program Consultation</li>
                                                        <li>Ready-made rewards catalogue</li>
                                                        <li>Communication and Marketing</li>
                                                    </ul>
                                                </div>
                                                <div className="plan_select_btn">
                                                    {enterprisePackage?.id == tempPlan ? (
                                                        <button disabled>Your Current Plan</button>
                                                    ) : (
                                                        <Link to={enterprisePackage?.payLink} target="_blank">
                                                            <button>Select</button>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Reset Password
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    <div className="dashboard__profileSettingsBox">
                      <div className="dashboard__profileSettingsBox">
                        <ValidatorForm
                          className="customForm"
                          onSubmit={handleSubmit}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Current Password</label>
                                <span className="inputIcon">
                                  {currentPasswordFocus ? (
                                    <img
                                      className="onFocus"
                                      title={
                                        isRevealOldPassword
                                          ? "Hide password"
                                          : "Show password"
                                      }
                                      src={
                                        isRevealOldPassword
                                          ? eyeUnseenFocus
                                          : eyeSeenFocus
                                      }
                                      alt="eye"
                                      onClick={() =>
                                        setIsRevealOldPassword(
                                          (prevState) => !prevState
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      title={
                                        isRevealOldPassword
                                          ? "Hide password"
                                          : "Show password"
                                      }
                                      src={
                                        isRevealOldPassword
                                          ? eyeUnseen
                                          : eyeSeen
                                      }
                                      alt="eye"
                                      onClick={() =>
                                        setIsRevealOldPassword(
                                          (prevState) => !prevState
                                        )
                                      }
                                    />
                                  )}
                                </span>
                                <PasswordValidator
                                  placeholder="Enter Current Password"
                                  className="form-control"
                                  name="oldPassword"
                                  maxLength="16"
                                  value={oldPassword}
                                  type={
                                    isRevealOldPassword ? "text" : "password"
                                  }
                                  onChange={onChangePassword}
                                  validators={["required"]}
                                  errorMessages={["Please enter password."]}
                                />
                                {/* <img className="inputIcon" src={lockIcon} alt="" /> */}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>New Password</label>
                                <span className="inputIcon">
                                  {newPasswordFocus ? (
                                    <img
                                      className="onFocus"
                                      title={
                                        isRevealNewPassword
                                          ? "Hide password"
                                          : "Show password"
                                      }
                                      src={
                                        isRevealNewPassword
                                          ? eyeUnseenFocus
                                          : eyeSeenFocus
                                      }
                                      alt="eye"
                                      onClick={() =>
                                        setIsRevealNewPassword(
                                          (prevState) => !prevState
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      title={
                                        isRevealNewPassword
                                          ? "Hide password"
                                          : "Show password"
                                      }
                                      src={
                                        isRevealNewPassword
                                          ? eyeUnseen
                                          : eyeSeen
                                      }
                                      alt="eye"
                                      onClick={() =>
                                        setIsRevealNewPassword(
                                          (prevState) => !prevState
                                        )
                                      }
                                    />
                                  )}
                                </span>
                                <PasswordValidator
                                  placeholder="Enter New Password"
                                  className="form-control"
                                  name="newPassword"
                                  minLength="6"
                                  maxLength="16"
                                  value={newPassword}
                                  type={
                                    isRevealNewPassword ? "text" : "password"
                                  }
                                  onChange={onChangePassword}
                                  validators={[
                                    "required",
                                    "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$",
                                  ]}
                                  errorMessages={[
                                    "Please enter password.",
                                    "condition",
                                  ]}
                                />

                                {/* <PasswordValidator placeholder="New Password" className="form-control" name="newPassword" minLength="6" maxLength="16" value={newPassword} type={isRevealPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$"]} errorMessages={["Please enter password.", "condition"]} onFocus={() => setPasswordFocus(true)} onBlur={() => setPasswordFocus(false)} /> */}
                                {/* <img className="inputIcon" src={lockIcon} alt="" /> */}
                                {errorMessageData && (
                                  <div style={{ color: "red" }}>
                                    {errorMessageData}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Confirm Password</label>
                                <span className="inputIcon">
                                  {confirmFocus ? (
                                    <img
                                      className="onFocus"
                                      title={
                                        isConfirmPassword
                                          ? "Hide password"
                                          : "Show password"
                                      }
                                      src={
                                        isConfirmPassword
                                          ? eyeUnseenFocus
                                          : eyeSeenFocus
                                      }
                                      alt="eye"
                                      onClick={() =>
                                        setIsConfirmPassword(
                                          (prevState) => !prevState
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      title={
                                        isConfirmPassword
                                          ? "Hide password"
                                          : "Show password"
                                      }
                                      src={
                                        isConfirmPassword ? eyeUnseen : eyeSeen
                                      }
                                      alt="eye"
                                      onClick={() =>
                                        setIsConfirmPassword(
                                          (prevState) => !prevState
                                        )
                                      }
                                    />
                                  )}
                                </span>
                                <PasswordValidator
                                  placeholder="Enter Confirm Password"
                                  className="form-control"
                                  name="comfirmPassword"
                                  maxLength="16"
                                  value={comfirmPassword}
                                  type={isConfirmPassword ? "text" : "password"}
                                  onChange={onChangePassword}
                                  validators={["required", "isPasswordMatch"]}
                                  errorMessages={[
                                    " Please enter confirm password.",
                                    "Password Mismatch.",
                                  ]}
                                />
                                {/* <img className="inputIcon" src={lockIcon} alt="" /> */}
                              </div>
                            </div>
                            <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                              <div
                                className="button-section"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={handleCancelPassword}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </ValidatorForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Minimum Redeem Value
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsBox">
                    <div className="dashboard__profileSettingsBox">
                      <ValidatorForm
                        className="customForm"
                        onSubmit={handleSubmitData}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Minimum Redeem Value</label>
                              <TextValidator
                                placeholder="Minimum Redeem Value"
                                maxLength="10"
                                onInput={validateNumbersOnly}
                                className="form-control"
                                name="emailID"
                                validators={["required"]}
                                errorMessages={[
                                  " Please enter Minimum Redeem Value",
                                ]}
                                value={minimumData}
                                onChange={onChangeMinimumData}
                              />
                              {minimumData?.length > 9 && (
                                <span className="errorMessage">
                                  Redeem value should be not more than 9 digits
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <div
                            className="button-section"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="upload_logo" className="accordion-item">
              <h2 className="accordion-header" id="headingNine">
                <button
                  className="accordion-button collapsed"
                  disabled={localStorage.getItem("firstLogin")}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Social Profile
                </button>
              </h2>
              <div
                id="collapseNine"
                className="accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    <div className="dashboard__profileSettingsBox">
                      <ValidatorForm
                        className="customForm"
                        onSubmit={SubmitMedia}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
                            <div className="form-group withIcon">
                              <label>Social Media Type</label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7">
                            <div className="form-group withIcon">
                              <label>Social Media URL</label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="form-group withIcon">
                              <label>Display on Customer Portal</label>
                            </div>
                          </div>
                          {socialMediaOptions?.map((option, index) => (
                            <React.Fragment key={index}>
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
                                <div className="form-group withIcon">
                                  <b>{option.label}</b>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7">
                                <div className="form-group withIcon">
                                  <TextValidator
                                    placeholder="Enter URL"
                                    className="form-control"
                                    name={`${option.value}Url`}
                                    value={
                                      socialMediaData?.socialMedia?.[index]
                                        ?.socialMediaLink
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "socialMediaLink",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div className="form-group withIcon">
                                  <div
                                    className="person"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="">
                                      <label className="toggle">
                                        <input
                                          type="checkbox"
                                          name={`${option.value}Status`}
                                          checked={
                                            socialMediaData?.socialMedia?.[
                                              index
                                            ]?.status
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "status",
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <span className="slider"></span>
                                        <span
                                          className="labels"
                                          data-on="Yes"
                                          data-off="No"
                                        ></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="button-section">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={
                                  !isSocialMediaData &&
                                  JSON.stringify(
                                    initializeSocialMediaData()
                                  ) === JSON.stringify(socialMediaData)
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEight">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Customer Portal Alert Notification
                </button>
              </h2>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    {/* <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3>Profile Settings</h3>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="dashboard__profileSettingsBox">
                      <ValidatorForm
                        className="customForm"
                        ref={formRef}
                        onSubmit={handleSubmitAlert}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group">
                              <label>Alert Title</label>
                              <TextValidator
                                type="text"
                                value={formDatas.customer.tital}
                                onChange={handleChangeData}
                                placeholder="Alert Title"
                                className="form-control"
                                name="tital"
                                validators={["required"]}
                                errorMessages={["Please enter alert title"]}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Start Date Time</label>
                              <DatePicker
                                selected={selectedData}
                                value={formDatas.customer.startDateTime}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy:hh:mm"
                                //   filterDate={filterWeekends}
                                showTimeSelect
                                timeIntervals={30}
                                timeFormat="hh:mm"
                                placeholder="Enter Start date"
                                name="startDate"
                              />
                              {notificationErr.startDateErr && (
                                <span className="errorMessage">
                                  Please select start date
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>End Date Time</label>
                              <DatePicker
                                selected={selectedEndData}
                                value={formDatas.customer.endDateTime}
                                onChange={handleDateEndChange}
                                dateFormat="dd/MM/yyyy:hh:mm"
                                //   filterDate={filterWeekends}
                                showTimeSelect
                                timeIntervals={30}
                                timeFormat="hh:mm"
                                placeholder="Enter End date"
                                name="endDate"
                              />
                              {notificationErr.endDateErr && (
                                <span className="errorMessage">
                                  Please select end date
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="row voucher_box_row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div
                                className="person"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <p
                                  className="voucher"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "0",
                                    color: "black",
                                  }}
                                >
                                  Status
                                </p>
                                <div className="">
                                  <label className="toggle">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      checked={formDatas.customer.status}
                                      onChange={handleStatusToggle}
                                    />
                                    <span className="slider"></span>
                                    <span
                                      className="labels"
                                      data-on="Active"
                                      data-off="Inactive"
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8">
                              <div className="form-group withIcon">
                                <label>Notification Description</label>

                                <TextAreaValidator
                                  type="text"
                                  value={formDatas.customer.discription}
                                  onChange={handleChangeData}
                                  placeholder="Notification Description"
                                  className="form-control"
                                  name="discription"
                                  validators={["required"]}
                                  errorMessages={[
                                    "Please enter notification description",
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="button-section">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div id="offer" className="accordion-item">
                            <h2 className="accordion-header" id="headingTen">
                                <button className="accordion-button collapsed" disabled={localStorage.getItem("firstLogin")} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    Offer
                                </button>
                            </h2>
                            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                </div>
                            </div>
                        </div> */}
            <div id="send_sms" className="accordion-item">
              <h2 className="accordion-header" id="headingEleven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  SMS Send
                </button>
              </h2>
              <div
                id="collapseEleven"
                className="accordion-collapse collapse"
                aria-labelledby="headingEleven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    <div className="dashboard__profileSettingsBox">
                      <div className="dashboard__profileSettingsBox">
                        <ValidatorForm
                          className="customForm"
                          onSubmit={smsSubmit}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Type</label>
                                <SelectValidator
                                  placeholder="Select Type"
                                  className="custom-ReactSelect bordered"
                                  name="type"
                                  value={
                                    emailSetting?.smsData?.type
                                      ? getDropdownObject(
                                          emailSetting?.smsData?.type,
                                          typeDisplay
                                        )
                                      : ""
                                  }
                                  options={typeDisplay}
                                  onChange={(e) => handleTypeChange(e, "sms")}
                                  validators={["required"]}
                                  errorMessages={["Please select type"]}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Title</label>
                                <TextValidator
                                  type="text"
                                  className="form-control"
                                  placeholder="Title"
                                  name="title"
                                  onChange={handleSmsChange}
                                  value={emailSetting?.smsData?.title}
                                  validators={["required"]}
                                  errorMessages={["Title is required"]}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Header</label>
                                <TextValidator
                                  type="text"
                                  className="form-control"
                                  placeholder="Header"
                                  name="header"
                                  onChange={handleSmsChange}
                                  value={emailSetting?.smsData?.header}
                                  validators={["required"]}
                                  errorMessages={["Header is required"]}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Send Grid APIkey</label>
                                <TextValidator
                                  type="text"
                                  className="form-control"
                                  placeholder="API Key"
                                  name="sendgridApiKey"
                                  onChange={handleSmsChange}
                                  value={emailSetting?.smsData?.sendgridApiKey}
                                  validators={["required"]}
                                  errorMessages={["API Key is required"]}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 d-flex justify-content-between align-items-center">
                              <div
                                className="person"
                                style={{ alignItems: "center", width: "100%" }}
                              >
                                <p
                                  className="voucher mb-2"
                                  style={{
                                    display: "flex",
                                    width: "25%",
                                    marginBottom: "0",
                                    color: "black",
                                  }}
                                >
                                  Status
                                </p>
                                <div className="">
                                  <label className="toggle">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      checked={emailSetting?.smsData?.status}
                                      onChange={(e) =>
                                        handleSmsChange(e, "toggle")
                                      }
                                    />
                                    <span className="slider"></span>
                                    <span
                                      className="labels"
                                      data-on="Active"
                                      data-off="Inactive"
                                    ></span>
                                  </label>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary align-self-center"
                              >
                                Submit
                              </button>
                            </div>
                            <div>
                              <SmsSetting
                                columns={SMSCOLUMN}
                                data={emailSetting?.SendGridSMS || []}
                              />
                            </div>
                          </div>
                        </ValidatorForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div id="template_setting" className="accordion-item">
              <h2 className="accordion-header" id="headingTwelve">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="false"
                  aria-controls="collapseTwelve"
                >
                  Email Template Setting
                </button>
              </h2>
              <div
                id="collapseTwelve"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwelve"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    <div className="dashboard__profileSettingsBox">
                      <div className="dashboard__profileSettingsBox">
                        <ValidatorForm
                          className="customForm"
                          onSubmit={handleSubmitEmail}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Subject</label>
                                <TextValidator
                                  placeholder="Subject"
                                  className="form-control"
                                  name="Subject"
                                  onChange={onChangeEmail}
                                  value={
                                    emailSetting?.emailTemplateData?.Subject
                                  }
                                  validators={["required"]}
                                  errorMessages={["Subject is required"]}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>From Name</label>
                                <TextValidator
                                  placeholder="Name"
                                  type="text"
                                  className="form-control"
                                  name="fromName"
                                  onChange={onChangeEmail}
                                  value={
                                    emailSetting?.emailTemplateData?.fromName
                                  }
                                  validators={["required"]}
                                  errorMessages={["Name is required"]}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Form Email</label>
                                <TextValidator
                                  placeholder="novus@loyalty.com"
                                  className="form-control"
                                  name="fromEmail"
                                  onChange={onChangeEmail}
                                  value={
                                    emailSetting?.emailTemplateData?.fromEmail
                                  }
                                  validators={["required", "isEmail"]}
                                  errorMessages={[
                                    "From email is required",
                                    "Email is not valid",
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Send Grid APIkey</label>
                                <TextValidator
                                  placeholder="API Key"
                                  type="text"
                                  className="form-control"
                                  name="sendgridApiKey"
                                  onChange={onChangeEmail}
                                  value={
                                    emailSetting?.emailTemplateData
                                      ?.sendgridApiKey
                                  }
                                  validators={["required"]}
                                  errorMessages={["API Key is required"]}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>Email Template</label>
                                <div className="row">
                                  <div className="col-11">
                                    <SelectValidator
                                      placeholder="Select Type"
                                      className="custom-ReactSelect bordered"
                                      name="selectEmailType"
                                      value={
                                        emailSetting?.emailTemplateData
                                          ?.selectEmailType
                                          ? getDropdownObject(
                                              emailSetting?.emailTemplateData
                                                ?.selectEmailType,
                                              typeDisplay
                                            )
                                          : ""
                                      }
                                      options={typeDisplay}
                                      onChange={(e) =>
                                        handleTypeChange(e, "email")
                                      }
                                    />
                                  </div>
                                  <div className="col-1 text-center px-0 pt-2">
                                    OR
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group withIcon">
                                <label>&nbsp;</label>
                                <TextValidator
                                  placeholder="Enter template name"
                                  className="form-control"
                                  name="templateName"
                                  onChange={(e) =>
                                    onChangeEmail(e, "templateName")
                                  }
                                  value={
                                    emailSetting?.emailTemplateData
                                      ?.templateName
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                              <div
                                className="person"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <p
                                  className="voucher mb-2"
                                  style={{
                                    display: "flex",
                                    width: "25%",
                                    marginBottom: "0",
                                    color: "black",
                                  }}
                                >
                                  Status
                                </p>
                                <div className="">
                                  <label className="toggle">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      checked={
                                        emailSetting?.emailTemplateData?.status
                                      }
                                      onChange={(e) =>
                                        onChangeEmail(e, "toggle")
                                      }
                                    />
                                    <span className="slider"></span>
                                    <span
                                      className="labels"
                                      data-on="Active"
                                      data-off="Inactive"
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-12">
                              <CKEditor
                                config={{ versionCheck: false }}
                                data={emailSetting?.emailTemplateData?.Body}
                                onChange={handleEditorChange}
                                name="Body"
                              />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3">
                              <div
                                className="button-section"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div>
                              <SmsSetting
                                columns={EMAIL_TEMPLATE_COLUMN}
                                data={emailSetting?.EmailTemplateSetting || []}
                              />
                            </div>
                          </div>
                        </ValidatorForm>
                      </div>
                      {templateModal?.isOpen && (
                        <EmailTemplateModal
                          show={templateModal?.isOpen}
                          close={() =>
                            showTemplateModal((prev) => ({
                              ...prev,
                              isOpen: false,
                            }))
                          }
                          body={templateModal.body}
                          header={"Email Template"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div id="upload_media" className="accordion-item">
              <h2 className="accordion-header" id="headingFourteen">
                <button
                  className="accordion-button collapsed"
                  disabled={localStorage.getItem("firstLogin")}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourteen"
                  aria-expanded="false"
                  aria-controls="collapseFourteen"
                >
                  Upload Document
                </button>
              </h2>
              <div
                id="collapseFourteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingFourteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="dashboard__profileSettingsMain">
                    <div className="dashboard__profileSettingsBox">
                      <ValidatorForm
                        className="customForm"
                        ref={formRef}
                        onSubmit={(e) => handleUploadDoc(e)}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Document</label>
                              <input
                                className="form-control"
                                type="file"
                                ref={uploadDocRef}
                                accept={
                                  ".csv, .xlsx, .pdf, .docx, image/png, image/gif, image/jpeg, image/jpg"
                                }
                                required
                                onChange={handleDocChange}
                              />
                              {uploadDoc.size && (
                                <span style={{ color: "red" }}>
                                  file size not more than 1 MB
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group withIcon">
                              <label>Document Name</label>
                              <TextValidator
                                placeholder="Document Name"
                                className="form-control"
                                name="document"
                                validators={["required", "validName"]}
                                errorMessages={[
                                  "Please enter document name",
                                  "Enter valid Name",
                                ]}
                                value={uploadDoc.name}
                                onChange={(e) =>
                                  setUploadDoc((prev) => ({
                                    ...prev,
                                    name: e.target.value.replace(/^ +/gm, ""),
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="button-section">
                              <button type="submit" className="btn btn-primary">
                                Upload
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  uploadDocRef.current.value = "";
                                  setUploadDoc((prev) => ({
                                    ...prev,
                                    name: "",
                                  }));
                                }}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                          <h6 style={{ color: "black", fontSize: "18px" }}>
                            Uploaded Document
                          </h6>
                          <div>
                            <UploadBanner
                              columns={DOCUMENT_COLUMN}
                              data={uploadDoc.uploadDocList || []}
                            />
                          </div>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  Setting: state.Setting,
});

const mapDispatchToProps = {
  getProfile,
  updateProfile,
  clearSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
