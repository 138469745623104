import React, { useState, useEffect, memo } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getDate,
  selectValueGetter,
  selectValueGetterBoolean,
  getOperator,
} from "../../../../utils/helper";
import Switch from "react-switch";
import TextValidator from "../../../partial/TextValidator";
import SelectValidator from "../../../partial/SelectValidator";
import { connect } from "react-redux";
import {
  voucherTemplateDetail,
  clearVoucherTemplate,
} from "../../../../actions/voucher";
import { campaign, schema } from "../services";
import { decrypt, encrypt } from "../../../../utils/encodingdecoding";
import swal from "sweetalert";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { getCustomer } from "../../../../actions/schema";

const CreateEarningRulesTabLoyalty = memo((props) => {
  const [labelValuePairs, setLabelValuePairs] = useState([]);
  const [queryData, setQueryData] = useState({});
  const [queryDatas, setQueryDatas] = useState([]);
  const location = useLocation();
  const [toggle, setToggle] = useState("AND");
  const [toggles, setToggles] = useState("AND");
  const [voucherOptions, setVoucherOptions] = useState([]);
  const {
    formPage,
    VoucherTemplate,
    voucherTemplateDetail,
    clearVoucherTemplate,
    formateData,
    handleTabChange,
  } = props;
  const navigate = useNavigate();

  const initialState = {
    id: 1,
    toggle: "AND",
    toggles: "AND",

    earnigWhoData: { Customer: "", EarnigWho: "" },
    infixRule: [
      {
        id: "transactionType-3",
        entityName: "Transaction",
        field: "",
        format: null,
        jsonPath: "",
        operator: "",
        type: "",
        value: "",
        isFlag: false,
        data: {
          class: "Transaction",
        },
      },
    ],
    prefixRule: {
      condition: "AND",
      rules: [
        {
          id: "transactionType-3",
          field: "",
          type: "",
          input: "",
          operator: "",
          value: "",
          data: {
            class: "Transaction",
          },
        },
      ],
      valid: true,
    },
    data: [
      formPage === "voucher"
        ? {
            AccountType: "",
            RedeemType: "",
            Value: 0,
            VoucherValueType: "",
            VoucherTemplate: "",
            VoucherEarningType: "",
            isEvery: false,
            FullPartial: "Full",
            EarnigWho: "Customer",
            Sponsors: [
              {
                Type: "Merchant",
                Code: decrypt(localStorage.getItem("merchantcode")),
                Percent: 100,
              },
            ],
            expirationRule: {
              afterDuration: "Day(s)",
              afterSpecificNumber: 365,
              onAfter: true,
            },
            redeemRule: {
              redeemRuleBy: false,
              afterSpecificTransaction: false,
            },
            TransactionField: "",
            everyValue: 0,
            earning_frequency: true,
            wallet_type: false,
            MerchantWalletName: "",
          }
        : {
            AccountType: "",
            Value: 10,
            FixPercentage: "",
            RewardRatio: 25,
            Sponsors: [
              {
                Type: "Merchant",
                Code: decrypt(localStorage.getItem("merchantcode")),
                Percent: 100,
              },
            ],
            expirationRule: {
              afterDuration: "Day(s)",
              afterSpecificNumber: 365,
              onAfter: true,
            },
            redeemRule: {
              redeemRuleBy: false,
              afterSpecificDate: "",
              afterSpecificTransaction: false,
            },
            EarnigWho: "Customer",
            RedeemType: "",
            TransactionField: "",
            FullPartial: "Full",
            isEvery: false,
            everyValue: 0,
            wallet_type: false,
            //   isCapLimit: false,
          },
    ],
    dbFunctionRule: [
      {
        sqlRule: null,
        infixRule: [],
        prefixRule: null,
      },
    ],
    IncomeDBFunctionRule: [
      {
        sqlRule: null,
        infixRule: [],
        prefixRule: null,
      },
    ],
    function: [
      {
        duration: "",
        infixRule: [],
        prefixRule: null,
        durationLimit: "",
        isAdvanceRule: false,
        infixRuleInc: [],
        prefixRuleInc: null,
        infixRuleExist: [],
        prefixRuleExist: null,
      },
    ],
  };
  const [state, setState] = useState([initialState]);
  const [data1, setData1] = useState(false);
  const [data2, setData2] = useState(false);
  const [data, setData] = useState();
  const [isRuleAdded, setIsRuleAdded] = useState(false);
  const [emailValidate, setEmailValidate] = useState(false);
  const [option, setOption] = useState({
    earningWhoOpt: [
      { label: "Customer", value: "Customer", name: "earningWho" },
    ],
    customerOpt: [],
    schemaOpt: [],
    amountOption: [],
    voucherOption: [],
    accountTypeOpt:
      formPage === "voucher"
        ? [{ value: "Voucher", label: "Voucher" }]
        : formPage === "points"
        ? [{ value: "Point", label: "Point" }]
        : [
            { value: "Point", label: "Point" },
            { value: "Voucher", label: "Voucher" },
          ],
    earningTypeOpt: [
      { value: "Fix", label: "Fix" },
      { value: "Percentage", label: "Percentage" },
    ],
    optionOnAfter: [
      { value: true, label: "On" },
      { value: false, label: "After" },
    ],
    optionsDAta: [
      { value: "No Value", label: "No Value" },
      { value: "Pre Loaded Value", label: "Pre Loaded Value" },
    ],
    optionAfter: [{ value: "Day(s)", label: "Day(s)" }],
    transactionOptAdv: [
      { label: "TransactionCount", value: "TransactionCount" },
    ],
    numberOpt: [
      { operator: "=", label: "Equal", value: "equal" },
      { operator: "!=", label: "Not Equal", value: "not_equal" },
      { operator: "<", label: "Less", value: "less" },
      { operator: "<=", label: "Less Or Equal", value: "less_or_equal" },
      { operator: ">", label: "Greater", value: "greater" },
      { operator: ">=", label: "Greater Or Equal", value: "greater_or_equal" },
      { operator: "", label: "Between", value: "between" },
    ],
    stringCondition: [
      { operator: "=", label: "Equal", value: "equal" },
      { operator: "!=", label: "Not Equal", value: "not_equal" },
      { operator: "==", label: "In", value: "in" },
      { operator: "!=", label: "Not In", value: "not_in" },
      // { operator: "!", label: "is not null", value: "is_not_null" },
    ],
    transactionOptionAdv: [
      // { label: "Select", value: "" },
      { operator: "=", label: "Equal", value: "equal" },
    ],
    data: [],
    advEarningOpt: [],
    durationOpt: [
      {
        value: "OneTime",
        label: "One Time",
        limitValue: "OneTime",
      },
      {
        value: "Day",
        label: "Day",
        limitValue: "Daily",
      },
      {
        value: "Week",
        label: "Week",
        limitValue: "Weekly",
      },
      {
        value: "Month",
        label: "Month",
        limitValue: "Monthly",
      },
      {
        value: "Quarterly",
        label: "Quarterly",
        limitValue: "Quarterly",
      },
      {
        value: "Yearly",
        label: "Yearly",
        limitValue: "Yearly",
      },
    ],
  });
  const [finalData, setFinalData] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState([]);
  const [selectedTransCount, setSelectedTransCount] = useState([]);
  const [isFinalData, setIsFinalData] = useState(false);
  const [response, setResponse] = useState({
    schemaRes: [],
  });
  const {
    earningWhoOpt,
    customerOpt,
    accountTypeOpt,
    earningTypeOpt,
    optionOnAfter,
    optionAfter,
    transactionOptAdv,
    numberOpt,
    advEarningOpt,
    durationOpt,
    schemaOpt,
    voucherOption,
    stringCondition,
    transactionOptionAdv,
    amountOption,
    nulldata,
    optionsDAta,
  } = option;
  const { schemaRes } = response;
  //--------------------------------------------use Effect -----------------------------------------
  useEffect(() => {
    setFinalData(formateData);
  }, [formateData]);

  useEffect(() => {
    setFinalData((prevState) => ({
      ...prevState,
      earningRule: {
        ...prevState.earningRule,
        rules: state,
      },
    }));
  }, [state]);

  useEffect(() => {
    setLoader(true);
    clearVoucherTemplate();
    const token = JSON.parse(localStorage.getItem("token_gen"));
    voucherTemplateDetail(token?.access_token);
    setLoader(false);
  }, []);

  // useEffect(() => {
  //     handleVoucherData();
  // }, [VoucherTemplate]);

  useEffect(() => {
    fetchSchema();
  }, []);

  useEffect(() => {
    const invalidDomains = [
      "mailinator",
      "cwmxc",
      "horsgit",
      "maildrop",
      "explodemail",
      "stresspc",
      "anonaddy",
    ];
    const validateEmail = (email) => {
      const domainPattern = invalidDomains.join("|");
      const regexPattern = `^(?!.*@(${domainPattern})\\b)([a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`;

      const regex = new RegExp(regexPattern);
      if (email) {
        if (regex.test(email)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };
    ValidatorForm.addValidationRule("validEmail", (value) => {
      if (validateEmail(value)) {
        return true;
      }
      return false;
    });
  }, [emailValidate]);
  //-------------------------------------------Earning Who--------------------------------------------
  const fetchSchema = async () => {
    const token = JSON.parse(localStorage.getItem("token_gen"));
    let schemaName = localStorage.getItem("schemaName");
    schemaName = schemaName.split(" ").join("");
    const response = await schema(token?.access_token, schemaName);
    let newdata = response?.data?.schema?.properties;
    let temp = [];
    let flag = 1;
    let tempCustomer = [];
    let tempExisTrans = [];
    for (const property in newdata) {
      if (
        newdata[property].isrulesupported === true &&
        property !== "transactionType"
      ) {
        temp.push({
          label: property,
          value: property,
          index: flag,
          type: newdata[property].type,
          fielddatatype: newdata[property].fielddatatype,
        });
      }

      if (
        !tempExisTrans.some(
          (item) =>
            item.label === "TransactionCount" &&
            item.value === "TransactionCount"
        )
      ) {
        tempExisTrans.push({
          label: "TransactionCount",
          value: "TransactionCount",
          type: "number",
          fielddatatype: "Number",
        });
      }

      if (
        newdata[property].isrulesupported === true &&
        newdata[property].type === "number"
      ) {
        tempExisTrans.push({
          label: property,
          value: property,
          index: flag,
          type: newdata[property].type,
          fielddatatype: newdata[property].fielddatatype,
        });
      }

      if (newdata[property].customerReferenceField) {
        tempCustomer.push({
          label: property,
          value: property,
          name: "customer",
        });
      }
      flag += 1;
    }
    let newdata1 = response?.data?.schema?.properties;

    const temps = [];
    for (const property in newdata1) {
      if (newdata1[property].enum) {
        newdata1[property].enum.map((val) => {
          return temps.push({ label: val, value: val });
        });
      }
    }

    setOption((prev) => ({
      ...prev,
      customerOpt: tempCustomer,
      advEarningOpt: tempExisTrans,
      schemaOpt: temp,
      amountOption: temps,
    }));
    setData(response);
    setOption((prev) => ({
      ...prev,
      customerOpt: tempCustomer,
      advEarningOpt: tempExisTrans,
      schemaOpt: temp,
    }));
    setResponse((prev) => ({ ...prev, schemaRes: response }));
  };
  useEffect(() => {
    fetchSchema();
  }, []);
  const handleAdd = () => {
    const newId = state.length > 0 ? state[state.length - 1].id + 1 : 1;
    setState([...state, { ...initialState, id: newId }]);
  };
  const handleDelete = (ind) => {
    const updatedState = state.filter((ele, i) => i !== ind);
    setState(updatedState);
  };
  const handleAddRuleAdvance = (ind) => {
    setState((prev) =>
      prev.map((ele) => {
        if (ele.id === ind + 1) {
          const newRule = {
            id: "transactionType-3",
            entityName: "Transaction",
            field: "",
            format: null,
            jsonPath: "",
            operator: "",
            type: "",
            value: "",
            isFlag: false,
            data: {
              class: "Transaction",
            },
          };

          let updatedDbFunctionRule = ele.dbFunctionRule.map((rule) => ({
            ...rule,
            infixRule: [...(rule.infixRule || []), ele.toggles, newRule],
          }));

          return {
            ...ele,
            dbFunctionRule: updatedDbFunctionRule,
          };
        }
        return ele;
      })
    );
  };

  const handleAndOr = (e, id) => {
    setState((prev) =>
      prev.map((ele) => {
        if (id + 1 === ele.id) {
          let newInfixRule = ele.infixRule ? [...ele.infixRule] : [];

          if (newInfixRule.length >= 3) {
            newInfixRule[newInfixRule.length - 2] = e;
          }

          return {
            ...ele,
            toggle: e,
            prefixRule: {
              ...ele.prefixRule,
              condition: e,
            },
            infixRule: newInfixRule.length > 1 ? newInfixRule : ele.infixRule,
          };
        }
        return ele;
      })
    );
    setToggle(e);
  };

  const handleAndOrAdvance = (e, id) => {
    setState((prev) =>
      prev.map((ele) => {
        if (id + 1 === ele.id) {
          let updatedDbFunctionRule = ele.dbFunctionRule.map((rule) => ({
            ...rule,
            sqlRule: e,
          }));

          return {
            ...ele,
            toggles: e,
            dbFunctionRule: updatedDbFunctionRule,
          };
        }
        return ele;
      })
    );
    setToggles(e);
  };

  const earningWhoSelect = (e, ind, type) => {
    setState((prev) =>
      prev.map((ele) =>
        ele.id === ind + 1
          ? type === "earningWho"
            ? {
                ...ele,
                earnigWhoData: {
                  ...ele.earnigWhoData,
                  EarnigWho: e.value,
                },
              }
            : type === "customer"
            ? {
                ...ele,
                earnigWhoData: {
                  ...ele.earnigWhoData,
                  Customer: e.value,
                },
              }
            : ""
          : ele
      )
    );
  };
  // -------------------------------------Add Rule---------------------------------------------

  const handleAddRule = (ind) => {
    setState((prev) =>
      prev.map((ele) => {
        if (ele.id === ind + 1) {
          // const newAndOr = "AND";
          const newRule = {
            id: "Transaction-4",
            entityName: "Transaction",
            field: "",
            format: null,
            jsonPath: "",
            operator: "",
            type: "",
            value: "",
            data: {
              class: "Transaction",
            },
          };
          return {
            ...ele,
            infixRule: [...ele.infixRule, ele.toggle, newRule],
          };
        }
        return ele;
      })
    );
    setIsRuleAdded(true);
  };
  const handleAmt = (e, stateInd, ruleInd) => {
    setState((prev) =>
      prev.map((ele) => {
        if (stateInd + 1 === ele.id) {
          const updatedRules = ele.infixRule.map((rule, ind) => {
            if (ruleInd + 1 === ind + 1 || ruleInd + 1 === rule.ind) {
              return { ...rule, value: [e.value] };
            }
            return rule;
          });
          return { ...ele, infixRule: updatedRules };
        } else {
          return ele;
        }
      })
    );
  };
  const handleRuleChange = (e, stateInd, ruleInd, field, email) => {
    if (email) {
      setEmailValidate(true);
    } else {
      setEmailValidate(false);
    }
    const { value } = e.target || e;

    const eventType = e.type !== "change" ? e.type : undefined;
    const eventFieldDataType =
      e.fielddatatype || (e.target && e.target.fielddatatype);
    setState((prev) =>
      prev.map((ele) => {
        if (stateInd + 1 === ele.id) {
          const updatedRules = ele.infixRule.map((rule, ind) => {
            if (typeof rule === "string") return rule;
            if (ruleInd + 1 === ind + 1 || ruleInd + 1 === rule.ind) {
              const updatedRule = {
                ...rule,
                [field]: value,
                type: eventType !== undefined ? eventType : rule.type,
                fielddatatype:
                  eventFieldDataType !== undefined
                    ? eventFieldDataType
                    : rule.fielddatatype,
              };

              let jsonPath;
              let op;

              if (field === "operator") {
                op = getOperator(
                  rule.type === "string" || rule.type === "text"
                    ? stringCondition
                    : rule.type === "number" || rule.type === "integer"
                    ? numberOpt
                    : rule.type === "boolean"
                    ? transactionOptionAdv
                    : [],
                  value
                );
              } else {
                op = rule.op;
              }

              if (op && op.operator) {
                jsonPath =
                  updatedRule.operator === "between"
                    ? `$.Transaction[?(@.${updatedRule.field}>=${updatedRule.value} && @.${updatedRule.field} <=${updatedRule.value1})]`
                    : `$.Transaction[?(@.${updatedRule.field}${op.operator}${
                        updatedRule.value ? updatedRule.value : "0"
                      })]`;
                updatedRule.op = op?.operator;
              } else {
                jsonPath =
                  updatedRule.operator === "between"
                    ? `$.Transaction[?(@.${updatedRule.field}>=${updatedRule.value} && @.${updatedRule.field} <=${updatedRule.value1})]`
                    : `$.Transaction[?(@.${updatedRule.field}${op}${
                        updatedRule.value ? updatedRule.value : "0"
                      })]`;
              }
              updatedRule.jsonPath = jsonPath;
              updatedRule.id = updatedRule.field + "-5";
              return updatedRule;
            }
            return rule;
          });
          const updatedInfixRule = updatedRules
            .filter((rule) => typeof rule !== "string")
            .map((updatedRule) => ({
              id: updatedRule.field + "-5",
              field: updatedRule.field + "-5",
              operator: updatedRule.operator,
              value:
                updatedRule.operator === "between"
                  ? [
                      updatedRule.type === "string"
                        ? updatedRule.value
                        : parseInt(updatedRule.value),
                      updatedRule.type === "string"
                        ? updatedRule.value1
                        : parseInt(updatedRule.value1),
                    ]
                  : updatedRule.type === "string"
                  ? updatedRule.value
                  : parseInt(updatedRule.value),
              type: updatedRule.type,
              input: updatedRule.type === "string" ? "text" : "integer",
              // jsonPath: updatedRule.jsonPath,
              data: {
                class: "Transaction",
              },
            }));

          return {
            ...ele,
            infixRule: updatedRules,
            prefixRule: {
              condition: toggle,
              valid: true,
              rules: updatedInfixRule,
            },
          };
        }
        return ele;
      })
    );
  };

  const handleDeleteRule = (stateInd, id) => {
    setState((prev) =>
      prev.map((ele) => {
        if (stateInd + 1 === ele.id) {
          return {
            ...ele,
            infixRule: ele.infixRule.filter((rule, i) => {
              return i !== id - 1 && i !== id;
            }),
          };
        }
        return ele;
      })
    );
  };

  const handleDeleteRuleAdvance = (stateInd, id) => {
    setState((prev) =>
      prev.map((ele, ind) => {
        if (stateInd + 1 === ele.id) {
          return {
            ...ele,
            dbFunctionRule: ele.dbFunctionRule.map((rule) => ({
              ...rule,
              infixRule: rule.infixRule.filter((rule, i) => {
                return i !== id - 1 && i !== id;
              }),
            })),
          };
        }
        return ele;
      })
    );
  };
  //----------------------------------earning------------------------------------------------------
  // const handleVoucherData = () => {
  //     let temp = [];
  //     const currentDate = new Date();
  //     VoucherTemplate?.dataList?.map((e) => {
  //         if (e.isActive && new Date(e.voucherVisibilityDate) <= currentDate) {
  //             temp.push({
  //                 label: e.name,
  //                 value: e.code,
  //                 earningValue: e.earningValue,
  //                 voucherValueType:e.voucherValueType
  //             });
  //         }
  //     });
  //     setOption((prev) => ({ ...prev, voucherOption: temp }));
  // };
  const earningSelect = (e, ind, type, earningType) => {
    if (type === "VoucherValueType") {
      setState((prev) =>
        prev.map((ele) =>
          ele.id === ind + 1
            ? {
                ...ele,
                data: ele.data.map((item) =>
                  formPage === "voucher"
                    ? {
                        ...item,
                        VoucherValueType: e.value,
                      }
                    : item
                ),
              }
            : ele
        )
      );
      if (e.value) {
        let temp = [];
        const currentDate = new Date();
        VoucherTemplate?.dataList?.map((v) => {
          if (
            v.isActive &&
            new Date(v.voucherVisibilityDate) <= currentDate &&
            e.value === v.voucherValueType
          ) {
            temp.push({
              label: v.name,
              value: v.code,
              earningValue: v.earningValue,
              voucherValueType: v.voucherValueType,
            });
          }
        });
        setVoucherOptions(temp);
      } else {
        setVoucherOptions([]);
      }
    } else if (e.value === "Fix") {
      const newState = [...state];
      const updatedObject = { ...newState[ind] };
      delete updatedObject.data[0].capLimit;
      newState[ind] = updatedObject;
      setState(newState);
      setState((prev) =>
        prev.map((ele) =>
          ele.id === ind + 1
            ? {
                ...ele,
                data: ele.data.map((item) =>
                  type === "earningType"
                    ? {
                        ...item,
                        FixPercentage: e.value,
                        RedeemType:
                          e.value === "Voucher"
                            ? e.value
                            : `${e.value}-${item.AccountType}`,
                        TransactionField: item.advTransField
                          ? item.advTransField
                          : "",
                      }
                    : item
                ),
              }
            : ele
        )
      );
    } else if (
      e.value === "Percentage" ||
      earningType === "Fix" ||
      earningType === "Percentage"
    ) {
      const newState = [...state];
      const updatedObject = { ...newState[ind] };
      newState[ind] = updatedObject;
      setState(newState);
      setState((prev) =>
        prev.map((ele) =>
          ele.id === ind + 1
            ? {
                ...ele,
                data: ele.data.map((item) =>
                  type === "capLimit"
                    ? {
                        ...item,
                        capLimit: e.target.value,
                      }
                    : type === "transactionField"
                    ? {
                        ...item,
                        TransactionField: e.value,
                      }
                    : type === "earningType"
                    ? {
                        ...item,
                        FixPercentage: e.value,
                        RedeemType:
                          e.value === "Voucher"
                            ? e.value
                            : `${e.value}-${item.AccountType}`,
                      }
                    : type === "rewardRatio"
                    ? {
                        ...item,
                        RewardRatio: e.target.value,
                      }
                    : type === "value"
                    ? {
                        ...item,
                        Value: e.target.value,
                      }
                    : item
                ),
              }
            : ele
        )
      );
    }

    setState((prev) =>
      prev.map((ele) =>
        ele.id === ind + 1
          ? {
              ...ele,
              data: ele.data.map((item) =>
                type === "rewardRatio"
                  ? {
                      ...item,
                      RewardRatio: e.target.value,
                    }
                  : type === "value"
                  ? {
                      ...item,
                      Value: e.target.value
                        ? parseInt(e.target.value)
                        : e.target.value,
                    }
                  : type === "VoucherEarningType"
                  ? {
                      ...item,
                      VoucherEarningType:
                        type === "VoucherEarningType"
                          ? e.value
                          : item.VoucherEarningType,
                    }
                  : type === "accountType"
                  ? {
                      ...item,
                      AccountType: e.value,
                      RedeemType:
                        e.value === "Voucher"
                          ? e.value
                          : `${item.FixPercentage}-${e.value}`,
                    }
                  : type === "Voucher"
                  ? {
                      ...item,
                      VoucherTemplate: e.value,
                    }
                  : type === "isEvery"
                  ? {
                      ...item,
                      isEvery: !item.isEvery,
                    }
                  : type === "advEveryValue"
                  ? {
                      ...item,
                      everyValue: e.target.value
                        ? parseInt(e.target.value)
                        : "",
                    }
                  : type === "advTransField"
                  ? {
                      ...item,
                      TransactionField: item.TransactionField
                        ? item.TransactionField
                        : e.value,
                      advTransField: e.value,
                    }
                  : type === "transField"
                  ? {
                      ...item,
                      TransactionField: item.TransactionField
                        ? item.TransactionField
                        : e.value,
                    }
                  : item
              ),
            }
          : ele
      )
    );
  };
  //-------------------------------Advance Rule------------------------------------------------
  const isAdvanceRule = (ind, status) => {
    setState((prev) =>
      prev.map((ele) => {
        if (ele.id === ind + 1) {
          return {
            ...ele,
            function: [
              {
                duration: "",
                infixRule: [],
                prefixRule: null,
                durationLimit: null,
                isAdvanceRule: !status,
                infixRuleInc: [],
                prefixRuleInc: null,
                infixRuleExist: [],
                prefixRuleExist: null,
              },
            ],
            dbFunctionRule: [
              {
                sqlRule: null,
                infixRule: [
                  {
                    id: "transactionType-3",
                    entityName: "Transaction",
                    field: "",
                    format: null,
                    jsonPath: "",
                    operator: "",
                    type: "",
                    value: "",
                    isFlag: false,
                    data: {
                      class: "Transaction",
                    },
                  },
                ],
                prefixRule: {
                  condition: "AND",
                  rules: [
                    {
                      id: "transactionType-3",
                      field: "",
                      type: "",
                      input: "",
                      operator: "",
                      value: "",
                      data: {
                        class: "Transaction",
                      },
                    },
                  ],
                  valid: true,
                },
              },
            ],
            data: ele.data.map((item) => ({
              ...item,
              everyValue: "",
              isEvery: false,
              TransactionField: "",
              advTransField: "",
              // isCapLimit: false,
            })),
          };
        }
        return ele;
      })
    );
  };
  const advIncSelect = (e, ind, type) => {
    setState((prev) =>
      prev.map((ele) =>
        ele.id === ind + 1
          ? {
              ...ele,
              function: ele.function.map((fun, i) =>
                type === "property"
                  ? {
                      ...fun,
                      infixRuleInc: [
                        {
                          entityName: "Functions",
                          id: e.value,
                          field: e.value,
                          type:
                            ele?.infixRule[0]?.type === "string"
                              ? "text"
                              : "integer",
                          input: "number",
                          operator: "",
                          value: [],
                          data: {
                            class: "Functions",
                          },
                        },
                      ],
                      prefixRuleInc: {
                        condition: "AND",
                        valid: true,
                        rules: [
                          {
                            id: e.value,
                            field: e.value,
                            type:
                              ele?.infixRule[0]?.type === "string"
                                ? "text"
                                : "integer",
                            input: "number",
                            operator: "",
                            value: [],
                            data: {
                              class: "Functions",
                            },
                          },
                        ],
                      },
                    }
                  : type === "operator"
                  ? {
                      ...fun,
                      infixRuleInc: fun.infixRuleInc.map((rule) => ({
                        ...rule,
                        operator: e.value,
                      })),
                      prefixRuleInc: {
                        ...fun.prefixRuleInc,
                        rules: fun.prefixRuleInc.rules.map((rule) => ({
                          ...rule,
                          operator: e.value,
                        })),
                      },
                    }
                  : type === "value"
                  ? {
                      ...fun,
                      infixRuleInc: fun.infixRuleInc.map((rule) => ({
                        ...rule,
                        value:
                          rule.operator === "between"
                            ? [e.target.value, rule.value[1]]
                            : [e.target.value],
                      })),
                      prefixRuleInc: {
                        ...fun.prefixRuleInc,
                        rules: fun.prefixRuleInc.rules.map((rule) => ({
                          ...rule,
                          value:
                            rule.operator === "between"
                              ? [e.target.value, rule.value[1]]
                              : [e.target.value],
                        })),
                      },
                    }
                  : type === "valueBtw"
                  ? {
                      ...fun,
                      infixRuleInc: fun.infixRuleInc.map((rule) => ({
                        ...rule,
                        value:
                          rule.operator === "between"
                            ? [rule.value[0], e.target.value]
                            : [e.target.value],
                      })),
                      prefixRuleInc: {
                        ...fun.prefixRuleInc,
                        rules: fun.prefixRuleInc.rules.map((rule) => ({
                          ...rule,
                          value:
                            rule.operator === "between"
                              ? [rule.value[0], e.target.value]
                              : [e.target.value],
                        })),
                      },
                    }
                  : type === "duration"
                  ? {
                      ...fun,
                      duration: e.value,
                      durationLimit: e.limitValue,
                    }
                  : type === "advExistTrans"
                  ? {
                      ...fun,
                      infixRuleExist: [
                        {
                          id: e.value,
                          field: e.value,
                          type:
                            ele?.infixRule[0]?.type === "string"
                              ? "text"
                              : "integer",
                          input: "number",
                          operator: "",
                          value: [],
                          data: {
                            class:
                              e.value == "TransactionCount"
                                ? "Functions"
                                : "Sum",
                          },
                        },
                      ],
                      prefixRuleExist: {
                        condition: "AND",
                        valid: true,
                        rules: [
                          {
                            id: e.value,
                            field: e.value,
                            type:
                              ele?.infixRule[0]?.type === "string"
                                ? "text"
                                : "integer",
                            input: "number",
                            operator: "",
                            value: [],
                            data: {
                              class:
                                e.value == "TransactionCount"
                                  ? "Functions"
                                  : "Sum",
                            },
                          },
                        ],
                      },
                    }
                  : type === "existOperator"
                  ? {
                      ...fun,
                      infixRuleExist: fun.infixRuleExist.map((rule) => ({
                        ...rule,
                        operator: e.value,
                      })),
                      prefixRuleExist: {
                        ...fun.prefixRuleExist,
                        rules: fun.prefixRuleExist.rules.map((rule) => ({
                          ...rule,
                          operator: e.value,
                        })),
                      },
                    }
                  : type === "existValue"
                  ? {
                      ...fun,
                      infixRuleExist: fun?.infixRuleExist.map((rule) => ({
                        ...rule,
                        value:
                          rule.operator === "between"
                            ? [e.target.value, rule.value[1]]
                            : [e.target.value],
                      })),
                      prefixRuleExist: {
                        ...fun.prefixRuleExist,
                        rules: fun?.prefixRuleExist?.rules?.map((rule) => ({
                          ...rule,
                          value:
                            rule?.operator === "between"
                              ? [e.target.value, rule?.value[1]]
                              : [e.target.value],
                        })),
                      },
                    }
                  : type === "existValueBtw"
                  ? {
                      ...fun,
                      infixRuleExist: fun?.infixRuleExist.map((rule) => ({
                        ...rule,
                        value:
                          rule.operator === "between"
                            ? [rule.value[0], e.target.value]
                            : [e.target.value],
                      })),
                      prefixRuleExist: {
                        ...fun.prefixRuleExist,
                        rules: fun?.prefixRuleExist?.rules.map((rule) => ({
                          ...rule,
                          value:
                            rule?.operator === "between"
                              ? [rule?.value[0], e.target.value]
                              : [e.target.value],
                        })),
                      },
                    }
                  : ""
              ),
            }
          : ele
      )
    );
    if (type === "advExistTrans") {
      setSelectedAmount([...selectedAmount, { id: ind + 1, amount: e.value }]);
    }
    if (type === "property") {
      setSelectedTransCount([
        ...selectedTransCount,
        { id: ind + 1, amount: e.value },
      ]);
    }
  };

  //......................................Expiration...................................................................
  const expSelect = (e, ind, type, expType) => {
    if (e.label === "On" || expType === "On") {
      const newState = [...state];
      const updatedObject = { ...newState[ind] };
      delete updatedObject.data[0].expirationRule.onAfterSame;
      delete updatedObject.data[0].expirationRule.onSpecificDate;
      newState[ind] = updatedObject;
      setState(newState);
      setState((prev) =>
        prev.map((ele) =>
          ele.id === ind + 1
            ? {
                ...ele,
                data: ele.data.map((item) =>
                  type === "onAfter"
                    ? {
                        ...item,
                        expirationRule: {
                          ...item.expirationRule,
                          onAfter: true,
                        },
                      }
                    : type === "afterSpecificNumber"
                    ? {
                        ...item,
                        expirationRule: {
                          ...item.expirationRule,
                          afterSpecificNumber: e.target.value,
                        },
                      }
                    : type === "afterDuration"
                    ? {
                        ...item,
                        expirationRule: {
                          ...item.expirationRule,
                          afterDuration: e.value,
                        },
                      }
                    : item
                ),
              }
            : ele
        )
      );
    } else if (e.label === "After" || expType === "after") {
      const newState = [...state];
      const updatedObject = { ...newState[ind] };
      delete updatedObject.data[0].expirationRule.afterDuration;
      delete updatedObject.data[0].expirationRule.afterSpecificNumber;
      newState[ind] = updatedObject;
      setState(newState);
      setState((prev) =>
        prev.map((ele) =>
          ele.id === ind + 1
            ? {
                ...ele,
                data: ele.data.map((item) =>
                  type === "onAfter"
                    ? {
                        ...item,
                        expirationRule: {
                          ...item.expirationRule,
                          onAfter: false,
                          onAfterSame: false,
                        },
                      }
                    : type === "onSpecificDate"
                    ? {
                        ...item,
                        expirationRule: {
                          ...item.expirationRule,
                          onSpecificDate: e.target.value,
                        },
                      }
                    : item
                ),
              }
            : ele
        )
      );
    }
  };
  const handleRuleChangeAdvance = (e, stateInd, ruleInd, field, email) => {
    if (email) {
      setEmailValidate(true);
    } else {
      setEmailValidate(false);
    }
    const { value } = e.target || e;

    const eventType = e.type !== "change" ? e.type : undefined;
    const eventFieldDataType =
      e.fielddatatype || (e.target && e.target.fielddatatype);

    setState((prev) =>
      prev.map((ele) => {
        if (stateInd + 1 === ele.id) {
          const updatedDbFunctionRule = ele.dbFunctionRule.map(
            (dbRule, dbIndex) => {
              const updatedRules = dbRule.infixRule.map((rule, ind) => {
                if (typeof rule === "string") return rule;

                if (ruleInd + 1 === ind + 1 || ruleInd + 1 === rule.ind) {
                  const updatedRule = {
                    ...rule,
                    [field]: value,
                    type: eventType !== undefined ? eventType : rule.type,
                    fielddatatype:
                      eventFieldDataType !== undefined
                        ? eventFieldDataType
                        : rule.fielddatatype,
                  };

                  let jsonPath;
                  let op;

                  if (field === "operator") {
                    op = getOperator(
                      rule.type === "string" || rule.type === "text"
                        ? stringCondition
                        : rule.type === "number" || rule.type === "integer"
                        ? numberOpt
                        : rule.type === "boolean"
                        ? transactionOptionAdv
                        : [],
                      value
                    );
                  } else {
                    op = rule.op;
                  }

                  if (op && op.operator) {
                    jsonPath =
                      updatedRule.operator === "between"
                        ? `$.Transaction[?(@.${updatedRule.field}>=${updatedRule.value} && @.${updatedRule.field} <=${updatedRule.value1})]`
                        : `$.Transaction[?(@.${updatedRule.field}${
                            op.operator
                          }${updatedRule.value ? updatedRule.value : "0"})]`;
                    updatedRule.op = op?.operator;
                  } else {
                    jsonPath =
                      updatedRule.operator === "between"
                        ? `$.Transaction[?(@.${updatedRule.field}>=${updatedRule.value} && @.${updatedRule.field} <=${updatedRule.value1})]`
                        : `$.Transaction[?(@.${updatedRule.field}${op}${
                            updatedRule.value ? updatedRule.value : "0"
                          })]`;
                  }
                  updatedRule.jsonPath = jsonPath;
                  updatedRule.id = updatedRule.field + "-5";
                  return updatedRule;
                }
                return rule;
              });

              // Constructing SQL rule
              let sqlRule = `{\"sql\":\"(`;
              updatedRules.forEach((rule) => {
                if (rule.operator === "between") {
                  sqlRule += `c.transactionDetail.${rule.field} <= ${Number(
                    rule.value
                  )} AND c.transactionDetail.${rule.field} >= ${Number(
                    rule.value1
                  )} `;
                } else if (rule.type === "string") {
                  sqlRule +=
                    rule.operator === "in"
                      ? `c.transactionDetail.${rule.field} ${
                          rule.operator
                        } (${rule.value
                          .split(",")
                          .map((val) => `'${val.trim()}'`)
                          .join(", ")}) `
                      : `c.transactionDetail.${rule.field} ${rule?.op} '${rule.value}' `;
                } else {
                  sqlRule += `c.transactionDetail.${rule.field} ${rule?.op} ${rule.value} `;
                }
              });
              sqlRule += ` )\"}`;

              const updatedInfixRule = updatedRules
                .filter((rule) => typeof rule !== "string")
                .map((updatedRule) => ({
                  id: updatedRule.field + "-5",
                  field: updatedRule.field + "-5",
                  operator: updatedRule.operator,
                  value:
                    updatedRule.operator === "between"
                      ? [
                          updatedRule.type === "string"
                            ? updatedRule.value
                            : parseInt(updatedRule.value),
                          updatedRule.type === "string"
                            ? updatedRule.value1
                            : parseInt(updatedRule.value1),
                        ]
                      : updatedRule.type === "string"
                      ? updatedRule.value
                      : parseInt(updatedRule.value),
                  type: updatedRule.type,
                  input: updatedRule.type === "string" ? "text" : "integer",
                  data: {
                    class: "Transaction",
                  },
                }));

              return {
                ...dbRule,
                infixRule: updatedRules,
                prefixRule: {
                  condition: toggles,
                  valid: true,
                  rules: updatedInfixRule,
                },
                sqlRule, // Including the constructed sqlRule here
              };
            }
          );

          return {
            ...ele,
            dbFunctionRule:
              updatedDbFunctionRule.length > 0
                ? updatedDbFunctionRule
                : [
                    {
                      sqlRule: null,
                      infixRule: [],
                      prefixRule: null,
                    },
                  ],
          };
        }
        return ele;
      })
    );
  };

  //.........................Redeem Rule...................................................
  const RedeemSelect = (e, ind) => {
    setState((prev) =>
      prev.map((ele) =>
        ele.id === ind + 1
          ? {
              ...ele,
              data: ele.data.map((item) => ({
                ...item,
                redeemRule: {
                  ...item.redeemRule,
                  afterSpecificDate: e.target.value,
                },
              })),
            }
          : ele
      )
    );
  };
  function transData() {
    setState((prev) =>
      prev.map((ele) => {
        const updatedDbFunctionRule = ele.IncomeDBFunctionRule.map((dbRule) => {
          var sqlRule = `{\"sql\":\"(`;
          ele.infixRule.forEach((updatedRule, index) => {
            if (updatedRule.id) {
              if (index > 0) {
                sqlRule += ele.toggles + " ";
              }
              sqlRule +=
                updatedRule.operator === "between"
                  ? `c.transactionDetail.${updatedRule.field} <= ${Number(
                      updatedRule.value
                    )} AND c.transactionDetail.${updatedRule.field} >= ${Number(
                      updatedRule.value1
                    )} `
                  : updatedRule.type === "string"
                  ? updatedRule.operator === "in"
                    ? `c.transactionDetail.${updatedRule.field} ${
                        updatedRule.operator
                      } (${updatedRule.value
                        .split(",")
                        .map((val) => `'${val.trim()}'`)
                        .join(", ")}) `
                    : `c.transactionDetail.${updatedRule.field} ${updatedRule?.op} '${updatedRule.value}' `
                  : `c.transactionDetail.${updatedRule.field} ${updatedRule?.op} ${updatedRule.value} `;
            }
          });
          return {
            ...dbRule,
            infixRule: ele.infixRule.map((updatedRule) => ({
              id: "amount-4",
              field: updatedRule.field,
              operator: updatedRule.operator,
              value:
                updatedRule.operator === "between"
                  ? [updatedRule.value, updatedRule.value1]
                  : updatedRule.value,
              type: updatedRule.type,
              jsonPath:
                updatedRule.operator === "between"
                  ? `$.Transaction[?(@.${updatedRule.field}>=${updatedRule.value} && @.${updatedRule.field} >=${updatedRule.value1})]`
                  : `$.Transaction[?(@.${updatedRule.field}${updatedRule?.op}${
                      updatedRule.value ? updatedRule.value : "0"
                    })]`,
              data: {
                class: "Transaction",
              },
            })),
            prefixRule: {
              condition: "AND",
              valid: true,
              rules: ele.infixRule.map((updatedRule) => ({
                id: "amount-4",
                field: updatedRule.field,
                operator: updatedRule.operator,
                value:
                  updatedRule.operator === "between"
                    ? [updatedRule.value, updatedRule.value1]
                    : parseInt(updatedRule.value),
                type: updatedRule.type,
                input: "integer",
                data: {
                  class: "Transaction",
                },
              })),
            },
            sqlRule: `${sqlRule} )\"}`,
          };
        });
        const isMatch = selectedAmount.some((item) => item.id === ele.id);
        if (isMatch) {
          return { ...ele, IncomeDBFunctionRule: updatedDbFunctionRule };
        } else {
          return { ...ele, IncomeDBFunctionRule: ele.dbFunctionRule };
        }
      })
    );
  }

  // function transCount() {
  //     setState((prev) =>
  //         prev.map((ele) => {
  //             const updatedDbFunctionRule = ele.dbFunctionRule.map((dbRule) => {
  //                 var sqlRule = `{\"sql\":\"( `;
  //                 ele.infixRule.forEach((updatedRule, index) => {
  //                     if (updatedRule.id) {

  //                         if (index > 0) {
  //                             sqlRule += ele.toggle + " ";
  //                         }
  //                         sqlRule += updatedRule.operator === "between"
  //                             ? `c.transactionDetail.${updatedRule.field} <= ${Number(updatedRule.value)} AND c.transactionDetail.${updatedRule.field} >= ${Number(updatedRule.value1)} `
  //                             : updatedRule.type === "string"
  //                                 ? updatedRule.operator === "in"
  //                                     ? `c.transactionDetail.${updatedRule.field} ${updatedRule.operator} (${updatedRule.value.split(',').map(val => `'${val.trim()}'`).join(', ')}) `
  //                                     : `c.transactionDetail.${updatedRule.field} ${updatedRule?.op} '${updatedRule.value}' `
  //                                 : `c.transactionDetail.${updatedRule.field} ${updatedRule?.op} ${updatedRule.value} `;
  //                     }
  //                 });
  //                 return {
  //                     ...dbRule,
  //                     infixRule: ele.infixRule.map((updatedRule) => {
  //                         if (updatedRule.field) {
  //                             return {
  //                                 id: updatedRule.field,
  //                                 entityName: "Transaction",
  //                                 field: updatedRule.field,
  //                                 jsonPath: "",
  //                                 operator: updatedRule.operator,
  //                                 value: [updatedRule.operator === "between" ? [updatedRule.value, updatedRule.value1] : updatedRule.value],
  //                                 type: updatedRule.type === "string" ? "text" : "integer",
  //                                 format: "",
  //                                 data: {
  //                                     class: "Transaction",
  //                                 },
  //                             };
  //                         } else {
  //                             return updatedRule;
  //                         }
  //                     }),
  //                     prefixRule: {
  //                         condition: "AND",
  //                         valid: true,
  //                         rules: ele.infixRule.filter((updatedRule) => {
  //                             if (updatedRule.field) {
  //                                 return {
  //                                     id: updatedRule.field,
  //                                     field: updatedRule.field,
  //                                     type: updatedRule.type === "string" ? "text" : "integer",
  //                                     input: updatedRule.type,
  //                                     operator: updatedRule.operator,
  //                                     value: updatedRule.operator === "between" ? [updatedRule.value, updatedRule.value1] : parseInt(updatedRule.value),
  //                                     data: {
  //                                         class: "Transaction",
  //                                     },
  //                                 };
  //                             }
  //                         }),
  //                     },
  //                     sqlRule: `${sqlRule} )\"}`,
  //                 };
  //             });
  //             const isMatch = selectedTransCount.some((item) => item.id === ele.id);
  //             if (isMatch) {
  //                 return { ...ele, dbFunctionRule: updatedDbFunctionRule };
  //             } else {
  //                 return { ...ele, dbFunctionRule: ele.dbFunctionRule };
  //             }
  //         })
  //     );
  // }
  useEffect(() => {
    state.map((item) => {
      setData1(item.function);
      setData2(item.infixRule);
    });
  });
  const handleCommTab = () => {
    handleTabChange({ key: "Communication Flow" });
  };

  useEffect(() => {
    state.map((item) => {
      const functionObj = item?.function?.[0];
      const infixRule = item?.infixRule.filter((ele) => ele.id);
      const infixRuleExist = functionObj?.infixRuleExist?.[0];
      const field = infixRuleExist?.field;
      const infixRuleInc = functionObj?.infixRuleInc?.[0];
      const infixField = infixRuleInc?.field;
      // if (infixField?.length > 0) {
      //     if (infixField?.length > 0 || infixRule?.length > 0) {
      //         transCount();
      //     }
      // }
      if (field?.length > 0) {
        transData();
      }
    });
  }, [data1, data2]);
  const onSubmit = async () => {
    const currentState = { ...finalData };
    const isInfixRuleExist = await currentState?.earningRule?.rules?.find(
      (rule) => {
        const temp = rule.function.find((fun) => {
          if (fun?.infixRuleExist?.length) {
            return true;
          }
        });
        if (temp) {
          return {
            isCustomer: true,
          };
        }
      }
    );

    const { earningRule, campaignCustomerLimit, ...rest } = currentState;
    const updatedState = {
      ...rest,
      campaignCustomerLimit: isInfixRuleExist
        ? {
            ...campaignCustomerLimit,
            // isCustomerLimit: true,
            customerLimits: campaignCustomerLimit.customerLimits.map(
              (limit, ind) => {
                if (ind === 0) {
                  const { maxLimit, limitType, ...restLimit } = limit;
                  return {
                    ...restLimit,
                    maxLimit: isInfixRuleExist?.data[0]?.Value
                      ? isInfixRuleExist?.data[0]?.Value
                      : "1",
                    limitType: isInfixRuleExist?.function[0]?.durationLimit
                      ? isInfixRuleExist?.function[0]?.durationLimit
                      : "2",
                  };
                } else {
                  return limit;
                }
              }
            ),
          }
        : {
            ...campaignCustomerLimit,
          },
      earningRule: {
        earningRuleEvalutionType: 2,
        rules: currentState?.earningRule?.rules?.map((ele) => {
          const updates = ele.infixRule.map((infix) => {
            if (infix.id) {
              if (infix.operator === "between") {
                const { value, value1, ...rest } = infix;
                return {
                  ...rest,
                  value: [value, value1],
                };
              } else {
                const { value, value1, ...rest } = infix;
                return {
                  ...rest,
                  value,
                };
              }
            } else return infix;
          });
          const { data } = ele;
          const { advTransField, ...rest } = data[0];
          return {
            ...ele,
            data: [{ ...rest }],
            infixRule: updates[0].field.length > 0 ? updates : [],
            function: ele.function.map((fun) => {
              if (fun?.infixRuleInc?.length && fun?.infixRuleExist?.length) {
                const {
                  prefixRuleInc,
                  infixRuleInc,
                  infixRuleExist,
                  prefixRuleExist,
                  isAdvanceRule,
                  durationLimit,
                  ...rest
                } = fun;
                return {
                  ...rest,
                  infixRule: [
                    ...fun.infixRuleInc,
                    "AND",
                    ...fun.infixRuleExist,
                  ],
                  prefixRule: {
                    condition: "AND",
                    valid: true,
                    rules: [prefixRuleInc.rules[0], prefixRuleExist.rules[0]],
                  },
                };
              } else if (fun?.infixRuleInc?.length) {
                const {
                  prefixRuleInc,
                  infixRuleInc,
                  infixRuleExist,
                  prefixRuleExist,
                  isAdvanceRule,
                  durationLimit,
                  ...rest
                } = fun;
                return {
                  ...rest,
                  infixRule: [...fun.infixRuleInc],
                  prefixRule: fun.prefixRuleInc,
                };
              } else if (fun?.infixRuleExist?.length) {
                const {
                  prefixRuleInc,
                  infixRuleInc,
                  infixRuleExist,
                  prefixRuleExist,
                  isAdvanceRule,
                  durationLimit,
                  ...rest
                } = fun;
                return {
                  ...rest,
                  infixRule: [...fun.infixRuleExist],
                  prefixRule: fun.prefixRuleExist,
                };
              } else {
                const {
                  prefixRuleInc,
                  infixRuleInc,
                  infixRuleExist,
                  prefixRuleExist,
                  isAdvanceRule,
                  durationLimit,
                  ...rest
                } = fun;
                return {
                  ...rest,
                };
              }
            }),
          };
        }),
      },
    };

    setLoader(true);
    const token = JSON.parse(localStorage.getItem("token_gen"));
    const response = await campaign(token?.access_token, updatedState);
    if (response.message === "Record Created Successfully.") {
      localStorage.setItem("code", encrypt(response.data.code));
      localStorage.setItem("key", encrypt("Communication Flow"));
      setLoader(false);
      swal({
        position: "center",
        icon: "success",
        title: "Create Campaign Successfully.",
        showConfirmButton: false,
        timer: 5000,
      });
      handleCommTab();
      navigate("/my-campaigns/edit-campaign", {
        state: { data: response.data, type: "", tab: "Communication Flow" },
      });
    } else {
      setLoader(false);
      swal({
        title: " Oops!",
        text: " Something went wrong please try again.",
        icon: "error",
        button: "oh no!",
        timer: 5000,
      });
    }
  };

  const returnOption = (type) => {
    if (type === "string") return stringCondition;
    else if (type === "number") return numberOpt;
    else if (type === "boolean") return transactionOptionAdv;
    else return [];
  };

  const fields = queryDatas.map((item) => {
    if (item?.type === "Enumeration") {
      return {
        name: item?.key,
        label: item?.fielddisplayname,
        type: item?.type,
        valueEditorType: "select",
        values: [
          { value: "", label: "Select", key: "select-option" },
          ...item?.enum.map((i, index) => ({ value: i, label: i, key: index })),
        ],
      };
    } else {
      return {
        name: item?.key,
        label: item?.fielddisplayname,
        type: item?.type,
      };
    }
  });

  const initialQuery = {
    combinator: "and",
    rules: [],
  };
  const [query, setQuery] = useState(initialQuery);
  useEffect(() => {
    const querys = JSON.stringify(query, null, 2);
    setQueryData(querys);
  }, [query]);

  const fetch = async () => {
    try {
      setLoader(true);
      const token = JSON.parse(localStorage.getItem("token_gen"));
      const tedata = await getCustomer(token?.access_token);
      const propertiesWithDisplayNameAndType = Object.entries(
        tedata.schema.properties
      ).map(([key, property]) => ({
        key,
        fielddisplayname: property.fielddisplayname,
        type: property.fielddatatype,
        enum: property.enum,
        isIdent: property.isident,
      }));

      setQueryDatas(propertiesWithDisplayNameAndType);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (Array.isArray(queryDatas)) {
      const isIdentList = queryDatas.filter(
        (property) => property.isIdent === true
      );
      const labelsAndValues = [
        { label: "Select", value: "" },
        ...isIdentList.map((item) => ({
          label: item.fielddisplayname,
          value: item.key,
        })),
      ];
      setLabelValuePairs(labelsAndValues);
    }
  }, [queryDatas]);

  useEffect(() => {
    fetch();
  }, []);

  const operatorMapping = [
    { operator: "=", value: "equal" },
    { operator: "!=", value: "not_equal" },
    { operator: "<", value: "less" },
    { operator: "<=", value: "less_or_equal" },
    { operator: ">", value: "greater" },
    { operator: ">=", value: "greater_or_equal" },
  ];
  const handleQueryChange = (newQuery) => {
    if (newQuery.rules.length > 0) {
      if (!formateData?.qualifyingRule?.qualifyrules) {
        formateData.qualifyingRule = formateData.qualifyingRule || {};
        formateData.qualifyingRule.qualifyrules = [{}];
      }

      const qualifyingRules = formateData.qualifyingRule.qualifyrules[0];
      qualifyingRules.InfixQualifyingRules = [];
      qualifyingRules.PrefixQualifyingRules = {
        rules: [],
        condition: newQuery.combinator.toUpperCase(),
      };

      newQuery.rules.forEach((ruleArray, index) => {
        const selectedFieldName = ruleArray.field;
        const selectedFieldOperator = ruleArray.operator;
        const selectedFieldOp = operatorMapping.find(
          (item) => item.operator === ruleArray.operator
        )?.value;
        const selectedFieldNames = ruleArray.value;

        const rule = {
          entityName: "Customer",
          field: selectedFieldName,
          jsonPath: `$.Customer[?(@.${selectedFieldName} ${selectedFieldOperator} '${selectedFieldNames}')]`,
          op: selectedFieldOp,
          value: [selectedFieldNames],
          type: "string",
          format: null,
        };

        qualifyingRules.InfixQualifyingRules.push(rule);

        const prefixRule = {
          id: `${selectedFieldName}-9`,
          field: `${selectedFieldName}-9`,
          type: "string",
          input: "text",
          operator: selectedFieldOperator,
          value: selectedFieldNames,
          data: {
            class: "Customer",
          },
        };

        qualifyingRules.PrefixQualifyingRules.rules.push(prefixRule);

        if (index < newQuery.rules.length - 1) {
          const condition = newQuery.combinator.toUpperCase();
          qualifyingRules.InfixQualifyingRules.push(condition);
          qualifyingRules.PrefixQualifyingRules.rules.push(condition);
        }
      });
    } else {
      if (formateData?.qualifyingRule) {
        formateData.qualifyingRule.qualifyrules = null;
      }
    }
    setQuery(newQuery);
  };
  const handleQualify = async (selectedOption) => {
    const customerIdent = selectedOption ? selectedOption.value : null;
    if (formateData?.qualifyingRule?.qualifyrules?.length > 0) {
      const qualifyingRules = formateData.qualifyingRule.qualifyrules[0];
      qualifyingRules.customerIdent = customerIdent;
    } else {
      formateData.qualifyingRule = formateData.qualifyingRule || {};
      formateData.qualifyingRule.qualifyrules = [
        { customerIdent: customerIdent },
      ];
    }
  };

  return (
    <ValidatorForm className="customForm" onSubmit={onSubmit}>
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="dashboard__customFormBox" style={{ padding: "10px" }}>
        <div className="dashboard__customFormCard">
          <div className="dashboard__middle">
            <div className="row">
              <div className="dashboard__heading">
                <div className="dashboard__left">
                  <h6>Qualifying Rule</h6>
                </div>
              </div>

              <div id="advance_rule" className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="form-group">
                    <label>Customer Ident</label>
                    <SelectValidator
                      placeholder="Select"
                      className="custom-ReactSelect bordered"
                      value={selectValueGetter(labelValuePairs)}
                      options={labelValuePairs}
                      onChange={handleQualify}
                    />
                  </div>
                </div>
              </div>
              <div id="advance_rule" className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="form-group withIcon">
                    <QueryBuilder
                      fields={fields}
                      query={query}
                      onQueryChange={handleQueryChange}
                    ></QueryBuilder>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard__customFormBox" style={{ padding: "10px" }}>
        <div className="dashboard__customFormCard">
          <div className="dashboard__middle">
            <div className="row">
              <div className="dashboard__heading">
                <div className="dashboard__left">
                  <h6>Earning Rule</h6>
                </div>
                <div className="dashboard__right">
                  <button
                    type="button"
                    onClick={handleAdd}
                    className="btn btn-primary btn-lg"
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="w-100">
                {state?.length &&
                  state.map((ele, ind) => (
                    <div className="row">
                      {ind !== 0 && (
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            onClick={() => handleDelete(ind)}
                            className="btn btn-primary"
                          >
                            -
                          </button>
                        </div>
                      )}
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="dashboard__customFormBox">
                          <div className="row">
                            <div className="col-md-6">
                              <span>Earning Who</span>
                              <SelectValidator
                                validators={["required"]}
                                options={earningWhoOpt}
                                value={selectValueGetter(
                                  earningWhoOpt,
                                  ele.earnigWhoData.EarnigWho
                                )}
                                errorMessages={["Please select a value "]}
                                className="custom-ReactSelect bordered no____value"
                                placeholder="Select"
                                onChange={(e) =>
                                  earningWhoSelect(e, ind, "earningWho")
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <span>Customer</span>
                              <SelectValidator
                                validators={["required"]}
                                options={customerOpt}
                                value={selectValueGetter(
                                  customerOpt,
                                  ele.earnigWhoData.Customer
                                )}
                                errorMessages={["Please select a value "]}
                                className="custom-ReactSelect bordered no____value"
                                placeholder="Select"
                                onChange={(e) =>
                                  earningWhoSelect(e, ind, "customer")
                                }
                              />
                            </div>
                          </div>
                          <div
                            id="Earning_rules"
                            className="dashboard__customFormCard"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="dashboard__top">
                              <div className="dashboard__left">
                                <button
                                  type="button"
                                  className={
                                    ele.toggle === "AND"
                                      ? "btn btn-primary"
                                      : "btn btn-outline-primary"
                                  }
                                  onClick={() => handleAndOr("AND", ind)}
                                >
                                  AND
                                </button>
                                <button
                                  type="button"
                                  className={
                                    ele.toggle === "OR"
                                      ? "btn btn-primary"
                                      : "btn btn-outline-primary"
                                  }
                                  onClick={() => handleAndOr("OR", ind)}
                                >
                                  OR
                                </button>
                              </div>
                              <div className="dashboard__right">
                                <button
                                  type="button"
                                  onClick={() => handleAddRule(ind)}
                                  id="add_rules"
                                  className="btn btn-outline-success"
                                >
                                  <i className="fa-solid fa-circle-plus"></i>{" "}
                                  Add Rule
                                </button>
                              </div>
                            </div>
                            {ele?.infixRule?.length &&
                              ele?.infixRule?.map(
                                (rule, i) =>
                                  (i === 0 || i % 2 === 0) && (
                                    <div className="dashboard__middle" key={i}>
                                      <div className="row">
                                        {i === 0 ? null : (
                                          <span
                                            className="dashboard__close"
                                            onClick={() =>
                                              handleDeleteRule(ind, i)
                                            }
                                          >
                                            <i className="fas fa-close"></i>
                                          </span>
                                        )}
                                        {location.state === "referral" ? (
                                          <div className="row">
                                            <div
                                              id="drop1"
                                              className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            >
                                              <div className="form-group">
                                                <label>Property</label>
                                                <SelectValidator
                                                  className="custom-ReactSelect bordered no____value"
                                                  placeholder="Select"
                                                  name="field"
                                                  options={schemaOpt}
                                                  onChange={(e) =>
                                                    handleRuleChange(
                                                      e,
                                                      ind,
                                                      i,
                                                      "field"
                                                    )
                                                  }
                                                  value={selectValueGetter(
                                                    schemaOpt,
                                                    rule.field
                                                  )}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              id="drop2"
                                              className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            >
                                              <div className="form-group">
                                                <label>Operator</label>
                                                <SelectValidator
                                                  placeholder="Select"
                                                  className="custom-ReactSelect bordered no____value"
                                                  name="operator"
                                                  value={
                                                    selectValueGetter(
                                                      returnOption(rule.type),
                                                      rule.operator
                                                    )
                                                      ? selectValueGetter(
                                                          returnOption(
                                                            rule.type
                                                          ),
                                                          rule.operator
                                                        )
                                                      : ""
                                                  }
                                                  options={returnOption(
                                                    rule.type
                                                  )}
                                                  onChange={(e) =>
                                                    handleRuleChange(
                                                      e,
                                                      ind,
                                                      i,
                                                      "operator"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {rule?.fielddatatype ===
                                            "Enumeration" ? (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <label>Value</label>
                                                  <SelectValidator
                                                    className="custom-ReactSelect bordered no____value"
                                                    placeholder="Select"
                                                    name="amt"
                                                    options={amountOption}
                                                    onChange={(e) =>
                                                      handleAmt(
                                                        e,
                                                        ind,
                                                        i,
                                                        "value"
                                                      )
                                                    }
                                                    value={selectValueGetter(
                                                      amountOption,
                                                      rule.value[0] || ""
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <label>Value</label>
                                                  {rule.fielddatatype ===
                                                  "String" ? (
                                                    rule.field === "mobile" ? (
                                                      <TextValidator
                                                        name="quantity1"
                                                        className="form-control"
                                                        value={rule.value}
                                                        onChange={(e) =>
                                                          handleRuleChange(
                                                            e,
                                                            ind,
                                                            i,
                                                            "value"
                                                          )
                                                        }
                                                      />
                                                    ) : rule.field ===
                                                      "email" ? (
                                                      <TextValidator
                                                        name="quantity1"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          handleRuleChange(
                                                            e,
                                                            ind,
                                                            i,
                                                            "value",
                                                            "email"
                                                          )
                                                        }
                                                        value={rule.value}
                                                      />
                                                    ) : (
                                                      <TextValidator
                                                        value={rule.value}
                                                        name="quantity1"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          handleRuleChange(
                                                            e,
                                                            ind,
                                                            i,
                                                            "value"
                                                          )
                                                        }
                                                      />
                                                    )
                                                  ) : (
                                                    <TextValidator
                                                      value={rule.value}
                                                      name="quantity1"
                                                      className="form-control"
                                                      onChange={(e) => {
                                                        const inputValue =
                                                          e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                          );
                                                        handleRuleChange(
                                                          {
                                                            target: {
                                                              value: inputValue,
                                                            },
                                                          },
                                                          ind,
                                                          i,
                                                          "value"
                                                        );
                                                      }}
                                                      pattern="^(?:[0-9]\d*|'')$"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {rule.operator === "between" && (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <label>Value 2</label>
                                                  <TextValidator
                                                    pattern="^(?:[0-9]\d*|'')$"
                                                    name="value1"
                                                    value={
                                                      rule.value1
                                                        ? rule.value1
                                                        : ""
                                                    }
                                                    className="form-control"
                                                    onChange={(e) => {
                                                      const inputValue =
                                                        e.target.value.replace(
                                                          /[^0-9]/g,
                                                          ""
                                                        );
                                                      handleRuleChange(
                                                        {
                                                          target: {
                                                            value: inputValue,
                                                          },
                                                        },
                                                        ind,
                                                        i,
                                                        "value1"
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="row">
                                            <div
                                              id="drop1"
                                              className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            >
                                              <div className="form-group">
                                                <label>Property</label>
                                                <SelectValidator
                                                  validators={["required"]}
                                                  errorMessages={[
                                                    "Please select a value",
                                                  ]}
                                                  className="custom-ReactSelect bordered no____value"
                                                  placeholder="Select"
                                                  name="field"
                                                  options={schemaOpt}
                                                  onChange={(e) =>
                                                    handleRuleChange(
                                                      e,
                                                      ind,
                                                      i,
                                                      "field"
                                                    )
                                                  }
                                                  value={selectValueGetter(
                                                    schemaOpt,
                                                    rule.field
                                                  )}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              id="drop2"
                                              className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            >
                                              <div className="form-group">
                                                <label>Operator</label>
                                                <SelectValidator
                                                  validators={["required"]}
                                                  errorMessages={[
                                                    "Please select a value",
                                                  ]}
                                                  placeholder="Select"
                                                  className="custom-ReactSelect bordered no____value"
                                                  name="operator"
                                                  value={
                                                    selectValueGetter(
                                                      returnOption(rule.type),
                                                      rule.operator
                                                    )
                                                      ? selectValueGetter(
                                                          returnOption(
                                                            rule.type
                                                          ),
                                                          rule.operator
                                                        )
                                                      : ""
                                                  }
                                                  options={returnOption(
                                                    rule.type
                                                  )}
                                                  onChange={(e) =>
                                                    handleRuleChange(
                                                      e,
                                                      ind,
                                                      i,
                                                      "operator"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {rule?.fielddatatype ===
                                            "Enumeration" ? (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <label>Value</label>
                                                  <SelectValidator
                                                    validators={["required"]}
                                                    errorMessages={[
                                                      "Please select a value ",
                                                    ]}
                                                    className="custom-ReactSelect bordered no____value"
                                                    placeholder="Select"
                                                    name="amt"
                                                    options={amountOption}
                                                    onChange={(e) =>
                                                      handleAmt(
                                                        e,
                                                        ind,
                                                        i,
                                                        "value"
                                                      )
                                                    }
                                                    value={selectValueGetter(
                                                      amountOption,
                                                      rule.value[0] || ""
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <label>Value</label>
                                                  {rule.fielddatatype ===
                                                  "String" ? (
                                                    rule.field === "mobile" ? (
                                                      <TextValidator
                                                        name="quantity1"
                                                        className="form-control"
                                                        value={rule.value}
                                                        onChange={(e) =>
                                                          handleRuleChange(
                                                            e,
                                                            ind,
                                                            i,
                                                            "value"
                                                          )
                                                        }
                                                        validators={[
                                                          "required",
                                                          "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$",
                                                        ]}
                                                        errorMessages={[
                                                          "Please enter value",
                                                          "Please enter a valid number",
                                                        ]}
                                                      />
                                                    ) : rule.field ===
                                                      "email" ? (
                                                      <TextValidator
                                                        name="quantity1"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          handleRuleChange(
                                                            e,
                                                            ind,
                                                            i,
                                                            "value",
                                                            "email"
                                                          )
                                                        }
                                                        value={rule.value}
                                                        validators={[
                                                          "required",
                                                          "validEmail",
                                                        ]}
                                                        errorMessages={[
                                                          "Please Enter value",
                                                          "Enter valid email",
                                                        ]}
                                                      />
                                                    ) : (
                                                      <TextValidator
                                                        value={rule.value}
                                                        name="quantity1"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                          handleRuleChange(
                                                            e,
                                                            ind,
                                                            i,
                                                            "value"
                                                          )
                                                        }
                                                        validators={[
                                                          "required",
                                                        ]}
                                                        errorMessages={[
                                                          "Please Enter value",
                                                        ]}
                                                      />
                                                    )
                                                  ) : (
                                                    <TextValidator
                                                      value={rule.value}
                                                      name="quantity1"
                                                      className="form-control"
                                                      onChange={(e) => {
                                                        const inputValue =
                                                          e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                          );
                                                        handleRuleChange(
                                                          {
                                                            target: {
                                                              value: inputValue,
                                                            },
                                                          },
                                                          ind,
                                                          i,
                                                          "value"
                                                        );
                                                      }}
                                                      validators={["required"]}
                                                      errorMessages={[
                                                        "Please Enter value",
                                                      ]}
                                                      pattern="^(?:[0-9]\d*|'')$"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {rule.operator === "between" && (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <label>Value 2</label>
                                                  <TextValidator
                                                    validators={["required"]}
                                                    errorMessages={[
                                                      "Please Enter value",
                                                    ]}
                                                    pattern="^(?:[0-9]\d*|'')$"
                                                    name="value1"
                                                    value={
                                                      rule.value1
                                                        ? rule.value1
                                                        : ""
                                                    }
                                                    className="form-control"
                                                    onChange={(e) => {
                                                      const inputValue =
                                                        e.target.value.replace(
                                                          /[^0-9]/g,
                                                          ""
                                                        );
                                                      handleRuleChange(
                                                        {
                                                          target: {
                                                            value: inputValue,
                                                          },
                                                        },
                                                        ind,
                                                        i,
                                                        "value1"
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}
                          </div>
                        </div>
                        <div id="middle" className="dashboard__customFormBox">
                          <div className="dashboard__customFormCard">
                            <div className="dashboard__middle">
                              <div className="dashboard__heading">
                                <div className="dashboard__left">
                                  <h6>Earning</h6>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-6 cols-lg-6 col-xl-3">
                                  <div className="form-group">
                                    <label>Account Type</label>
                                    <SelectValidator
                                      placeholder="Select"
                                      className="custom-ReactSelect bordered"
                                      name="Account"
                                      options={accountTypeOpt}
                                      value={selectValueGetter(
                                        accountTypeOpt,
                                        ele.data[0].AccountType
                                      )}
                                      validators={["required"]}
                                      errorMessages={["Please select a value "]}
                                      onChange={(e) =>
                                        earningSelect(e, ind, "accountType")
                                      }
                                    />
                                  </div>
                                </div>
                                {formPage === "referral" ? (
                                  <>
                                    {ele.data[0].AccountType !== "Voucher" && (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                          <label>Earning Type</label>
                                          <SelectValidator
                                            placeholder="Select"
                                            className="custom-ReactSelect bordered"
                                            name="Earning"
                                            options={earningTypeOpt}
                                            value={selectValueGetter(
                                              earningTypeOpt,
                                              ele.data[0].FixPercentage
                                            )}
                                            validators={["required"]}
                                            errorMessages={[
                                              "Please select a value ",
                                            ]}
                                            onChange={(e) =>
                                              earningSelect(
                                                e,
                                                ind,
                                                "earningType"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {ele.data[0].AccountType === "Voucher" ? (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                          <>
                                            <label>Voucher</label>
                                            <SelectValidator
                                              placeholder="Select"
                                              className="custom-ReactSelect bordered"
                                              name="Voucher"
                                              validators={["required"]}
                                              errorMessages={[
                                                "Please select a value ",
                                              ]}
                                              value={selectValueGetter(
                                                voucherOption,
                                                ele.data[0].VoucherTemplate
                                              )}
                                              options={voucherOption}
                                              onChange={(e) =>
                                                earningSelect(e, ind, "Voucher")
                                              }
                                            />
                                          </>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                          <>
                                            <label>Value</label>
                                            <TextValidator
                                              placeholder="Value"
                                              maxLength="7"
                                              className="form-control"
                                              pattern="^(?:[0-9]\d*|'')$"
                                              name="Value"
                                              validators={[
                                                "required",
                                                "matchRegexp:^[0-9][0-9]*$",
                                              ]}
                                              errorMessages={[
                                                "Field can't be empty",
                                                "please enter a valid value (greater than zero)",
                                              ]}
                                              value={ele.data[0].Value}
                                              onChange={(e) =>
                                                earningSelect(
                                                  e,
                                                  ind,
                                                  "value",
                                                  "Fix"
                                                )
                                              }
                                            />
                                          </>
                                        </div>
                                      </div>
                                    )}
                                    {ele.data[0].AccountType !== "Voucher" && (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                          <>
                                            <label>Reward Ratio</label>
                                            <TextValidator
                                              placeholder="Reward Ratio"
                                              className="form-control"
                                              pattern="^[0-9]*(\.[0-9]+)?$"
                                              name="RewardRatio"
                                              validators={[
                                                "required",
                                                "isFloat",
                                                "maxNumber:100",
                                              ]}
                                              errorMessages={[
                                                "Field can't be empty",
                                                "Please enter a valid value",
                                                "Reward ratio should be between 0 and 100",
                                              ]}
                                              value={ele.data[0].RewardRatio}
                                              onChange={(e) =>
                                                earningSelect(
                                                  e,
                                                  ind,
                                                  "rewardRatio",
                                                  "Fix"
                                                )
                                              }
                                            />
                                          </>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {formPage !== "voucher" && (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                          <label>Earning Type</label>
                                          <SelectValidator
                                            placeholder="Select"
                                            className="custom-ReactSelect bordered"
                                            name="Earning"
                                            options={earningTypeOpt}
                                            value={selectValueGetter(
                                              earningTypeOpt,
                                              ele.data[0].FixPercentage
                                            )}
                                            validators={["required"]}
                                            errorMessages={[
                                              "Please select a value ",
                                            ]}
                                            onChange={(e) =>
                                              earningSelect(
                                                e,
                                                ind,
                                                "earningType"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {formPage === "voucher" ? (
                                      <>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                          <div className="form-group">
                                            <>
                                              <label>Voucher Value Type</label>
                                              <SelectValidator
                                                placeholder="Select"
                                                className="custom-ReactSelect bordered"
                                                name="VoucherValueType"
                                                validators={["required"]}
                                                errorMessages={[
                                                  "Please select a value ",
                                                ]}
                                                value={selectValueGetter(
                                                  optionsDAta,
                                                  ele.data[0].VoucherValueType
                                                )}
                                                options={optionsDAta}
                                                onChange={(e) =>
                                                  earningSelect(
                                                    e,
                                                    ind,
                                                    "VoucherValueType"
                                                  )
                                                }
                                              />
                                            </>
                                          </div>
                                        </div>
                                        {ele.data[0].VoucherValueType ===
                                        "No Value" ? (
                                          <>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                              <div className="form-group">
                                                <>
                                                  <label>
                                                    Voucher Earning Type
                                                  </label>
                                                  <SelectValidator
                                                    placeholder="Select"
                                                    className="custom-ReactSelect bordered"
                                                    name="VoucherEarningType"
                                                    options={earningTypeOpt}
                                                    value={selectValueGetter(
                                                      earningTypeOpt,
                                                      ele.data[0]
                                                        .VoucherEarningType
                                                    )}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                      "Please select a value ",
                                                    ]}
                                                    onChange={(e) =>
                                                      earningSelect(
                                                        e,
                                                        ind,
                                                        "VoucherEarningType"
                                                      )
                                                    }
                                                  />
                                                </>
                                              </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                              <div className="form-group">
                                                <>
                                                  <label>Earning Value</label>
                                                  <TextValidator
                                                    placeholder="Value"
                                                    maxLength="7"
                                                    className="form-control"
                                                    pattern="^(?:[0-9]\d*|'')$"
                                                    name="Value"
                                                    validators={[
                                                      "required",
                                                      "matchRegexp:^[0-9][0-9]*$",
                                                    ]}
                                                    errorMessages={[
                                                      "Field can't be empty",
                                                      "please enter a valid value (greater than zero)",
                                                    ]}
                                                    value={ele.data[0].Value}
                                                    onChange={(e) =>
                                                      earningSelect(
                                                        e,
                                                        ind,
                                                        "value",
                                                        "Fix"
                                                      )
                                                    }
                                                  />
                                                </>
                                              </div>
                                            </div>
                                            {ele.data[0].VoucherEarningType ===
                                            "Percentage" ? (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <>
                                                    <label>
                                                      Transaction Field For
                                                      Every
                                                    </label>
                                                    <SelectValidator
                                                      className="custom-ReactSelect bordered"
                                                      placeholder="Select"
                                                      name="type"
                                                      validators={["required"]}
                                                      errorMessages={[
                                                        "Please select field",
                                                      ]}
                                                      value={selectValueGetter(
                                                        advEarningOpt,
                                                        ele?.data[0]
                                                          ?.TransactionField
                                                      )}
                                                      options={advEarningOpt}
                                                      onChange={(e) =>
                                                        earningSelect(
                                                          e,
                                                          ind,
                                                          "transField"
                                                        )
                                                      }
                                                    />
                                                  </>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                        ) : null}
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                          <div className="form-group">
                                            <>
                                              <label>Voucher</label>
                                              <SelectValidator
                                                placeholder="Select"
                                                className="custom-ReactSelect bordered"
                                                name="Voucher"
                                                validators={["required"]}
                                                errorMessages={[
                                                  "Please select a value ",
                                                ]}
                                                value={selectValueGetter(
                                                  voucherOptions,
                                                  ele.data[0].VoucherTemplate
                                                )}
                                                options={voucherOptions}
                                                onChange={(e) =>
                                                  earningSelect(
                                                    e,
                                                    ind,
                                                    "Voucher"
                                                  )
                                                }
                                              />
                                            </>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                          <>
                                            <label>Value</label>
                                            <TextValidator
                                              placeholder="Value"
                                              maxLength="7"
                                              className="form-control"
                                              pattern="^(?:[0-9]\d*|'')$"
                                              name="Value"
                                              validators={[
                                                "required",
                                                "matchRegexp:^[0-9][0-9]*$",
                                              ]}
                                              errorMessages={[
                                                "Field can't be empty",
                                                "please enter a valid value (greater than zero)",
                                              ]}
                                              value={ele.data[0].Value}
                                              onChange={(e) =>
                                                earningSelect(
                                                  e,
                                                  ind,
                                                  "value",
                                                  "Fix"
                                                )
                                              }
                                            />
                                          </>
                                        </div>
                                      </div>
                                    )}
                                    {formPage !== "voucher" && (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                          <>
                                            <label>Reward Ratio</label>
                                            <TextValidator
                                              placeholder="Reward Ratio"
                                              className="form-control"
                                              pattern="^[0-9]*(\.[0-9]+)?$"
                                              name="RewardRatio"
                                              validators={[
                                                "required",
                                                "isFloat",
                                                "maxNumber:100",
                                              ]}
                                              errorMessages={[
                                                "Field can't be empty",
                                                "Please enter a valid value",
                                                "Reward ratio should be between 0 and 100",
                                              ]}
                                              value={ele.data[0].RewardRatio}
                                              onChange={(e) =>
                                                earningSelect(
                                                  e,
                                                  ind,
                                                  "rewardRatio",
                                                  "Fix"
                                                )
                                              }
                                            />
                                          </>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                              {ele.data[0].RedeemType ===
                                "Percentage-Point" && (
                                <div className="row">
                                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                    <div className="form-group">
                                      <label>Cap Limits</label>
                                      {/* <TextValidator placeholder="Cap Limits" className="form-control" pattern="^(?:[1-9]\d*|'')$" name="CapLimit" validators={["required", "matchRegexp:^[1-9][0-9]*$"]} errorMessages={["please enter a valid value (greater than zero)"]} value={ele.data[0].capLimit} onChange={(e) => earningSelect(e, ind, "capLimit", "Percentage")} /> */}
                                      <TextValidator
                                        placeholder="Cap Limits"
                                        className="form-control"
                                        pattern="^(?:[0-9]\d*)?$"
                                        name="CapLimit"
                                        validators={[
                                          "matchRegexp:^[0-9][0-9]*$",
                                        ]}
                                        value={ele.data[0].capLimit}
                                        onChange={(e) =>
                                          earningSelect(
                                            e,
                                            ind,
                                            "capLimit",
                                            "Percentage"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                    <div className="form-group">
                                      <label>Transaction Field</label>
                                      <SelectValidator
                                        placeholder="Select"
                                        className="custom-ReactSelect bordered"
                                        name="TransactionField"
                                        validators={["required"]}
                                        errorMessages={[
                                          "Please select a value ",
                                        ]}
                                        value={selectValueGetter(
                                          schemaOpt,
                                          ele.data[0].TransactionField
                                        )}
                                        options={schemaOpt}
                                        onChange={(e) =>
                                          earningSelect(
                                            e,
                                            ind,
                                            "transactionField",
                                            "Percentage"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="text-end">
                          <span
                            style={{ color: "#2056a5", cursor: "pointer" }}
                            onClick={() =>
                              isAdvanceRule(
                                ind,
                                ele?.function[0]?.isAdvanceRule
                              )
                            }
                          >
                            Advance Rules <i class="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </div>
                      {ele?.function[0]?.isAdvanceRule && (
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="dashboard__customFormBox">
                            <div className="dashboard__customFormCard">
                              <div
                                className="dashboard__customFormBox"
                                style={{ padding: "10px" }}
                              >
                                <div className="dashboard__customFormCard">
                                  <div className="dashboard__middle">
                                    <div className="row">
                                      {/* <div className="dashboard__heading">
                                                                <div className="dashboard__left">
                                                                    <h6>Advance rule on Incoming transactions</h6>
                                                                </div>
                                                            </div> */}
                                      {/* <div id="advance_rule" className="row">
                                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                                    <div className="form-group">
                                                                        <label>Property</label>
                                                                        <SelectValidator className="custom-ReactSelect bordered" placeholder="Select" name="field" value={selectValueGetter(transactionOptAdv, ele?.function[0]?.infixRuleInc[0]?.id)} options={transactionOptAdv} onChange={(e) => advIncSelect(e, ind, "property")} />
                                                                    </div>
                                                                </div>
                                                                {ele?.function[0]?.infixRuleInc[0]?.id && (
                                                                    <>
                                                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                                            <div className="form-group">
                                                                                <label>Operator</label>
                                                                                <SelectValidator placeholder="Select" className="custom-ReactSelect bordered" name="greaterOrEqual" value={selectValueGetter(numberOpt, ele?.function[0]?.infixRuleInc[0]?.operator)} options={numberOpt} validators={["required"]} errorMessages={["Please select field"]} onChange={(e) => advIncSelect(e, ind, "operator")} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                                            <div className="form-group">
                                                                                <label>Value</label>
                                                                                <TextValidator pattern="^(?:[1-9]\d*|'')$" className="form-control" validators={["required"]} errorMessages={["Please Enter value "]} value={ele?.function[0]?.infixRuleInc[0]?.value[0] ? ele?.function[0]?.infixRuleInc[0]?.value[0] : ""} onChange={(e) => advIncSelect(e, ind, "value")} />
                                                                            </div>
                                                                        </div>
                                                                        {ele?.function[0]?.infixRuleInc[0]?.operator === "between" && (
                                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                                                <div className="form-group">
                                                                                    <label>Value</label>
                                                                                    <TextValidator pattern="^(?:[1-9]\d*|'')$" className="form-control" validators={["required"]} errorMessages={["Please Enter value "]} value={ele?.function[0]?.infixRuleInc[0]?.value[1] ? ele?.function[0]?.infixRuleInc[0]?.value[1] : ""} onChange={(e) => advIncSelect(e, ind, "valueBtw")} />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div> */}
                                      {formPage !== "voucher" && (
                                        <div id="advance_earning">
                                          <div className="dashboard__heading">
                                            <div className="dashboard__left">
                                              <h6>
                                                Advance rule on Earning Rule
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div
                                              className="form-group withSwitch"
                                              style={{ margin: "0 0 20px" }}
                                            >
                                              <label>
                                                Every:
                                                <Switch
                                                  name="isEvery"
                                                  onHandleColor="#50BC14"
                                                  handleDiameter={30}
                                                  uncheckedIcon={true}
                                                  checkedIcon={false}
                                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                  height={20}
                                                  width={48}
                                                  className="react-switch"
                                                  onChange={(e) =>
                                                    earningSelect(
                                                      e,
                                                      ind,
                                                      "isEvery"
                                                    )
                                                  }
                                                  value={ele?.data[0]?.isEvery}
                                                  checked={
                                                    ele?.data[0]?.isEvery
                                                  }
                                                />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {ele?.data[0]?.isEvery &&
                                        formPage !== "voucher" && (
                                          <>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                              <div className="form-group">
                                                <label>Property</label>
                                                <SelectValidator
                                                  className="custom-ReactSelect bordered"
                                                  placeholder="Select"
                                                  name="type"
                                                  validators={["required"]}
                                                  errorMessages={[
                                                    "Please select field",
                                                  ]}
                                                  value={selectValueGetter(
                                                    advEarningOpt,
                                                    ele?.data[0]
                                                      ?.TransactionField
                                                  )}
                                                  options={advEarningOpt}
                                                  onChange={(e) =>
                                                    earningSelect(
                                                      e,
                                                      ind,
                                                      "advTransField"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                              <div className="form-group">
                                                <label>Value</label>
                                                <TextValidator
                                                  placeholder="Every Value"
                                                  className="form-control"
                                                  name="everyValue"
                                                  pattern="^(?:[0-9]\d*|'')$"
                                                  validators={["required"]}
                                                  errorMessages={[
                                                    "Please Enter value ",
                                                  ]}
                                                  value={
                                                    ele?.data[0]?.everyValue
                                                  }
                                                  onChange={(e) =>
                                                    earningSelect(
                                                      e,
                                                      ind,
                                                      "advEveryValue"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {/* {formPage !== "voucher" && (
                                                                <div id="advance_earning">
                                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                        <div className="form-group withSwitch" style={{ margin: "0 0 20px" }}>
                                                                            <label>
                                                                                Cap Limits:
                                                                                <Switch name="isCapLimit" onHandleColor="#50BC14" handleDiameter={30} uncheckedIcon={true} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" onChange={(e) => earningSelect(e, ind, "isCapLimit")} value={ele?.data[0]?.isCapLimit} checked={ele?.data[0]?.isCapLimit} />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {ele?.data[0]?.isCapLimit && formPage !== "voucher" && (
                                                                <>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                                        <div className="form-group">
                                                                            <label>Transaction Field</label>
                                                                            <SelectValidator placeholder="Select" className="custom-ReactSelect bordered" name="TransactionField" validators={["required"]} errorMessages={["Please select a value "]} value={selectValueGetter(schemaOpt, ele.data[0].TransactionField)} options={schemaOpt} onChange={(e) => earningSelect(e, ind, "transactionField", "Percentage")} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                                        <div className="form-group">
                                                                            <label>Cap Limits</label>
                                                                            <TextValidator placeholder="Cap Limits" className="form-control" pattern="^(?:[1-9]\d*)?$" name="CapLimit" validators={["matchRegexp:^[1-9][0-9]*$"]} value={ele.data[0].capLimit} onChange={(e) => earningSelect(e, ind, "capLimit", "Percentage")} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="dashboard__customFormBox"
                                style={{ padding: "10px" }}
                              >
                                <div className="dashboard__customFormCard">
                                  <div
                                    className="dashboard__customFormBox"
                                    style={{ padding: "10px" }}
                                  >
                                    <div className="dashboard__customFormCard">
                                      <div className="dashboard__heading">
                                        <div className="dashboard__middle">
                                          <div className="row">
                                            <div className="dashboard__left">
                                              <h6>Rule on Function Existing</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        id="Earning_rules"
                                        className="dashboard__customFormCard"
                                      >
                                        <div className="dashboard__top">
                                          <div className="dashboard__left">
                                            {}
                                            <button
                                              type="button"
                                              className={
                                                ele.toggles === "AND"
                                                  ? "btn btn-primary"
                                                  : "btn btn-outline-primary"
                                              }
                                              onClick={() =>
                                                handleAndOrAdvance("AND", ind)
                                              }
                                            >
                                              AND
                                            </button>
                                            <button
                                              type="button"
                                              className={
                                                ele.toggles === "OR"
                                                  ? "btn btn-primary"
                                                  : "btn btn-outline-primary"
                                              }
                                              onClick={() =>
                                                handleAndOrAdvance("OR", ind)
                                              }
                                            >
                                              OR
                                            </button>
                                          </div>
                                          <div className="dashboard__right">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleAddRuleAdvance(ind)
                                              }
                                              id="add_rules"
                                              className="btn btn-outline-success"
                                            >
                                              <i className="fa-solid fa-circle-plus"></i>{" "}
                                              Add Rule
                                            </button>
                                          </div>
                                        </div>
                                        {ele?.dbFunctionRule?.map(
                                          (dbRule, dbInd) =>
                                            dbRule?.infixRule?.length &&
                                            dbRule.infixRule.map(
                                              (rule, i) =>
                                                (i === 0 || i % 2 === 0) && (
                                                  <div
                                                    className="dashboard__middle"
                                                    key={i}
                                                  >
                                                    <div className="row">
                                                      {i === 0 ? null : (
                                                        <span
                                                          className="dashboard__close"
                                                          onClick={() =>
                                                            handleDeleteRuleAdvance(
                                                              ind,
                                                              i
                                                            )
                                                          }
                                                        >
                                                          <i className="fas fa-close"></i>
                                                        </span>
                                                      )}
                                                      <div className="row">
                                                        <div
                                                          id="drop1"
                                                          className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                                        >
                                                          <div className="form-group">
                                                            <label>
                                                              Property
                                                            </label>
                                                            <SelectValidator
                                                              validators={[
                                                                "required",
                                                              ]}
                                                              errorMessages={[
                                                                "Please select a value",
                                                              ]}
                                                              className="custom-ReactSelect bordered no____value"
                                                              placeholder="Select"
                                                              name="field"
                                                              options={
                                                                schemaOpt
                                                              }
                                                              onChange={(e) =>
                                                                handleRuleChangeAdvance(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  "field"
                                                                )
                                                              }
                                                              value={selectValueGetter(
                                                                schemaOpt,
                                                                rule.field
                                                              )}
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          id="drop2"
                                                          className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                                        >
                                                          <div className="form-group">
                                                            <label>
                                                              Operator
                                                            </label>
                                                            <SelectValidator
                                                              validators={[
                                                                "required",
                                                              ]}
                                                              errorMessages={[
                                                                "Please select a value",
                                                              ]}
                                                              placeholder="Select"
                                                              className="custom-ReactSelect bordered no____value"
                                                              name="operator"
                                                              value={
                                                                selectValueGetter(
                                                                  returnOption(
                                                                    rule.type
                                                                  ),
                                                                  rule.operator
                                                                )
                                                                  ? selectValueGetter(
                                                                      returnOption(
                                                                        rule.type
                                                                      ),
                                                                      rule.operator
                                                                    )
                                                                  : ""
                                                              }
                                                              options={returnOption(
                                                                rule.type
                                                              )}
                                                              onChange={(e) =>
                                                                handleRuleChangeAdvance(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  "operator"
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>

                                                        {rule?.fielddatatype ===
                                                        "Enumeration" ? (
                                                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                            <div className="form-group">
                                                              <label>
                                                                Value
                                                              </label>
                                                              <SelectValidator
                                                                validators={[
                                                                  "required",
                                                                ]}
                                                                errorMessages={[
                                                                  "Please select a value ",
                                                                ]}
                                                                className="custom-ReactSelect bordered no____value"
                                                                placeholder="Select"
                                                                name="amt"
                                                                options={
                                                                  amountOption
                                                                }
                                                                onChange={(e) =>
                                                                  handleAmt(
                                                                    e,
                                                                    ind,
                                                                    i,
                                                                    "value"
                                                                  )
                                                                }
                                                                value={selectValueGetter(
                                                                  amountOption,
                                                                  rule
                                                                    .value[0] ||
                                                                    ""
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                            <div className="form-group">
                                                              <label>
                                                                Value
                                                              </label>
                                                              {rule.fielddatatype ===
                                                              "String" ? (
                                                                rule.field ===
                                                                "mobile" ? (
                                                                  <TextValidator
                                                                    name="quantity1"
                                                                    className="form-control"
                                                                    value={
                                                                      rule.value
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleRuleChangeAdvance(
                                                                        e,
                                                                        ind,
                                                                        i,
                                                                        "value"
                                                                      )
                                                                    }
                                                                    validators={[
                                                                      "required",
                                                                      "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$",
                                                                    ]}
                                                                    errorMessages={[
                                                                      "Please enter value",
                                                                      "Please enter a valid number",
                                                                    ]}
                                                                  />
                                                                ) : rule.field ===
                                                                  "email" ? (
                                                                  <TextValidator
                                                                    name="quantity1"
                                                                    className="form-control"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleRuleChangeAdvance(
                                                                        e,
                                                                        ind,
                                                                        i,
                                                                        "value",
                                                                        "email"
                                                                      )
                                                                    }
                                                                    value={
                                                                      rule.value
                                                                    }
                                                                    validators={[
                                                                      "required",
                                                                      "validEmail",
                                                                    ]}
                                                                    errorMessages={[
                                                                      "Please Enter value",
                                                                      "Enter valid email",
                                                                    ]}
                                                                  />
                                                                ) : (
                                                                  <TextValidator
                                                                    value={
                                                                      rule.value
                                                                    }
                                                                    name="quantity1"
                                                                    className="form-control"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleRuleChangeAdvance(
                                                                        e,
                                                                        ind,
                                                                        i,
                                                                        "value"
                                                                      )
                                                                    }
                                                                    validators={[
                                                                      "required",
                                                                    ]}
                                                                    errorMessages={[
                                                                      "Please Enter value",
                                                                    ]}
                                                                  />
                                                                )
                                                              ) : (
                                                                <TextValidator
                                                                  value={
                                                                    rule.value
                                                                  }
                                                                  name="quantity1"
                                                                  className="form-control"
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    const inputValue =
                                                                      e.target.value.replace(
                                                                        /[^0-9]/g,
                                                                        ""
                                                                      );
                                                                    handleRuleChangeAdvance(
                                                                      {
                                                                        target:
                                                                          {
                                                                            value:
                                                                              inputValue,
                                                                          },
                                                                      },
                                                                      ind,
                                                                      i,
                                                                      "value"
                                                                    );
                                                                  }}
                                                                  validators={[
                                                                    "required",
                                                                  ]}
                                                                  errorMessages={[
                                                                    "Please Enter value",
                                                                  ]}
                                                                  pattern="^(?:[0-9]\d*|'')$"
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                        )}
                                                        {rule.operator ===
                                                          "between" && (
                                                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                            <div className="form-group">
                                                              <label>
                                                                Value 2
                                                              </label>
                                                              <TextValidator
                                                                validators={[
                                                                  "required",
                                                                ]}
                                                                errorMessages={[
                                                                  "Please Enter value",
                                                                ]}
                                                                pattern="^(?:[0-9]\d*|'')$"
                                                                name="value1"
                                                                value={
                                                                  rule.value1
                                                                    ? rule.value1
                                                                    : ""
                                                                }
                                                                className="form-control"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const inputValue =
                                                                    e.target.value.replace(
                                                                      /[^0-9]/g,
                                                                      ""
                                                                    );
                                                                  handleRuleChangeAdvance(
                                                                    {
                                                                      target: {
                                                                        value:
                                                                          inputValue,
                                                                      },
                                                                    },
                                                                    ind,
                                                                    i,
                                                                    "value1"
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                            )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="dashboard__customFormBox"
                                    style={{ padding: "10px" }}
                                  >
                                    <div className="dashboard__customFormCard">
                                      <div className="dashboard__middle">
                                        <div className="row">
                                          <div className="dashboard__heading">
                                            <div className="dashboard__left">
                                              <h6>
                                                Function on Existing
                                                transactions
                                              </h6>
                                            </div>
                                          </div>

                                          <div
                                            id="advance_rule"
                                            className="row"
                                          >
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                              <div className="form-group">
                                                <label>Duration</label>
                                                <SelectValidator
                                                  placeholder="Select"
                                                  className="custom-ReactSelect bordered"
                                                  name="earningType"
                                                  value={selectValueGetter(
                                                    durationOpt,
                                                    ele?.function[0]?.duration
                                                  )}
                                                  options={durationOpt}
                                                  onChange={(e) =>
                                                    advIncSelect(
                                                      e,
                                                      ind,
                                                      "duration"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            id="advance_rule"
                                            className="row"
                                          >
                                            <div
                                              id="drop1"
                                              className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            >
                                              <div className="form-group">
                                                <label>Property</label>
                                                <SelectValidator
                                                  className="custom-ReactSelect bordered no____value"
                                                  placeholder="Select"
                                                  name="category"
                                                  value={selectValueGetter(
                                                    advEarningOpt,
                                                    ele?.function[0]
                                                      ?.infixRuleExist[0]?.id
                                                  )}
                                                  options={advEarningOpt}
                                                  onChange={(e) =>
                                                    advIncSelect(
                                                      e,
                                                      ind,
                                                      "advExistTrans"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div
                                              id="drop2"
                                              className="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            >
                                              <div className="form-group">
                                                <label>Operator</label>
                                                <SelectValidator
                                                  placeholder="Select"
                                                  className="custom-ReactSelect bordered no____value"
                                                  name="greaterOrEqual"
                                                  value={
                                                    ele?.function[0]
                                                      ?.infixRuleExist[0]
                                                      ?.operator
                                                      ? selectValueGetter(
                                                          numberOpt,
                                                          ele?.function[0]
                                                            ?.infixRuleExist[0]
                                                            ?.operator
                                                        )
                                                      : null
                                                  }
                                                  options={numberOpt}
                                                  onChange={(e) =>
                                                    advIncSelect(
                                                      e,
                                                      ind,
                                                      "existOperator"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                              <div className="form-group">
                                                <label>Value</label>
                                                <TextValidator
                                                  pattern="^(?:[0-9]\d*|'')$"
                                                  name="value"
                                                  className="form-control"
                                                  value={
                                                    ele?.function[0]
                                                      ?.infixRuleExist[0]
                                                      ?.value[0]
                                                      ? ele?.function[0]
                                                          ?.infixRuleExist[0]
                                                          ?.value[0]
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    advIncSelect(
                                                      e,
                                                      ind,
                                                      "existValue"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {ele?.function[0]?.infixRuleExist[0]
                                              ?.operator === "between" && (
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                  <label>Value</label>
                                                  <TextValidator
                                                    pattern="^(?:[0-9]\d*|'')$"
                                                    name="valueBtw"
                                                    className="form-control"
                                                    value={
                                                      ele?.function[0]
                                                        ?.infixRuleExist[0]
                                                        ?.value[1]
                                                        ? ele?.function[0]
                                                            ?.infixRuleExist[0]
                                                            ?.value[1]
                                                        : ""
                                                    }
                                                    onChange={(e) =>
                                                      advIncSelect(
                                                        e,
                                                        ind,
                                                        "existValueBtw"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        id="onAfter"
                        className="col-sm-12 col-md-12 col-lg-12"
                      >
                        <div className="dashboard__customFormBox">
                          <div className="dashboard__heading">
                            <div className="dashboard__left">
                              <h5>Expiration</h5>
                            </div>
                          </div>
                          <div className="dashboard__customFormCard">
                            <div className="dashboard__middle">
                              <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                  <div className="form-group">
                                    <label>On / After</label>
                                    <SelectValidator
                                      placeholder="Select"
                                      validators={["required"]}
                                      errorMessages={["Please select a value "]}
                                      className="custom-ReactSelect bordered"
                                      name="onAfter"
                                      value={selectValueGetterBoolean(
                                        optionOnAfter,
                                        ele.data[0].expirationRule.onAfter
                                      )}
                                      options={optionOnAfter}
                                      onChange={(e) =>
                                        expSelect(e, ind, "onAfter")
                                      }
                                    />
                                  </div>
                                </div>
                                {ele.data[0].expirationRule.onAfter === true ? (
                                  <>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                      <div className="form-group">
                                        <label>Number</label>
                                        <TextValidator
                                          pattern="^(?:[0-9]\d*|'')$"
                                          placeholder="365"
                                          className="form-control"
                                          name="afterSpecificNumber"
                                          validators={[
                                            "required",
                                            "matchRegexp:^[0-9][0-9]*$",
                                          ]}
                                          errorMessages={[
                                            "Field can't be empty",
                                            "please enter a valid value (greater than zero)",
                                          ]}
                                          value={
                                            ele.data[0].expirationRule
                                              .afterSpecificNumber
                                              ? ele.data[0].expirationRule
                                                  .afterSpecificNumber
                                              : ""
                                          }
                                          defaultValue={"365"}
                                          onChange={(e) =>
                                            expSelect(
                                              e,
                                              ind,
                                              "afterSpecificNumber",
                                              "On"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                      <div className="form-group">
                                        <label>After</label>
                                        <SelectValidator
                                          placeholder="Select"
                                          className="custom-ReactSelect bordered"
                                          name="afterDuration"
                                          validators={["required"]}
                                          errorMessages={[
                                            "Please select a value ",
                                          ]}
                                          value={selectValueGetter(
                                            optionAfter,
                                            ele.data[0].expirationRule
                                              .afterDuration
                                          )}
                                          options={optionAfter}
                                          onChange={(e) =>
                                            expSelect(
                                              e,
                                              ind,
                                              "afterDuration",
                                              "On"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                      <div className="form-group">
                                        <label>On (Specific Date)</label>
                                        <TextValidator
                                          className="form-control"
                                          name="onSpecificDate"
                                          type="date"
                                          validators={["required"]}
                                          min={getDate(
                                            new Date(),
                                            "YYYY-MM-DD"
                                          )}
                                          value={
                                            ele.data[0].expirationRule
                                              .onSpecificDate
                                              ? getDate(
                                                  ele.data[0].expirationRule
                                                    .onSpecificDate,
                                                  "YYYY-MM-DD"
                                                )
                                              : ""
                                          }
                                          errorMessages={[
                                            "Please select a date ",
                                          ]}
                                          onChange={(e) =>
                                            expSelect(
                                              e,
                                              ind,
                                              "onSpecificDate",
                                              "after"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="onAfter"
                        className="col-sm-12 col-md-12 col-lg-12"
                      >
                        <div className="dashboard__customFormBox">
                          <div className="dashboard__heading">
                            <div className="dashboard__left">
                              <h5>Redeem Rule</h5>
                            </div>
                          </div>
                          <div className="dashboard__customFormCard">
                            <div className="dashboard__middle">
                              <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                  <div className="form-group">
                                    <label>
                                      Redeem After Date (mm/dd/yyyy)
                                    </label>
                                    <TextValidator
                                      type="date"
                                      className="form-control"
                                      name="onAfterDate"
                                      min={getDate(new Date(), "YYYY-MM-DD")}
                                      value={
                                        ele.data[0].redeemRule.afterSpecificDate
                                          ? getDate(
                                              ele.data[0].redeemRule
                                                .afterSpecificDate,
                                              "YYYY-MM-DD"
                                            )
                                          : ""
                                      }
                                      onChange={(e) => RedeemSelect(e, ind)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="row">
                  <div className="button-section">
                    <Link to={"/my-campaigns"}>
                      <button type="button" className="btn btn-danger">
                        Cancel
                      </button>
                    </Link>
                    <button
                      type="submit"
                      id="create_cmpain"
                      className="btn btn-primary"
                    >
                      Create Campaign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidatorForm>
  );
});
const mapStateToProps = (state) => ({
  VoucherTemplate: state.VoucherTemplate,
});
const mapDispatchToProps = {
  voucherTemplateDetail,
  clearVoucherTemplate,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEarningRulesTabLoyalty);
