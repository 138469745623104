import React, { useRef, useState, useEffect, memo } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate } from "react-router-dom";
import { campaign, schema } from "../services";
import { getDate, parseNumeric, selectValueGetter, selectValueGetterBoolean, getOperator, titleCase } from "../../../../utils/helper";
import Switch from "react-switch";
import TextValidator from "../../../partial/TextValidator";
import SelectValidator from "../../../partial/SelectValidator";


const EarningRulesTab = memo((props) => {
	const formRef = useRef();
	const navigate = useNavigate();
	const { formateData } = props;
	const [toggle, setToggle] = useState("AND");
	const [finalData, setFinalData] = useState({});
	const [incomingTransactionsCount2, setIncomingTransactionsCount2] = useState(1);
	const [toggleAnd2, setToggleAnd2] = useState("AND");
	const [addDelBtn, setAddDelBtn] = useState(true);
	const [incomingTransactions, setIncomingTransactions] = useState({
		data: [
			{
				id: 1,
				transactionAmount: "",
				greaterOrEqual: "",
				quantity: "",
				quantity1: "",
				amt: [],
				isFlag: false,
				andOr: "AND",
				type: "",
				position: 0,
				fielddatatype:""
			
			},
		],
		EarnigWho: "Customer",
		RedeemType: "",
		RewardRatio: "",
		TransactionFieldForEvery: "",
		Value: 0,
		everyValue: 0,
		isEvery: true,
	});
	const [incomingTransactions2, setIncomingTransactions2] = useState({
		data: [
			{
				id: 1,
				transactionAmount: "",
				greaterOrEqual: "",
				quantity: "",
				amt: [],
				isFlag: false,
				andOr: "AND",
			},
		],
		EarnigWho: "Customer",
		RedeemType: "",
		RewardRatio: "",
		Value: 0,
	});
	const [advanceRule, setAdvanceRule] = useState({
		field: "",
		input: "",
		value: "",
		value2: "",
		type: "",
		everyValue: "",
		isEvery: false,
	});
	const [rewardratioData, setRewardRatiodata] = useState({
		EarnigWho: "Customer",
		RedeemType: "",
		// RewardRatio: 0,
		RewardRatio: "",
		expirationRule: {
			afterDuration: "",
			afterSpecificNumber: 365,
			onAfter: true,
			onSpecificDate: "",
		},
	});

	const [earnigWhoData, setEarnigWhoData] = useState({
        EarnigWho: "",
        Customer: ""
    })
	const [stringCondition] = useState([
        { operator: "=", label: "Equal", value: "equal" },
        { operator: "!=", label: "Not Equal", value: "not_equal" },
        { operator: "==", label: "In", value: "in" },
        { operator: "!=", label: "Not In", value: "not_in" },
        // { operator: "!", label: "is not null", value: "is_not_null" },
    ]);
    const [numberCondition] = useState([
        { operator: "=", label: "Equal", value: "equal" },
        { operator: "!=", label: "Not Equal", value: "not_equal" },
        { operator: "<", label: "Less", value: "less" },
        { operator: "<=", label: "Less Or Equal", value: "less_or_equal" },
        { operator: ">", label: "Greater", value: "greater" },
        { operator: ">=", label: "Greater Or Equal", value: "greater_or_equal" },
        { operator: "", label: "Between", value: "between" },
    ]);
	const [transactionOptionAdv] = useState([{ label: "TransactionCount", value: "TransactionCount" }]);

	const [booleanCondition] = useState([{ label: "equal", value: "equal" }]);

	const [formData, setFormData] = useState({
		optionPoint: [
			{ value: "Fix-Point", label: "Fix- Point" },
			{ value: "Percentage-Point", label: "Percentage-Point" },
		],
		optionOnAfter: [
			{ value: true, label: "On" },
			{ value: false, label: "After" },
		],
		optionAfter: [{ value: "Day(s)", label: "Day(s)" }],
		transactionOption: [],
		amountOption: [],
		conditions: [],
	});
	const earningWho = [
        { label: "Customer", value: "Customer" },
    ]
    const [customer, setCustomer] =useState([]) 
   
	const [isAdvance, setIsAdvance] = useState(false);
	const [data, setData] = useState();

	const [loader, setLoader] = useState(false);
	const { earningRule } = formateData;
	const { rules } = earningRule;
	const { optionPoint, optionOnAfter, optionAfter, transactionOption, amountOption, conditions } = formData;
	const { RewardRatio, RedeemType, EarnigWho, expirationRule } = rewardratioData;
	const { afterDuration, afterSpecificNumber, onAfter, onSpecificDate } = expirationRule;
	useEffect(() => {
		fetch();
	}, []);

	useEffect(() => {
		setFinalData({ ...finalData, finalData: formateData });
	}, [formateData]);

	useEffect(() => {
		const infixRule = [];
		const prefixRule = [];
		const DBInfix = [];
		const DBPrefix = [];
		var sqlRule = `{\"sql\":\"`;
		var condition = "";
		incomingTransactions?.data?.map((e, ind) => {
			var op = getOperator(conditions, e.greaterOrEqual);
			if (typeof e === "object" && ind % 2 === 0) {
				condition = e.greaterOrEqual == "between" ? `c.transactionDetail.${e.transactionAmount} <= ${Number(e.quantity)} AND c.transactionDetail.${e.transactionAmount} >= ${Number(e.quantity1)}` : `c.transactionDetail.${e.transactionAmount} ` + op?.operator + " " + e.quantity;
				prefixRule.push({
					id: e.transactionAmount + "-" + e.position,
					field: e.transactionAmount + "-" + e.position,
					type: e.type,
					input: e.type === "string" ? "text" : "integer",
					operator: e.greaterOrEqual,
					value: e.type === "string" ? e.quantity : e.greaterOrEqual === "between" ? [Number(e.quantity), Number(e.quantity1)] : Number(e.quantity),
					data: {
						class: "Transaction",
					},
				});
				sqlRule = sqlRule + condition;
				infixRule.push({
					id: e.transactionAmount + "-" + e.position,
					entityName: "Transaction",
					field: e.transactionAmount,
					format: null,
					jsonPath: e.greaterOrEqual === "between" ? `$.Transaction[?(@.${e.transactionAmount}>=${e.quantity} && @.${e.transactionAmount} >=${e.quantity1})]` : `$.Transaction[?(@.${e.transactionAmount}${op?.operator}${e.quantity ? e.quantity : e.amt[0]})]`,
					operator: e.greaterOrEqual,
					type: e.type,
					value: e.greaterOrEqual === "between" ? [e.quantity, e.quantity1] : e.quantity,
					data: {
						class: "Transaction",
					},
				});
				DBPrefix.push({
					id: "c.transactionDetail." + e.transactionAmount,
					field: "c.transactionDetail." + e.transactionAmount,
					type: e.type,
					input: e.type === "string" ? "text" : "integer",
					operator: e.greaterOrEqual,
					value: e.type === "string" ? e.quantity : e.greaterOrEqual === "between" ? [Number(e.quantity), Number(e.quantity1)] : Number(e.quantity),
					data: {
						class: "Transaction",
					},
				});
				DBInfix.push({
					id: "c.transactionDetail." + e.transactionAmount,
					// entityName: "Transaction",
					field: "c.transactionDetail." + e.transactionAmount,
					// format: null,
					jsonPath: e.greaterOrEqual === "between" ? `$.Transaction[?(@.${e.transactionAmount}>=${e.quantity} && @.${e.transactionAmount} >=${e.quantity1})]` : `$.Transaction[?(@.${e.transactionAmount}${op?.operator}${e.quantity ? e.quantity : e.amt[0]})]`,
					operator: e.greaterOrEqual,
					type: e.type,
					value: e.greaterOrEqual === "between" ? [e.quantity, e.quantity1] : e.quantity,
					data: {
						class: "Transaction",
					},
				});
			} else {
				infixRule.push(toggle);
				DBInfix.push(toggle);
				sqlRule = sqlRule + ` ${toggle} `;
			}
		});

		setFinalData((prevState) => ({
			finalData: {
				...prevState.finalData,
				earningRule: {
					...prevState.finalData.earningRule,
					rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
						ind === 0
							? {
									...rules,
									infixRule: infixRule,
									prefixRule: { condition: toggle, valid: true, rules: prefixRule },
									dbFunctionRule: rules?.dbFunctionRule?.map((value, index) => (index === 0 && isAdvance ? { sqlRule: sqlRule + '"}', infixRule: DBInfix, prefixRule: { condition: toggle, valid: true, rules: DBPrefix } } : formateData?.earningRule?.rules[0]?.dbFunctionRule[0])),
							  }
							: rules
					),
				},
			},
		}));
	}, [incomingTransactions?.data, isAdvance]);

	useEffect(() => {
		// const infixRule = { class: {} };
		// const prefixRule = { data: {} };
		const InfixRule = [];
		const PrefixRule = [];

		PrefixRule.push({
			id: advanceRule.field,
			field: advanceRule.field,
			type: "integer",
			input: "number",
			operator: advanceRule.input,
			value: Number(advanceRule.value),
			data: {
				class: "Functions",
			},
		});
		InfixRule.push({
			id: advanceRule.field,
			field: advanceRule.field,
			type: "integer",
			input: "number",
			operator: "every",
			value: Number(advanceRule.value),
			data: {
				class: "Functions",
			},
		});

		// infixRule.entityName = "Functions"
		// infixRule.id = advanceRule.type
		// infixRule.field = advanceRule.type
		// infixRule.type = "integer"
		// infixRule.input = "number"
		// // infixRule.jsonPath = ""
		// infixRule.operator = "every"
		// infixRule.value = [advanceRule.everyValue]

		// infixRule.format = "";

		// prefixRule.id = advanceRule.type
		// prefixRule.field = advanceRule.type
		// prefixRule.type = "integer"
		// prefixRule.input = "number"
		// prefixRule.operator = "every"
		// prefixRule.value = advanceRule.everyValue
		// prefixRule.data.class = "Functions"

		setFinalData((prevState) => ({
			finalData: {
				...prevState.finalData,
				earningRule: {
					...prevState.finalData.earningRule,
					rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
						ind === 0
							? {
									...rules,
									// dbFunctionRule: rules?.dbFunctionRule?.map((value, index) => (index === 0 && isAdvance ? { sqlRule: sqlRule, infixRule: InfixRule, prefixRule: { condition: toggle, valid: true, rules: PrefixRule } } : formateData?.earningRule?.rules[0]?.dbFunctionRule[0])),
									function: rules?.function?.map((value, index) => (index === 0 && isAdvance ? { infixRule: InfixRule, prefixRule: { condition: toggle, valid: true, rules: PrefixRule }, duration: "Day" } : formateData?.earningRule?.rules[0]?.function[0])),
							  }
							: rules
					),
				},
			},
		}));
	}, [advanceRule]);

	useEffect(() => {
		const data = [];
		incomingTransactions2?.data?.map((e) => {
			data.push({
				entityName: "Transaction",
				field: e.transactionAmount.charAt(0).toUpperCase() + e.transactionAmount.slice(1),
				format: null,
				jsonPath: `$.Transaction[?(@.Referral_Type=='Referrer')]`,
				op: "equal",
				type: e.quantity ? "string" : "number",
				value: e.quantity ? e.quantity : e.amt,
			});
		});
		setFinalData((prevState) => ({
			finalData: {
				...prevState.finalData,
				earningRule: {
					...prevState.finalData.earningRule,
					rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) => (ind === 1 ? { ...rules, infixRule: data } : rules)),
				},
			},
		}));
	}, [incomingTransactions2]);

	useEffect(() => {
		setFinalData((prevState) => ({
			finalData: {
				...prevState.finalData,
				earningRule: {
					...prevState.finalData.earningRule,
					rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
						ind === 0
							? {
									...rules,
									data: prevState?.finalData?.earningRule?.rules[0]?.data.map((data, index) =>
										index === 0
											? {
													...data,
													Value: Number(incomingTransactions.Value),
													EarnigWho: incomingTransactions.EarnigWho,
													RedeemType: incomingTransactions.RedeemType,
													RewardRatio: Number(incomingTransactions.RewardRatio),
													TransactionFieldForEvery: advanceRule.isEvery ? advanceRule.type : "",
													isEvery: advanceRule.isEvery,
													everyValue: advanceRule.isEvery ? advanceRule.everyValue : 0,
											  }
											: data
									),
							  }
							: // ind === 1 ? {
							  // 	...rules, data: prevState?.finalData?.earningRule?.rules[0]?.data.map((data, index) => index === 0 ?
							  // 		{
							  // 			...data,
							  // 			Value: incomingTransactions2.Value,
							  // 			EarnigWho: incomingTransactions2.EarnigWho,
							  // 			RedeemType: incomingTransactions2.RedeemType,
							  // 			RewardRatio: incomingTransactions2.RewardRatio
							  // 		} : data
							  // 	)
							  // } :
							  rules
					),
				},
			},
		}));
	}, [incomingTransactions, advanceRule]);

	useEffect(() => {
		setFinalData((prevState) => ({
			finalData: {
				...prevState.finalData,
				earningRule: {
					...prevState.finalData.earningRule,
					rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
						ind === 0
							? {
									...rules,
									data: prevState?.finalData?.earningRule?.rules[0]?.data.map((data, index) =>
										index == 0
											? {
													...data,
													expirationRule: onAfter
														? {
																...prevState?.finalData?.earningRule?.rules[0]?.data.expirationRule,
																afterDuration: afterDuration,
																afterSpecificNumber: Number(afterSpecificNumber),
																onAfter: onAfter,
														  }
														: {
																...prevState?.finalData?.earningRule?.rules[0]?.data.expirationRule,
																onAfter: onAfter,
																onSpecificDate: onSpecificDate,
																onAfterSame: false,
														  },
											  }
											: data
									),
							  }
							: ind === 1
							? {
									...rules,
									data: prevState?.finalData?.earningRule?.rules[0]?.data.map((data, index) =>
										index == 0
											? {
													...data,
													expirationRule: {
														...prevState?.finalData?.earningRule?.rules[0]?.data.expirationRule,
														afterDuration: afterDuration,
														afterSpecificNumber: afterSpecificNumber,
														onAfter: onAfter,
													},
											  }
											: data
									),
							  }
							: rules
					),
				},
			},
		}));
	}, [expirationRule]);

	useEffect(() => {
		setRewardRatiodata({
			...rewardratioData,
			EarnigWho: formateData.earningRule.rules[0].data[0].EarnigWho,
			RedeemType: formateData.earningRule.rules[0].data[0].RedeemType,
			RewardRatio: formateData.earningRule.rules[0].data[0].RewardRatio,
			expirationRule: {
				...rewardratioData.expirationRule,
				onAfter: formateData.earningRule.rules[0].data[0].expirationRule.onAfter,
				afterSpecificNumber: formateData.earningRule.rules[0].data[0].expirationRule.afterSpecificNumber,
				afterDuration: formateData.earningRule.rules[0].data[0].expirationRule.afterDuration,
			},
		});
		let newdata = {};
		newdata = data?.data?.schema?.properties?.transactionDetail?.properties;
		const infixRule = [];
		const infixRule2 = [];

		formateData.earningRule.rules[0].infixRule.map((obj, id) => {
			infixRule.push({
				id: id + 1,
				transactionAmount: obj.field.replace(/_/g, ""),
				andOr: "AND",
				greaterOrEqual: obj.op,
				amt: obj.field.toUpperCase() === "transactionType".toUpperCase() ? [] : obj.value,
				isFlag: obj.field.toUpperCase() === "transactionType".toUpperCase() ? false : true,
				quantity: obj.field.toUpperCase() === "transactionType".toUpperCase() ? obj.value[0] : "",
				quantity1: obj.field.toUpperCase() === "transactionType".toUpperCase() && obj.value[1] ? obj.value[1] : "",
				type: obj?.type ? obj?.type : "string",
				position: 0,
				fielddatatype:""
			});
		});
		// setIncomingTransactions2({
		// 	...incomingTransactions2,
		// 	data: infixRule2,
		// 	EarnigWho: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].EarnigWho,
		// 	Value: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].Value,
		// 	RedeemType: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].RedeemType,
		// 	RewardRatio: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].RewardRatio,
		// });
		setIncomingTransactions({
			...incomingTransactions,
			EarnigWho: formateData.earningRule.rules[0].data[0].EarnigWho,
			Value: Number(formateData.earningRule.rules[0].data[0].Value),
			RedeemType: formateData.earningRule.rules[0].data[0].RedeemType,
			RewardRatio: Number(formateData.earningRule.rules[0].data[0].RewardRatio),
			data: infixRule,
		});
		
		const temps = [];
		for (const property in newdata) {
			if (property.toUpperCase() === "REFERRAL_TYPE" && newdata[property].enum) {
				newdata[property].enum.map((val) => {
					temps.push({ label: val, value: val });
				});
			}
		}
		setFormData((prev) => ({ ...prev, amountOption: temps }));
	}, [formateData]);
	// useEffect(() => {
	// 	let data = incomingTransactions?.data
	// 	setAdvanceRule({ ...advanceRule, data: data })
	// }, [incomingTransactions?.data])

	const handleConditions = (e, id, type) => {
		if (type === 0) {
			setIncomingTransactions({
				...incomingTransactions,
				data: incomingTransactions?.data.map((obj, ind) => {
					if (obj.id === id) return { ...obj, greaterOrEqual: e.value };
					return obj;
				}),
			});
		} else {
			setIncomingTransactions2({
				...incomingTransactions2,
				data: incomingTransactions2?.data.map((obj, ind) => {
					if (obj.id === id) return { ...obj, greaterOrEqual: e.value };
					return obj;
				}),
			});
		}
	};

	const handleAmt = (e, id) => {
		setIncomingTransactions({
			...incomingTransactions,
			data: incomingTransactions?.data.map((obj, ind) => {
				if (obj.id === id) return { ...obj, amt: [e.value], quantity: "" };
				return obj;
			}),
		});
	};
	const onSelectChange = async (e, id, type) => {
		let newdata = {};
		newdata = await data?.data?.schema?.properties?.transactionDetail?.properties;
		let temp = [];
		for (const property in newdata) {
			if (property.toUpperCase() === e.value.toUpperCase() && newdata[property].enum) {
				newdata[property].enum.map((val) => {
					temp.push({ label: val, value: val });
				});
				if (type === 0) {
					let temp = Object.getOwnPropertyNames(newdata).find((data) => data.toLowerCase() == e.value.toLowerCase());
					setIncomingTransactions({
						...incomingTransactions,
						data: incomingTransactions?.data.map((obj, ind) => {
							if (obj.id === id) return { ...obj, isFlag: true, transactionAmount: e.value, position: e.index ,fielddatatype:newdata[temp].fielddatatype?newdata[temp]?.fielddatatype:newdata[temp].type,type:newdata[temp].type};
							return obj;
						}),
					});
				} else {
					setIncomingTransactions2({
						...incomingTransactions2,
						data: incomingTransactions2?.data.map((obj, ind) => {
							if (obj.id === id) return { ...obj, isFlag: true, transactionAmount: e.value };
							return obj;
						}),
					});
				}
			} else {
				if (type === 0) {
					let temp = Object.getOwnPropertyNames(newdata).find((data) => data.toLowerCase() == e.value.toLowerCase());
					if (property === temp)
						setIncomingTransactions({
							...incomingTransactions,
							data: incomingTransactions?.data.map((obj, ind) => {
								if (obj.id === id) return { ...obj, isFlag: false, transactionAmount: e.value, type: newdata[temp].type, position: e.index,fielddatatype:newdata[temp].fielddatatype?newdata[temp].fielddatatype:newdata[temp].type };
								return obj;
							}),
						});
				} else {
					setIncomingTransactions2({
						...incomingTransactions2,
						data: incomingTransactions2?.data.map((obj, ind) => {
							if (obj.id === id) return { ...obj, isFlag: false, transactionAmount: e.value };
							return obj;
						}),
					});
				}
			}
		}
		let tempStringCondition = [];
		for (const property in newdata) {
			if (property.toUpperCase() === e.value.toUpperCase()) {
				if (newdata[property].type == "string") {
					tempStringCondition = stringCondition;
				} else if (newdata[property].type == "number") {
					tempStringCondition = numberCondition;
				} else if (newdata[property].type == "boolean") {
					tempStringCondition = booleanCondition;
				}
			}
		}
		setFormData({
			...formData,
			amountOption: temp,
			conditions: tempStringCondition,
		});
	};

	const fetch = async () => {
		const token = JSON.parse(localStorage.getItem("token_gen"));
		let schemaName = await localStorage.getItem("schemaName");
		schemaName = schemaName.split(" ").join("");
		const response = await schema(token?.access_token, schemaName);
		let newdata = response.data.schema.properties.transactionDetail.properties;
		let temp = [];
		let TempAmountOption = []
		let flag = 1;
		let tempCustomer = [];
        for (const property in newdata) {
            if (newdata[property].fielddatatype === "Enumeration") {
            }
            newdata[property]?.enum?.map((val) => TempAmountOption.push({ label: val, value: val }));
            if (newdata[property].isrulesupported === true) {
                temp.push({
                    label: property,
                    value: property,
                    index: flag,
                    type: newdata[property].type,
                });
            }
            if (newdata[property].customerReferenceField) {
                tempCustomer.push({
                    label: property,
                    value: property,
                });
            }
            flag += 1;
        }
        setData(response);
        setCustomer(tempCustomer);
		setFormData({ ...formData, transactionOption: temp });
	};

	const onAddRule = (id) => {
		if (id === 1) {
			// setIncomingTransactionsCount(incomingTransactionsCount + 1);
			setIncomingTransactions({
				...incomingTransactions,
				data: [
					...incomingTransactions.data,
					incomingTransactions.data[incomingTransactions.data.length - 1].andOr,
					{
						id: incomingTransactions.data[incomingTransactions.data.length - 1].id + 1,
						transactionAmount: "",
						greaterOrEqual: "",
						quantity: "",
						quantity1: "",
						amt: [],
						isFlag: false,
						andOr: toggle,
						position: 0,
					},
				],
			});
		} else {
			setIncomingTransactionsCount2(incomingTransactionsCount2 + 1);
			setIncomingTransactions2({
				...incomingTransactions2,
				data: [
					...incomingTransactions2.data,
					{
						id: incomingTransactionsCount2 + 1,
						transactionAmount: "",
						greaterOrEqual: "",
						quantity: "",
						amt: [],
						isFlag: false,
						andOr: "AND",
					},
				],
			});
		}
	};

	const onDeleteRule = async (id) => {
		let incomingTransactions_data = incomingTransactions?.data;
		incomingTransactions_data = await incomingTransactions_data.filter((choice, i) => {
			return i !== id - 1 && i !== id;
		});
		setIncomingTransactions({ ...incomingTransactions, data: incomingTransactions_data });
	};

	const onDeleteRule2 = (id) => {
		let incomingTransactions_data2 = incomingTransactions2?.data;
		incomingTransactions_data2 = incomingTransactions_data2.filter((choice, i) => {
			return choice.id !== id;
		});
		setIncomingTransactions2({
			...incomingTransactions2,
			data: incomingTransactions_data2,
		});
	};

	const onInputQuantity = (e, i) => {
		setIncomingTransactions({
			...incomingTransactions,
			data: incomingTransactions?.data.map((obj, ind) => {
				if (ind === i) return { ...obj, [e.target.name]: e.target.value, amt: [] };
				return obj;
			}),
		});
	};

	const onInputQuantity2 = (e, i) => {
		setIncomingTransactions2({
			...incomingTransactions2,
			data: incomingTransactions2?.data.map((obj, ind) => {
				if (ind === i) return { ...obj, quantity: e.target.value, amt: [] };
				return obj;
			}),
		});
	};

	// const handleToggle = () => {
	// 	setTogglee((prev) => !prev);
	// };

	const handleOnChainge = (e, type) => {
		if (type === 1) {
			setIncomingTransactions({
				...incomingTransactions,
				[e.target.name]: e.target.value,
			});
			// setRewardRatiodata({ ...rewardratioData, [e.target.name]: e.target.value })
		} else
			setIncomingTransactions2({
				...incomingTransactions2,
				[e.target.name]: e.target.value,
			});
	};

	const handleAndOr = (e, id) => {
		if (id === 1) {
			setToggle(e);
			setIncomingTransactions({
				...incomingTransactions,
				data: incomingTransactions?.data.map((obj, ind) => {
					if (ind === incomingTransactions?.data.length - 1) return { ...obj, andOr: e };
					return obj;
				}),
			});
		} else {
			setToggleAnd2(e);
			setIncomingTransactions2({
				...incomingTransactions2,
				data: incomingTransactions2?.data.map((obj, ind) => {
					if (ind === incomingTransactions2?.data.length - 1) return { ...obj, andOr: e };
					return obj;
				}),
			});
		}
	};

	const handleAddReferalReward = () => {
		const rules = [];
		finalData.finalData.earningRule.rules.map((val) => {
			rules.push(val);
		});

		rules.push(finalData.finalData.earningRule.rules[0]);
		setAddDelBtn(false);
		setFinalData((prevState) => ({
			finalData: {
				...prevState.finalData,
				earningRule: {
					...prevState.finalData.earningRule,
					rules: rules,
				},
			},
		}));
	};

	const handleDeleteReferalReward = () => {
		setIncomingTransactionsCount2(1);
		setIncomingTransactions2({
			data: [
				{
					id: 1,
					transactionAmount: "",
					greaterOrEqual: "",
					quantity: "",
					amt: [],
					isFlag: false,
					andOr: "AND",
				},
			],
			EarnigWho: "",
			RedeemType: "",
			RewardRatio: "",
			Value: 0,
		});
		setAddDelBtn(true);
		setFinalData((prevState) => ({
			finalData: {
				...prevState.finalData,
				earningRule: {
					...prevState.finalData.earningRule,
					rules: prevState?.finalData?.earningRule?.rules.filter((rules, ind) => ind !== 1),
				},
			},
		}));
	};
	const handleAdvanceRule = () => {
		if (isAdvance) {
			setAdvanceRule({
				field: "",
				input: "",
				value: "",
				value2: "",
				type: "",
				everyValue: "",
				isEvery: false,
			});
		}
		setIsAdvance(!isAdvance);
	};

	const handleEarnigWhoData = (e, name) => {
        if (name == "EarnigWho")
            setEarnigWhoData({ ...earnigWhoData, EarnigWho: e.value })
        else setEarnigWhoData({ ...earnigWhoData, Customer: e.value })
        // console.log(e,name)

    }

	const onSubmit = async () => {
		// console.log(JSON.stringify(finalData.finalData));
		// setLoader(true);
		// const token = JSON.parse(localStorage.getItem("token_gen"));
		// const response = await campaign(token?.access_token, finalData.finalData);
		// if (response.message === "Record Created Successfully.") {
		// 	setLoader(false);
		// 	alert("Campaign Created Successfully.");
		// 	navigate("/my-campaigns");
		// } else {
		// 	setLoader(false);
		// 	alert("Something Went wrong please try again.");
		// }
	};

	return (
		<ValidatorForm className="customForm" ref={formRef} onSubmit={(e) => onSubmit(e)}>
			{loader && (
				<div className="mainPreloaderMain">
					<div className="mainPreloader">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			)}
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12">
					<div className="dashboard__customFormBox">
						{/* <div className="dashboard__heading">
							<div className="dashboard__left">
								<h5>
									Transactions Rule{" "}
									<span title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
										<i className="fa-solid fa-circle-info"></i>
									</span>
								</h5>
							</div>
							<div className="dashboard__right">
								{
									addDelBtn &&
									<button type="button" className="btn btn-primary" onClick={handleAddReferalReward}>
										Add Referral Rewards
									</button>
								}
							</div>
						</div> */}
						<div className="row">
                            <div className="col-md-6">
                                <span>Earning Who</span>
                                <SelectValidator validators={["required"]} errorMessages={["Please select a value "]} className="custom-ReactSelect bordered no____value" placeholder="Select" name="EarnigWho" options={earningWho} value={selectValueGetter(earningWho, earnigWhoData.EarnigWho)} onChange={(e) => handleEarnigWhoData(e, "EarnigWho")} />
                            </div>
                            <div className="col-md-6">
                                <span>Customer</span>
                                <SelectValidator validators={["required"]} errorMessages={["Please select a value "]} className="custom-ReactSelect bordered no____value" placeholder="Select" name="Customer" options={customer} value={selectValueGetter(customer, earnigWhoData.Customer)} onChange={(e) => handleEarnigWhoData(e, "Customer")} />
                            </div>
                        </div>
						<div className="dashboard__customFormCard">
							<div className="dashboard__top">
								<div className="dashboard__left">
									<button type="button" className={toggle === "AND" ? "btn btn-outline-primary active" : "btn btn-outline-primary"} onClick={() => handleAndOr("AND", 1)}>
										AND
									</button>
									<button type="button" className={toggle === "OR" ? "btn btn-outline-primary active" : "btn btn-outline-primary"} onClick={() => handleAndOr("OR", 1)}>
										OR
									</button>
								</div>
								<div className="dashboard__right">
									<button type="button" className="btn btn-outline-success" onClick={() => onAddRule(1)}>
										<i className="fa-solid fa-circle-plus"></i> Add Rule
									</button>
								</div>
							</div>
							<div className="dashboard__middle">
								{incomingTransactions?.data?.map(
									(col, i) =>
										typeof col === "object" && (
											<div className="row" key={col.id}>
												{i === 0 ? null : (
													<span className="dashboard__close" onClick={() => onDeleteRule(i)}>
														<i className="fas fa-close"></i>
													</span>
												)}
												<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
													<div className="form-group">
														{/* <label>Transaction Amount</label> */}
														<SelectValidator validators={["required"]} errorMessages={["Please select a value "]} className="custom-ReactSelect bordered no____value" placeholder="Select" name="category" value={selectValueGetter(transactionOption, col.transactionAmount)} options={transactionOption} onChange={(e) => onSelectChange(e, col.id, 0)} />
													</div>
												</div>
												<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
													<div className="form-group">
														{/* <label>Condition</label> */}
														<SelectValidator validators={["required"]} errorMessages={["Please select a value "]} placeholder="Select" className="custom-ReactSelect bordered" name="greaterOrEqual" value={selectValueGetter(conditions, col.greaterOrEqual)} options={conditions} onChange={(e) => handleConditions(e, col.id, 0)} />
													</div>
												</div>
												<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
													<div className="form-group">
														{/* <label>Amount</label> */}
														{col?.isFlag ? (
															<SelectValidator placeholder="Select" className="custom-ReactSelect bordered" name="amt" value={selectValueGetter(amountOption, col.amt[0] || "")} options={amountOption} onChange={(e) => handleAmt(e, col.id)} />
														) : (
															<TextValidator
																value={col.quantity}
																// placeholder="1001"
																name="quantity"
																className="form-control"
																onChange={(e) => onInputQuantity(e, i)}
															/>
														)}
													</div>
												</div>
												{col.greaterOrEqual === "between" && (
													<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
														<div className="form-group">
															{/* <label>Amount</label> */}
															<TextValidator
																value={col.quantity1}
																// placeholder="1001"
																name="quantity1"
																className="form-control"
																onChange={(e) => onInputQuantity(e, i)}
															/>
														</div>
													</div>
												)}
											</div>
										)
								)}
							</div>
							<div className="dashboard__middle">
								<div className="dashboard__heading">
									<div className="dashboard__left">
										<h6>Earning</h6>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
										<div className="form-group">
											<label>Earning Type</label>
											<SelectValidator
												placeholder="Select"
												className="custom-ReactSelect bordered"
												name="RedeemType"
												validators={["required"]}
												errorMessages={["Please select a value "]}
												value={selectValueGetter(optionPoint, incomingTransactions.RedeemType)}
												options={optionPoint}
												onChange={(e) => {
													setIncomingTransactions({
														...incomingTransactions,
														RedeemType: e.value,
													});
												}}
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
										<div className="form-group">
											<label>Value</label>
											<TextValidator placeholder="Value" className="form-control" pattern="^(?:[1-9]\d*|'')$" name="Value" validators={["required", "matchRegexp:^[1-9][0-9]*$"]} errorMessages={["Field can't be empty", "please enter a valid value (greater than zero)"]} value={incomingTransactions.Value > 0 ? incomingTransactions.Value : ""} onChange={(e) => e.target.validity.valid && handleOnChainge(e, 1)} />
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
										<div className="form-group">
											<label>Reward Ratio</label>
											<TextValidator
                                                        placeholder="Reward Ratio"
                                                        className="form-control"
                                                        pattern="^[0-9]*(\.[0-9]+)?$"
                                                        name="RewardRatio"
                                                        maxLength="10"
                                                        validators={["required", "isFloat", "maxNumber:100"]}
                                                        errorMessages={[
                                                            "Field can't be empty",
                                                            "Please enter a valid value",
                                                            "Reward ratio should be between 0 and 100"
                                                        ]}
                                                        value={incomingTransactions.RewardRatio !== undefined ? incomingTransactions.RewardRatio : ""}
                                                        onChange={(e) => handleOnChainge(e, 1)}
                                                    />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{!addDelBtn && (
						<div className="dashboard__customFormBox">
							<div className="dashboard__heading">
								<div className="dashboard__left">
									<h5>
										Transactions Rule{" "}
										<span title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
											<i className="fa-solid fa-circle-info"></i>
										</span>
									</h5>
								</div>
								<div className="dashboard__right">
									<button type="button" className="btn btn-primary" onClick={handleDeleteReferalReward}>
										Delete
									</button>
								</div>
							</div>
							<div className="dashboard__customFormCard">
								<div className="dashboard__top">
									<div className="dashboard__left">
										<button type="button" className={toggleAnd2 === "AND" ? "btn btn-outline-primary active" : "btn btn-outline-primary"} onClick={() => handleAndOr("AND", 2)}>
											AND
										</button>
										<button type="button" className={toggleAnd2 === "OR" ? "btn btn-outline-primary active" : "btn btn-outline-primary"} onClick={() => handleAndOr("OR", 2)}>
											OR
										</button>
									</div>
									<div className="dashboard__right">
										<button type="button" className="btn btn-outline-success" onClick={() => onAddRule(2)}>
											<i className="fa-solid fa-circle-plus"></i> Add Rule
										</button>
									</div>
								</div>
								<div className="dashboard__middle">
									{incomingTransactions2?.data.map((col, i) => (
										<div className="row" key={col.id}>
											{i === 0 ? null : (
												<span className="dashboard__close" onClick={() => onDeleteRule2(col.id)}>
													<i className="fas fa-close"></i>
												</span>
											)}
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Transaction Amount</label>
													<SelectValidator placeholder="Transaction-amount" className="custom-ReactSelect bordered" name="category" value={selectValueGetter(transactionOption, col.transactionAmount)} options={transactionOption} onChange={(e) => onSelectChange(e, col.id, 1)} />
													<label>Transaction Amount</label>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Condition</label>
													<SelectValidator
														// placeholder="greater OR equal"
														className="custom-ReactSelect bordered"
														name="category"
														value={selectValueGetter(conditions, col.greaterOrEqual)}
														options={conditions}
														onChange={(e) => handleConditions(e, col.id, 1)}
													/>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Amount</label>
													{col?.isFlag ? (
														<SelectValidator
															// placeholder="greater OR equal"
															className="custom-ReactSelect bordered"
															name="amt"
															value={selectValueGetter(amountOption, col.amt[0] || "")}
															options={amountOption}
															onChange={(e) => handleAmt(e, col.id)}
														/>
													) : (
														<TextValidator
															value={col.quantity}
															// placeholder="100"
															className="form-control"
															onChange={(e) => onInputQuantity2(e, i)}
														/>
													)}
												</div>
											</div>
										</div>
									))}
								</div>
								<div className="dashboard__middle">
									<div className="dashboard__heading">
										<div className="dashboard__left">
											<h6>Earning</h6>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
											<div className="form-group">
												<label>Earning Type</label>
												<SelectValidator
													placeholder="Fix - Point"
													value={selectValueGetter(optionPoint, incomingTransactions2.RedeemType)}
													options={optionPoint}
													onChange={(e) =>
														setIncomingTransactions2({
															...incomingTransactions2,
															RedeemType: e.value,
														})
													}
													className="custom-ReactSelect bordered"
													name="fixPoint"
												/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
											<div className="form-group">
												{/* <label>Value</label> */}
												<TextValidator placeholder="Value" className="form-control" name="Value" value={incomingTransactions2.Value} onChange={(e) => handleOnChainge(e, 2)} />
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
											<div className="form-group">
												{/* <label>Reward Ratio </label> */}
												<TextValidator placeholder="Reward Ratio" className="form-control" name="RewardRatio" value={incomingTransactions2.RewardRatio} onChange={(e) => handleOnChainge(e, 12)} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<p className="text-end">
						<span style={{ color: "#86b7fe" }} onClick={() => handleAdvanceRule()}>
							Advance Rules ?
						</span>
					</p>
				</div>
				{isAdvance && (
					<div className="col-sm-12 col-md-12 col-lg-12">
						<div className="dashboard__customFormBox">
							<div className="dashboard__customFormCard">
								<div className="dashboard__middle">
									<div className="row">
										<div className="dashboard__heading">
											<div className="dashboard__left">
												<h6>Advance rule on Incoming transactions</h6>
											</div>
										</div>

										<div className="row">
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Property</label>
													<SelectValidator
														className="custom-ReactSelect bordered"
														placeholder="Select"
														name="field"
														value={selectValueGetter(transactionOptionAdv, advanceRule.field)}
														options={transactionOptionAdv}
														onChange={(e) =>
															setAdvanceRule({
																...advanceRule,
																field: e.value,
															})
														}
														// disabled
													/>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Operator</label>
													<SelectValidator
														// placeholder="Transaction-amount"
														className="custom-ReactSelect bordered"
														name="greaterOrEqual"
														// disabled
														value={selectValueGetter(numberCondition, advanceRule.input)}
														options={numberCondition}
														onChange={(e) =>
															setAdvanceRule({
																...advanceRule,
																input: e.value,
															})
														}
													/>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Value</label>
													<TextValidator
														value={advanceRule.value}
														pattern="^(?:[1-9]\d*|'')$"
														className="form-control"
														onChange={(e) =>
															setAdvanceRule({
																...advanceRule,
																value: e.target.validity.valid ? e.target.value : advanceRule.value,
															})
														}
														// disabled
													/>
												</div>
											</div>
											{/* 
											{(advanceRule.input === "between") && (<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Value</label>
													<TextValidator
														value={advanceRule.value2}
														pattern="^(?:[1-9]\d*|'')$"
														className="form-control"
														onChange={(e) => setAdvanceRule({
															...advanceRule,
															value2: e.target.validity.valid ? e.target.value : advanceRule.value2,
														})}
													// disabled
													/>
												</div>
											</div>)} */}
										</div>

										<div className="dashboard__heading">
											<div className="dashboard__left">
												<h6>Advance rule on Earning Rule</h6>
											</div>
										</div>
										<>
											<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<div className="form-group withSwitch" style={{ margin: "0 0 20px" }}>
													<label>
														Every:
														<Switch
															name="isEvery"
															value={advanceRule.isEvery}
															checked={advanceRule.isEvery}
															onChange={() =>
																setAdvanceRule({
																	...advanceRule,
																	isEvery: !advanceRule.isEvery,
																})
															}
															onColor={advanceRule.isEvery ? "#50BC14" : "#E50000"}
															onHandleColor="#50BC14"
															handleDiameter={30}
															uncheckedIcon={true}
															checkedIcon={false}
															boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
															activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
															height={20}
															width={48}
															className="react-switch"
														/>
													</label>
												</div>
											</div>
											{advanceRule.isEvery && (
												<>
													<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
														<div className="form-group">
															{/* <label>Transaction Amount</label> */}
															<SelectValidator
																className="custom-ReactSelect bordered"
																placeholder="Select"
																name="type"
																value={selectValueGetter(transactionOption, advanceRule.type)}
																options={transactionOption}
																onChange={(e) =>
																	setAdvanceRule({
																		...advanceRule,
																		type: e.value,
																	})
																}
															/>
														</div>
													</div>
													<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
														<div className="form-group">
															{/* <label>Reward Ratio</label> */}
															<TextValidator
																placeholder="Every Value"
																className="form-control"
																name="everyValue"
																pattern="^(?:[1-9]\d*|'')$"
																value={advanceRule.everyValue}
																onChange={(e) =>
																	setAdvanceRule({
																		...advanceRule,
																		everyValue: e.target.validity.valid ? e.target.value : advanceRule.everyValue,
																	})
																}
															/>
														</div>
													</div>
												</>
											)}
										</>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="col-sm-12 col-md-12 col-lg-12">
					<div className="dashboard__customFormBox">
						<div className="dashboard__heading">
							<div className="dashboard__left">
								<h5>Expiration</h5>
							</div>
						</div>
						<div className="dashboard__customFormCard">
							<div className="dashboard__middle">
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
										<div className="form-group">
											<label>On / After</label>
											<SelectValidator
												placeholder="Select"
												validators={["required"]}
												errorMessages={["Please select a value "]}
												className="custom-ReactSelect bordered"
												name="onAfter"
												value={selectValueGetterBoolean(optionOnAfter, onAfter)}
												options={optionOnAfter}
												onChange={(e) =>
													setRewardRatiodata({
														...rewardratioData,
														expirationRule: {
															...rewardratioData.expirationRule,
															onAfter: e.value,
														},
													})
												}
											/>
										</div>
									</div>
									{onAfter === true ? (
										<>
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Number</label>
													<TextValidator
														placeholder="365"
														pattern="^(?:[1-9]\d*|'')$"
														className="form-control"
														name="afterSpecificNumber"
														validators={["required", "matchRegexp:^[1-9][0-9]*$"]}
														errorMessages={["Field can't be empty", "please enter a valid value (greater than zero)"]}
														value={afterSpecificNumber > 0 ? afterSpecificNumber : ""}
														onChange={(e) =>
															e.target.validity.valid &&
															setRewardRatiodata({
																...rewardratioData,
																expirationRule: {
																	...rewardratioData.expirationRule,
																	afterSpecificNumber: parseNumeric(e.target.value),
																},
															})
														}
													/>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>After</label>
													<SelectValidator
														placeholder="Select"
														className="custom-ReactSelect bordered"
														name="afterDuration"
														validators={["required"]}
														errorMessages={["Please select a value "]}
														value={selectValueGetter(optionAfter, afterDuration)}
														options={optionAfter}
														onChange={(e) =>
															setRewardRatiodata({
																...rewardratioData,
																expirationRule: {
																	...rewardratioData.expirationRule,
																	afterDuration: e.value,
																},
															})
														}
													/>
												</div>
											</div>
										</>
									) : (
										<>
											<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>On (Specific Date)</label>
													<TextValidator
														placeholder="365"
														className="form-control"
														name="onSpecificDate"
														type="date"
														validators={["required"]}
														errorMessages={["Please select a date "]}
														value={getDate(onSpecificDate, "YYYY-MM-DD")}
														onChange={(e) =>
															setRewardRatiodata({
																...rewardratioData,
																expirationRule: {
																	...rewardratioData.expirationRule,
																	onSpecificDate: e.target.value,
																},
															})
														}
													/>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-12">
					<div className="button-section">
						<button type="button" className="btn btn-danger">
							Cancel
						</button>
						<button type="submit" className="btn btn-primary">
							Create Campaign
						</button>
					</div>
				</div>
			</div>
		</ValidatorForm>
	);
});

export default EarningRulesTab;
