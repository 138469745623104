import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import moment from "moment";

import { backIcon2 } from "../../Image/Index";
import TextValidator from "../partial/TextValidator";
import SelectValidator from "../partial/SelectValidator";
import { generateQRTemplate, qrToken } from "../pages/campaigns/services";
import Header from "../layout/Header";
import { getDate, selectValueGetter } from "../../utils/helper";

const QRCode = () => {
  const navigate = useNavigate();
  const [valueTypeOption] = useState([
    {
      value: "NoValue",
      label: "No Value",
    },
    {
      value: "PreLoadedValue",
      label: "Pre Loaded Value",
    },
  ]);

  const [typeOption] = useState([
    {
      value: "Distributor",
      label: "Distributor",
    },
    {
      value: "Retailer",
      label: "Retailer",
    },
  ]);
  const [loader, setLoader] = useState(false);

  const initialState = {
    valuetype: "",
    value: "",
    quantity: "",
    status: false,
    productid: "",
    type: "",
    redemptionlimittype: false,
    redemptionlimitcount: "",
    issuedate: "",
    expirydate: "",
  };
  const [state, setState] = useState(initialState);
  const [dateErr, setDateErr] = useState(false);

  useEffect(() => {
    let tempEndDate = moment(state.expirydate).diff(
      moment(state.issuedate),
      "days"
    );
    if (tempEndDate < 0) {
      setDateErr(true);
    } else {
      setDateErr(false);
    }
  }, [state.issuedate, state.expirydate]);

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Ctrl",
    ];

    if (e.ctrlKey && ["a", "c", "v", "x"].includes(e.key.toLowerCase())) {
      return;
    }

    if (e.key === " ") {
      e.preventDefault();
      return;
    }

    if (allowedKeys.includes(e.key)) {
      return;
    }

    if (!/^[0-9]$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const onSelectChange = (e, name) => {
    let temp = e.value;
    setState({ ...state, [name]: temp });
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleStatus = () => {
    setState((prevState) => ({
      ...prevState,
      status: !prevState.status,
    }));
  };

  const handleRedemptionLimit = () => {
    setState((prevState) => ({
      ...prevState,
      redemptionlimittype: !prevState.redemptionlimittype,
    }));
  };

  const handleDateTime = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleReset = async () => {
    setState(initialState);
  };
  const onSubmit = async () => {
    if (!dateErr) {
      setLoader(true);

      const response = await qrToken();
      const { value, redemptionlimitcount, ...rest } = state;
      const body = {
        ...rest,
        status: state.status ? "Active" : "InActive",
        redemptionlimittype: state.redemptionlimittype ? "Multiple" : "OneTime",
        quantity: Number(state.quantity),
        ...(state.valuetype === "PreLoadedValue" && {
          value: Number(state.value),
        }),
        ...(state.redemptionlimittype && {
          redemptionlimitcount: Number(state.redemptionlimitcount),
        }),
      };
      const res = await generateQRTemplate(response?.access_token, body);
      if (res.message === "Record Created Successfully.") {
        swal({
          position: "center",
          icon: "success",
          title: "QR code template create successfully",
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        swal({
          title: " Oops!",
          text: res.message,
          icon: "error",
          button: "oh no!",
          timer: 5000,
        });
      }
      setState(initialState);
      navigate("/qrcode-template");
      setLoader(false);
    }
  };

  return (
    <>
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Header name={"QR Code Template"} />
      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="dashboard__profileSettingsMain">
            <div className="dashboard__heading">
              <div className="dashboard__headingLeft">
                <div className="dashboard__headingBackLink">
                  <Link to="" onClick={() => navigate(-1)}>
                    <img src={backIcon2} alt="" />
                    <div className="dashboard__headingContent">
                      <h3>QR Code Template</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="dashboard__profileSettingsBox">
              <ValidatorForm
                className="customForm customForm_2"
                onSubmit={onSubmit}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <label>
                        Product Id
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextValidator
                        maxLength="50"
                        className="form-control"
                        name="productid"
                        value={state.productid}
                        onChange={(e) => onChange(e)}
                        validators={["required"]}
                        errorMessages={["Please enter product id"]}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <label>
                        Activation Date
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextValidator
                        className="form-control"
                        name="issuedate"
                        type="date"
                        value={
                          state.issuedate
                            ? getDate(state.issuedate, "YYYY-MM-DD")
                            : ""
                        }
                        min={getDate(new Date(), "YYYY-MM-DD")}
                        onChange={handleDateTime}
                        validators={["required"]}
                        errorMessages={["Please enter activation date"]}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <label>
                        Expiration Date
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextValidator
                        className="form-control"
                        name="expirydate"
                        type="date"
                        value={
                          state.expirydate
                            ? getDate(state.expirydate, "YYYY-MM-DD")
                            : ""
                        }
                        onChange={handleDateTime}
                        validators={["required"]}
                        errorMessages={["Please enter expiration date"]}
                      />
                      {dateErr && (
                        <span style={{ color: "red" }}>
                          Expiration date should be greater than activation date
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <label>
                        Quantity
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextValidator
                        maxLength="50"
                        className="form-control"
                        name="quantity"
                        value={state.quantity}
                        onChange={(e) => onChange(e)}
                        validators={["required"]}
                        errorMessages={["Please enter quantity"]}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <div className="form-group">
                        <label>
                          Redemption Limit Type
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <label className="toggle">
                          <input
                            type="checkbox"
                            name="redemptionlimittype"
                            checked={state.redemptionlimittype}
                            onChange={handleRedemptionLimit}
                          />
                          <span className="slider"></span>
                          <span
                            className="labels"
                            data-on="Multiple"
                            data-off="OneTime"
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {state.redemptionlimittype && (
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group withIcon">
                        <label>
                          Redemption Limit Count
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextValidator
                          maxLength="50"
                          className="form-control"
                          name="redemptionlimitcount"
                          value={state.redemptionlimitcount}
                          onChange={(e) => onChange(e)}
                          validators={["required"]}
                          errorMessages={[
                            "Please enter redemption limit count",
                          ]}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <label>
                        QR Type
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <SelectValidator
                        placeholder="Select Type"
                        className="custom-ReactSelect bordered"
                        name="type"
                        value={selectValueGetter(typeOption, state.type) || ""}
                        options={typeOption}
                        validators={["required"]}
                        errorMessages={["Please select type"]}
                        onChange={(e) => onSelectChange(e, "type")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <div className="form-group">
                        <label>Status</label>
                        <div className="">
                          <label className="toggle">
                            <input
                              type="checkbox"
                              name="status"
                              checked={state.status}
                              onChange={handleStatus}
                            />
                            <span className="slider"></span>
                            <span
                              className="labels"
                              data-on="Active"
                              data-off="Inactive"
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group withIcon">
                      <div className="form-group">
                        <label>
                          Value Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectValidator
                          placeholder="Select Type"
                          className="custom-ReactSelect bordered"
                          name="valuetype"
                          value={
                            selectValueGetter(
                              valueTypeOption,
                              state.valuetype
                            ) || ""
                          }
                          options={valueTypeOption}
                          validators={["required"]}
                          errorMessages={["Please select type"]}
                          onChange={(e) => onSelectChange(e, "valuetype")}
                        />
                      </div>
                    </div>
                  </div>
                  {state.valuetype === "PreLoadedValue" && (
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group withIcon">
                        <label>
                          Value
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextValidator
                          maxLength="50"
                          className="form-control"
                          name="value"
                          value={state.value}
                          onChange={(e) => onChange(e)}
                          validators={["required"]}
                          errorMessages={["Please enter value"]}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                    <div className="button-section">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCode;
