import React, { useState } from "react";
import Header from "../../layout/Header";
import {
  shopify,
  sendgrid,
  twillio,
  whatsapp,
  razorpay,
  paypal,
  authorize_net,
  stripe,
  power_bi,
  msg91,
} from "../../../Image/Index";
import { Link } from "react-router-dom";

function Integration() {
  return (
    <div>
      <Header />

      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="dashboard__transactionsCard">
            <div className="dashboard__header">
              <div className="dashboard__left">
                <h4>
                  Integration Hub: Automate Customer and Transaction Processes
                </h4>
              </div>
            </div>

            <div className="dashboard__body">
              <div className="enhance-block">
                <p>
                  Enhance your loyalty programs by consolidating information
                  across all touchpoints, ensuring a cohesive and personalized
                  customer experience.
                </p>
              </div>

              <div className="integration-container">
                <div className="integration">
                  <Link
                    to="https://x-series-support.lightspeedhq.com/hc/en-us"
                    target="_blank"
                  >
                    <img
                      src="https://theme.zdassets.com/theme_assets/821742/d9a7b350346b57b76b3c0f8baa1c730600fbc375.svg"
                      style={{ height: "30px" }}
                    ></img>
                  </Link>
                  <p>
                    {" "}
                    Fetch real-time sales and customer data to enhance targeted
                    loyalty programs and marketing.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.shopify.com/" target="_blank">
                    <img src={shopify}></img>
                  </Link>
                  <p>
                    {" "}
                    Access purchase history and shopping behaviour to create
                    personalized rewards and promotions.
                  </p>
                </div>
                <div className="integration">
                  <h4>Comprehensive API Integration:</h4>
                  <p>
                    Seamlessly connect with CRM, ERP, and POS systems for a
                    unified loyalty experience.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.sendgrid.com/" target="_blank">
                    <img src={sendgrid}></img>
                  </Link>
                  <p>
                    Enable reliable email delivery and marketing campaigns for
                    customer engagement.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.twilio.com/" target="_blank">
                    <img src={twillio}></img>
                  </Link>
                  <p>
                    Power SMS and WhatsApp messaging to enhance customer
                    communication.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.msg91.com/" target="_blank">
                    <img src={msg91} height={30}></img>
                  </Link>
                  <p>
                    Streamline SMS communications, primarily for the Indian
                    market.
                  </p>
                </div>
                <div className="integration">
                  <Link
                    to="https://business.whatsapp.com/products/business-platform/"
                    target="_blank"
                  >
                    <img src={whatsapp}></img>
                  </Link>
                  <p>
                    Integrate WhatsApp for direct and efficient customer
                    interactions.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://app.powerbi.com/" target="_blank">
                    <img src={power_bi} height={25}></img>
                  </Link>
                  <p>
                    Leverage data analytics for advanced insights and
                    performance tracking
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.razorpay.com/" target="_blank">
                    <img src={razorpay}></img>
                  </Link>
                  <p>
                    Seamlessly integrate with Razorpay to enable smooth and
                    secure digital payments for loyalty transactions.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.stripe.com/" target="_blank">
                    <img src={stripe} height={25}></img>
                  </Link>
                  <p>
                    Easy Stripe integration, allowing businesses to process
                    payments globally with ease.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.paypal.com/" target="_blank">
                    <img src={paypal}></img>
                  </Link>
                  <p>
                    With PayPal integration, ensure loyal customers can make
                    transactions securely.
                  </p>
                </div>
                <div className="integration">
                  <Link to="https://www.authorize.net/" target="_blank">
                    <img src={authorize_net} height={30}></img>
                  </Link>
                  <p>
                    Connect with Authorize.net to provide reliable payment
                    processing for purchases.
                  </p>
                </div>
                <div className="integration">
                  <h4>Custom Integrations:</h4>
                  <p>
                    Flexible API-based custom integrations to accommodate unique
                    business payment needs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Integration;
