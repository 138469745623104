export const COLUMNS = [
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
  },

  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
  },

  {
    Header: "Mobile",
    Footer: "Mobile",
    accessor: "mobile",
  },
  {
    Header: "Membership Type ",
    Footer: "membershipType",
    accessor: "membershipType",
  },
  {
    Header: "Amount",
    Footer: "Amount",
    accessor: "amount",
  },

  {
    Header: "Purchased Date",
    Footer: "Purchased Date",
    accessor: "date",
  },
  {
    Header: "Remarks",
    Footer: "Remarks",
    accessor: "remarks",
  },

  {
    Header: "Payment Status",
    Footer: "Payment Status",
    accessor: "status",
  },
  {
    Header: "Edit",
    Footer: "Edit",
    accessor: "edit",
  },
];
