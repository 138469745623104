import { getNewAuthToken, isTokenExpired } from "../utils";
import axios from "../utils/axios";
import setAuthToken from "../utils/setAuthToken";
import { baseURL } from "../utils/url";
import {
  GET_PROFILE_SETTING,
  UPDATE_PROFILE_SETTING,
  GET_FINANCIAL_ACCOUNT,
  CLEAR_SETTING,
} from "./type";

export const getProfile = (token, data) => async (dispatch) => {
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        // console.log(res)
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const fetchResponse = await fetch(baseURL + "getprofile", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    fetchResponse
      .json()
      .then((res) => {
        dispatch({
          type: GET_PROFILE_SETTING,
          payload: res,
        });
        return res;
      })
      .catch(() => {
        return null;
      });
  } catch (error) {
    return error;
  }
};

export const updateProfile = (token, data) => async (dispatch) => {
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        // console.log(res)
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const fetchResponse = await fetch(baseURL + "updateprofile", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    fetchResponse
      .json()
      .then((res) => {
        dispatch({
          type: UPDATE_PROFILE_SETTING,
          payload: res,
        });
        return res;
      })
      .catch(() => {
        return null;
      });
  } catch (error) {
    return error;
  }
};

export const getFinancialAccount = (token, id) => async (dispatch) => {
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        // console.log(res)
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const fetchResponse = await fetch(
      baseURL + "getfinancialaccount?id=" + id,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    fetchResponse
      .json()
      .then((res) => {
        dispatch({
          type: GET_FINANCIAL_ACCOUNT,
          payload: res,
        });
        return res;
      })
      .catch(() => {
        return null;
      });
  } catch (error) {
    return error;
  }
};

export const merchantLogo = async (token, companyName, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        // console.log(res)
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const fetchResponse = await fetch(
      baseURL + `merchantLogo?companyName=${companyName}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
        body: formData,
      }
    );
    return fetchResponse.json();
  } catch (error) {
    return error;
  }
};
export const vouchersImage = async (token, voucherTemplateCode, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        // console.log(res)
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const fetchResponse = await fetch(
      baseURL + `voucherImage?voucherTemplateCode=${voucherTemplateCode}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
        body: formData,
      }
    );
    return fetchResponse.json();
  } catch (error) {
    return error;
  }
};
export const merchantBanner = async (
  token,
  companyName,
  isBanner,
  isReciptUpload,
  inStoreRedeption,
  changeInisReciptUpload,
  changeIninStoreRedeption,
  file
) => {
  const formData = new FormData();
  formData.append("file", file);
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        // console.log(res)
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const encodedCompanyName = encodeURIComponent(companyName);
    const fetchResponse = await fetch(
      baseURL +
        `merchantBannerLogo?companyName=${encodedCompanyName}&isBanner=${isBanner}&isReciptUpload=${isReciptUpload}&inStoreRedeption=${inStoreRedeption}&changeInisReciptUpload=${changeInisReciptUpload}&changeIninStoreRedeption=${changeIninStoreRedeption}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
        body: formData,
      }
    );
    return fetchResponse.json();
  } catch (error) {
    return error;
  }
};

export const merchantBannerData = async (
  token,
  companyName,
  isBanner,
  isReciptUpload,
  inStoreRedeption,
  changeInisReciptUpload,
  changeIninStoreRedeption,
  displayFirst,
  file
) => {
  const formData = new FormData();
  formData.append("file", file);
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        // console.log(res)
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const fetchResponse = await fetch(
      baseURL +
        `merchantBannerReciept?companyName=${companyName}&isBanner=${isBanner}&isReciptUpload=${isReciptUpload}&inStoreRedeption=${inStoreRedeption}&changeInisReciptUpload=${changeInisReciptUpload}&changeIninStoreRedeption=${changeIninStoreRedeption}&displayFirst=${displayFirst}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
        body: formData,
      }
    );
    return fetchResponse.json();
  } catch (error) {
    return error;
  }
};

export const updateCatCache = async (token) => {
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    setAuthToken(token);
    const fetchResponse = await axios.get(`updateCatCache`);
    return fetchResponse?.data;
  } catch (error) {
    return error;
  }
};

export const clearSetting = () => async (dispatch) => {
  dispatch({ type: CLEAR_SETTING });
};

export const mediaUpload = async (token, merchantId, mediaName, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });
    const fetchResponse = await fetch(
      baseURL + `mediaUpload?merchantId=${merchantId}&mediaName=${mediaName}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
        body: formData,
      }
    );
    return fetchResponse.json();
  } catch (error) {
    return error;
  }
};

export const getMediaUpload = async (token, merchantId) => {
  try {
    const fetchResponse = await fetch(
      baseURL + `getMediaUpload?merchantId=${merchantId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const response = await fetchResponse.json();
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMediaUpload = async (token, id) => {
  const valid = await isTokenExpired(token);
  try {
    if (!valid)
      await getNewAuthToken().then((res) => {
        localStorage.setItem("token_gen", JSON.stringify(res));
        token = res?.access_token;
      });

    const fetchResponse = await fetch(baseURL + `deleteMediaUpload?id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const response = await fetchResponse.json();

    return response;
  } catch (error) {
    return error;
  }
};
