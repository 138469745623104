import React, { useEffect, useLayoutEffect, useState } from "react";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layout/Header";
import { COLUMNS } from "./column";
import Paginated from "./Paginated";
import {
  generateQRCode,
  getQRTemplate,
  qrToken,
} from "../pages/campaigns/services";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const QRCodeTemplate = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    let templateData;
    try {
      setLoader(true);
      const tokenRes = await qrToken();
      const response = await getQRTemplate(tokenRes.access_token);
      if (response.message === "Record Created Successfully.") {
        if (Array.isArray(response?.data) && response?.data?.length > 0) {
          templateData = response.data?.map((val, ind) => {
            return (val.action = (
              <Link to="" onClick={() => handleClick(val.code, val)}>
                Generate
              </Link>
            ));
          });
          setData(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleClick = async (code, val) => {
    try {
      setLoader(true);
      const tokenRes = await qrToken();
      const response = await generateQRCode(tokenRes.access_token, {
        templatecode: code,
      });
      if (response.message === "Record Created Successfully.") {
        const {
          action,
          valueType,
          createdBy,
          createdDate,
          code,
          merchantId,
          redemptionlimitcount,
          redemptionlimittype,
          status,
          totalqrcodecount,
          updatedBy,
          updatedDate,
          quantity,
          ...rest
        } = val;
        const qrcodes = response.data.qrcodes.map((item) => ({
          ...item,
          ...rest,
        }));
        generateExcel(qrcodes);
        await fetch();
        swal({
          position: "center",
          icon: "success",
          title: "QR code create successfully",
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        swal({
          title: " Oops!",
          text: response.message,
          icon: "error",
          button: "oh no!",
          timer: 5000,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const generateExcel = async (qrcodeData) => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("QR Code Data");

      // Define Headers (Reordering 'qrcode' to be after 'code')
      const allKeys = Object.keys(qrcodeData[0]);
      const reorderedKeys = [
        "code",
        "qrcode",
        ...allKeys.filter((key) => key !== "code" && key !== "qrcode"),
      ];

      worksheet.columns = reorderedKeys.map((key) => ({
        header: key,
        key: key,
        width: key === "qrcode" ? 15 : 30,
      }));

      // Add Data & Images
      qrcodeData.forEach((row, index) => {
        const rowIndex = index + 2;
        const newRow = {};

        reorderedKeys.forEach((key) => {
          if (key !== "qrcode") {
            newRow[key] = Array.isArray(row[key])
              ? row[key].join(", ")
              : row[key];
          }
        });

        worksheet.addRow(newRow);
        worksheet.getRow(rowIndex).height = 80; // Set row height for images

        if (row.qrcode) {
          const imageId = workbook.addImage({
            base64: row.qrcode,
            extension: "png",
          });

          // Find the column index of "qrcode"
          const qrCodeColIndex = reorderedKeys.indexOf("qrcode");

          worksheet.addImage(imageId, {
            tl: { col: qrCodeColIndex, row: rowIndex - 1 },
            ext: { width: 100, height: 100 },
          });
        }
      });

      // Generate and Download Excel File
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "QR_Code_Data.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <Header name={"QR Code Template"} />
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="dashboard__heading">
                <div className="dashboard__headingLeft">
                  <div className="dashboard__headingContent">
                    <Link to="/qr-code">
                      <Button id="voucher" className="btn btn-primary btn-lg">
                        Generate QR Code Template
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard__customersCard">
              <div className="dashboard__header">
                <div className="dashboard__left">
                  <h4 id="voucher_details">QR Code Template Details</h4>
                </div>
              </div>
              <div className="dashboard__body">
                {<Paginated data={data ? data : []} columns={COLUMNS} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodeTemplate;
