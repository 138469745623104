import React, { useState } from "react";
import swal from "sweetalert";
import { CloseButton, Modal } from "react-bootstrap";
import { ValidatorForm } from "react-form-validator-core";

import { updateCustomer, updateGenerateCardData } from "../../../actions/auth";
import TextValidator from "../../partial/TextValidator";

const CardUpdateModal = (props) => {
  const [loader, setLoader] = useState(false);
  const [cardNum, setCardNum] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();

    let response;
    try {
      let token = JSON.parse(localStorage.getItem("token_gen"));
      setLoader(true);
      const res = await updateCustomer(
        token?.access_token,
        props.customer_Code,
        { memberCardNumber: cardNum }
      );
      if (res.message === "Request processed successfully.") {
        response = await updateGenerateCardData(token?.access_token, cardNum, {
          status: true,
        });
        if (response.message === "Card data updated successfully.") {
          await props.fetchCustomerDetail();
          swal({
            position: "center",
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          swal({
            title: " Oops!",
            text: response.message,
            icon: "error",
            button: "oh no!",
            timer: 5000,
          });
        }
      }
    } catch (error) {
      swal({
        title: " Oops!",
        text: response.message,
        icon: "error",
        button: "oh no!",
        timer: 5000,
      });
    } finally {
      setLoader(false);
      props.close();
    }
  };

  return (
    <>
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Modal show={props.show} onHide={props.close} size="lg" centered>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "20px" }}>
            Membership Card Number
          </Modal.Title>
          <CloseButton onClick={props.close} variant="black" />
        </Modal.Header>
        <Modal.Body
          className="dashboard__transactionsModal"
          style={{ position: "relative", minHeight: "85px" }}
        >
          <ValidatorForm
            className="signUpPage__formSection"
            onSubmit={handleSubmit}
          >
            <div className="modal-body">
              <div className="form-group">
                <TextValidator
                  onChange={(e) => setCardNum(e.target.value)}
                  value={cardNum}
                  placeholder="Enter membership card number"
                  name="count"
                  className="form-control"
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </ValidatorForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardUpdateModal;
